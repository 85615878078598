import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import BDatePicker from "../../common/input/BDatePicker";
import moment from "moment";

export default function ProductRules(props) {
  const { value } = props;

  const [leadTime, setLeadTime] = useState(value?.date?.leadTime || "");
  const [minDate, setMinDate] = useState(value?.date?.min || "");
  const [maxDate, setMaxDate] = useState(value?.date?.max || "");

  useEffect(() => {
    let rules = { date: {} };
    if (leadTime) {
      rules.date.leadTime = leadTime;
    }
    if (minDate) {
      rules.date.min = minDate;
    }
    if (maxDate) {
      rules.date.max = maxDate;
    }
    props.onChange(rules);
  }, [leadTime, minDate, maxDate]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Box mb={1}>
            <Typography variant="body1">Lead Time (hours)</Typography>
            <Typography variant="body2" color="textSecondary">
              Ensures you have enough time to prepare this item
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="leadTime-label">Lead Time</InputLabel>
            <Select
              value={leadTime}
              labelId="leadTime-label"
              label="Lead Time"
              id="leadTime"
              name="leadTime"
              onChange={(e) => setLeadTime(e.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="1">1 Hour</MenuItem>
              <MenuItem value="4">4 Hours</MenuItem>
              <MenuItem value="24">1 Day</MenuItem>
              <MenuItem value="48">2 Days</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Box mb={1}>
              <Typography variant="body1">Order Date</Typography>
              <Typography variant="body2" color="textSecondary">
                Accept orders only for dates that fall within a specified date range. Works great for seasonal items.
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <BDatePicker value={value?.date?.min} label="Min Date" onChange={(d) => setMinDate(d)} disablePast />
          </Grid>
          <Grid item sm={6} xs={12}>
            <BDatePicker value={value?.date?.max} label="Max Date" onChange={(d) => setMaxDate(d)} disablePast minDate={moment(minDate).toDate()} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
