import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import moment from "moment";
import ImageCarousel from "../../common/component/ImageCarousel";
import currency from "currency.js";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import ErrorIcon from "@mui/icons-material/Error";

export default function ChatHeader(props) {
  const { request, quote, status } = props;

  let photos = [];
  let date = "";
  let total = "";

  if (quote.items && quote.items.length > 0) {
    date = quote.fulfillment.date;
    total = quote.items.reduce((acc, o) => +acc + (+o.total || +o.price), 0);

    for (const item of quote.items) {
      photos.push(...item.images);
    }
  } else {
    date = request.date;
    total = quote.quote;
    photos = request.pics;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <ImageCarousel images={photos} />
      </Grid>
      <Grid item xs={8}>
        <Box style={{ color: "#777" }}>
          <Typography variant="h6" style={{ color: "#888", textTransform: "capitalize" }}>
            {request.name}
          </Typography>
          <Box my={0.5}>
            <Typography variant="caption" style={{ display: "flex", alignItems: "center", color: "#888", textTransform: "uppercase" }}>
              <EventOutlinedIcon style={{ fontSize: "1.3em", color: "#888", marginRight: "5px" }} />
              {`${moment(date).format("ddd, MMM D, YYYY")}`}
            </Typography>
          </Box>
          {status === "pending" && (
            <Box my={0.5}>
              <Typography variant="caption" style={{ display: "flex", alignItems: "center", color: "#e67e22" }}>
                <AccessTimeFilledIcon style={{ fontSize: "1.3em", color: "#e67e22", marginRight: "5px" }} />
                Quote pending for {moment(quote?.reqTs?._seconds * 1000).fromNow(true)}
              </Typography>
            </Box>
          )}
          {(status === "active" || status === "review") && (
            <Box my={0.5}>
              <Typography variant="caption" style={{ display: "flex", alignItems: "center", color: "#888", textTransform: "uppercase" }}>
                <ScheduleSendOutlinedIcon style={{ fontSize: "1.3em", color: "#888", marginRight: "5px" }} />
                {currency(total).format()}
              </Typography>
            </Box>
          )}
          {status === "unavailable" && (
            <Box my={0.5}>
              <Typography variant="caption" style={{ display: "flex", alignItems: "center", color: "#c23616" }}>
                <DoNotDisturbAltOutlinedIcon style={{ fontSize: "1.3em", color: "#c23616", marginRight: "5px" }} />
                Quote not sent
              </Typography>
            </Box>
          )}
          {status === "expired" && (
            <Box my={0.5}>
              <Typography variant="caption" style={{ display: "flex", alignItems: "center", color: "#c23616" }}>
                <ErrorIcon style={{ fontSize: "1.3em", color: "#c23616", marginRight: "5px" }} />
                {currency(total).format()} (expired {moment(quote.exdate).fromNow()})
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
