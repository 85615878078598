import React, { useEffect, useState } from "react";
import { Box, Container, LinearProgress, Typography } from "@mui/material";
import Review from "../../review/component/Review";
import bkstApi from "../../api";

export default function MyReviews(props) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/my/reviews`).then((res) => {
      setReviews(res.data.reviews);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box>
          <Box my={6} align="center">
            <Typography variant="h3">Reviews</Typography>
          </Box>
          <Box>
            {reviews.map((el) => {
              return (
                <Box mb={2} key={el.id}>
                  <Review data={el} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Container>
    );
  }
}
