import React from "react";
import { Alert, Box, Chip, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import Images from "../../request/components/Images";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import OrderProgress from "../../order/component/OrderProgress";
import CustomerContactInfoButton from "../../common/component/CustomerContactInfoButton";
import HTMLViewer from "../../common/component/HTMLViewer";
import LineItemDetails from "../../common/component/lineitem/LineItemDetails";
import ImageCarousel from "../../common/component/ImageCarousel";

export default function KitchenOrder(props) {
  const { order } = props;

  let alert = <Box></Box>;
  if (order.status === "fulfilled" || order.status === "completed") {
    alert = (
      <Box>
        <Alert severity="success">This order has been completed</Alert>
      </Box>
    );
  } else if (order.status === "canceled") {
    alert = (
      <Box>
        <Alert severity="error">This order has been canceled</Alert>
      </Box>
    );
  }

  if (order.items) {
    return (
      <Box my={6}>
        <Grid container alignItems={"center"}>
          <Grid item xs={6}>
            <CustomerContactInfoButton customer={order.customer} />
          </Grid>
          <Grid item xs={6} align="right">
            <OrderProgress orderId={order.id} progress={order.progress} />
          </Grid>
        </Grid>
        <Paper elevation={1}>
          {alert}
          <Box px={2} py={3}>
            <Box>
              {order.items.map((item, idx) => (
                <Box key={idx} my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={1} textAlign={"center"}>
                      <Box
                        sx={{
                          width: "40px",
                          height: "40px",
                          border: "1px solid #DDD",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Typography variant="h6">{item.quantity} </Typography>
                      </Box>
                      <Typography variant="overline">QTY</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <ImageCarousel images={item.images} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        {item.title}
                      </Typography>
                      <LineItemDetails item={item} />
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
            {order.invoice && (
              <Box my={2}>
                {Object.entries(order.invoice)
                  .filter(([k, v]) => v.status !== "void")
                  .map(([key, value]) => {
                    return (
                      <Grid item xs={6}>
                        <Typography variant="overline" color="textSecondary">
                          ADD-ON
                        </Typography>
                        <Typography variant="body2">{value.description}</Typography>
                      </Grid>
                    );
                  })}
              </Box>
            )}
            {order.kitchen && (
              <Box my={2}>
                <Typography variant="overline" color="textSecondary">
                  INSTRUCTIONS
                </Typography>
                <HTMLViewer content={order.kitchen.notes} style={{ fontSize: ".85rem" }} />
              </Box>
            )}
            <Box mt={2}>
              <Typography style={{ display: "flex", alignItems: "center" }}>
                {order.fulfillment.type === "delivery" ? (
                  <LocalShippingOutlinedIcon style={{ fontSize: "2em", color: "#999", marginRight: "10px" }} />
                ) : (
                  <LocalMallOutlinedIcon style={{ fontSize: "2em", color: "#999", marginRight: "10px" }} />
                )}
                <Box>
                  <Typography variant="overline" display="block" color="textSecondary">
                    {order.fulfillment.type}
                  </Typography>
                  <Typography variant="body2" display="block">
                    {moment(order.fulfillment.date).format("ddd, MMM D")} [{order.fulfillment.time}]
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  } else {
    return (
      <Box my={6}>
        <Grid container alignItems={"center"}>
          <Grid item xs={6}>
            <CustomerContactInfoButton customer={order.customer} />
          </Grid>
          <Grid item xs={6} align="right">
            <OrderProgress orderId={order.id} progress={order.progress} />
          </Grid>
        </Grid>
        <Paper elevation={1}>
          {alert}
          <Box px={2} py={3}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item sm={6} xs={12} align="center">
                <Images pics={order.details.pics} allowDownload={true} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Grid container spacing={1}>
                  <Grid item sm={12} xs={12}>
                    <Typography variant="overline" color="textSecondary" display="block">
                      TITLE
                    </Typography>
                    <Typography variant="body2" display="block">
                      {order.details.title}
                    </Typography>
                  </Grid>
                  {order.details.size && (
                    <Grid item sm={6} xs={6}>
                      <Typography variant="overline" color="textSecondary" display="block">
                        SIZE
                      </Typography>
                      <Typography variant="body2" display="block">
                        {`${order.details.shape || ""} ${order.details.size}`}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item sm={6} xs={6}>
                    <Typography variant="overline" color="textSecondary" display="block">
                      SERVINGS
                    </Typography>
                    <Typography variant="body2" display="block">
                      {order.details.servings}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography variant="overline" color="textSecondary" display="block">
                      FLAVOR
                    </Typography>
                    <Typography variant="body2" display="block">
                      {order.details.flavor || `Not specified`}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography variant="overline" color="textSecondary" display="block">
                      FILLING
                    </Typography>
                    <Typography variant="body2" display="block">
                      {order.details.filling || `Not specified`}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography variant="overline" color="textSecondary" display="block">
                      FROSTING
                    </Typography>
                    <Typography variant="body2" display="block">
                      {order.details.frosting}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="overline" color="textSecondary" display="block">
                      INSCRIPTION
                    </Typography>
                    <Typography variant="body2" display="block">
                      {order.details.inscription || `Not specified`}
                    </Typography>
                  </Grid>
                  {order.details.tags && order.details.tags.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="overline" color="textSecondary" display="block">
                        TAGS
                      </Typography>
                      {order.details.tags.map((e, idx) => (
                        <Chip key={idx} size="small" label={e} />
                      ))}
                    </Grid>
                  )}
                  {order.details.decor && order.details.decor.length > 2 && (
                    <Grid item xs={12}>
                      <Typography variant="overline" color="textSecondary" display="block">
                        NOTES
                      </Typography>
                      <HTMLViewer content={order.details.decor} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {order.invoice && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {Object.entries(order.invoice).map(([key, value]) => {
                      return (
                        <Grid item xs={6}>
                          <Typography variant="overline" color="textSecondary">
                            ADD-ON
                          </Typography>
                          <Typography variant="body2">{value.description}</Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              )}
              {order.kitchen && (
                <Grid item xs={12}>
                  <Typography variant="overline" color="textSecondary">
                    INSTRUCTIONS
                  </Typography>
                  <HTMLViewer content={order.kitchen.notes} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box mt={2}>
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    {order.fulfillment.type === "delivery" ? (
                      <LocalShippingOutlinedIcon style={{ fontSize: "2em", color: "#999", marginRight: "10px" }} />
                    ) : (
                      <LocalMallOutlinedIcon style={{ fontSize: "2em", color: "#999", marginRight: "10px" }} />
                    )}
                    <Box>
                      <Typography variant="overline" display="block" color="textSecondary">
                        {order.fulfillment.type}
                      </Typography>
                      <Typography variant="body2" display="block">
                        {moment(order.fulfillment.date).format("ddd, MMM D")} [{order.fulfillment.time}]
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    );
  }
}
