import React, { useContext, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ProductTypeAutocomplete from "./ProductTypeAutocomplete";
import ProductShapeAutocomplete from "./ProductShapeAutocomplete";
import ProductSizeAutocomplete from "./ProductSizeAutocomplete";
import ProductServingsAutocomplete from "./ProductServingsAutocomplete";
import FrostingAutocomplete from "./FrostingAutocomplete";
import { SIZE_SERVINGS_MAP } from "../constants";
import { UserContext } from "../../context/UserContext";

export default function ProductAttributes(props) {
  const { user } = useContext(UserContext);

  const [productType, setProductType] = useState(props.value.productType || "Cake");
  const [shape, setShape] = useState(props.value.shape || "");
  const [size, setSize] = useState(props.value.size || "");
  const [frosting, setFrosting] = useState(props.value.frosting || "");
  const [servings, setServings] = useState(props.value.servings || "");

  const handleProductTypeChange = (val) => {
    setProductType(val);
    if (val === "Cake") {
      setShape("");
      setSize("");
    } else {
      setSize(val);
    }
    setServings("");
    setFrosting("");
  };

  const handleShapeChange = (val) => {
    setShape(val);
    setSize("");
    setServings("");
  };

  const handleSizeChange = (val) => {
    setSize(val);
    if (productType === "Cake") {
      if (user.config?.sizeChart) {
        const match = user.config.sizeChart['Cake']?.[shape].filter(o => o.size === val);
        if(match?.length > 0){
          setServings(match[0].servings);
        }
      } else {
        setServings(SIZE_SERVINGS_MAP[val]);
      }
    } else {
      setServings("");
    }
  };

  useEffect(() => {
    props.onChange({ productType, shape, size, servings, frosting });
  }, [productType, shape, size, servings, frosting]);

  const showFrostingOptions = productType === "Cake" || productType === "Cookies" || productType === "Cupcakes";

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <ProductTypeAutocomplete value={productType} label="Product Type" onChange={handleProductTypeChange} required={props.required || false} />
      </Grid>
      {productType === "Cake" && (
        <Grid item xs={12} sm={6}>
          <ProductShapeAutocomplete
            productType={productType}
            value={shape}
            label="Shape"
            onChange={handleShapeChange}
            required={props.required || false}
          />
        </Grid>
      )}
      {productType === "Cake" && (
        <Grid item xs={12} sm={6}>
          <ProductSizeAutocomplete
            productType={productType}
            shape={shape}
            value={size}
            label="Size"
            onChange={handleSizeChange}
            required={props.required || false}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={showFrostingOptions ? 6 : 12}>
        <ProductServingsAutocomplete
          productType={productType}
          value={servings}
          label={productType === "Cake" ? "Servings" : "Quantity"}
          onChange={(v) => setServings(v)}
          required={props.required || false}
        />
      </Grid>
      {showFrostingOptions && (
        <Grid item xs={12} sm={6}>
          <FrostingAutocomplete
            productType={productType}
            value={frosting}
            label="Frosting"
            onChange={(v) => setFrosting(v)}
            required={props.required || false}
          />
        </Grid>
      )}
    </Grid>
  );
}

