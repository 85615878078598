import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { SERVINGS } from "../constants";

export default function ProductServingsAutocomplete(props) {
  const { productType, value } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (productType === "Cake") {
      setOptions(SERVINGS);
    } else if (productType === "Cupcakes" || productType === "Cake Pops" || productType === "Cookies" || productType === "Shooters") {
      setOptions(["1 dozen", "2 dozen", "3 dozen", "4 dozen", "5 dozen"]);
    } else {
      setOptions([]);
    }
  }, [productType]);

  const onChange = (e, v) => {
    props.onChange(v || "");
  };

  return (
    <Autocomplete
      value={value || ""}
      onChange={onChange}
      options={options}
      renderInput={(params) => <TextField required={props.required} variant="outlined" {...params} label={props.label} />}
      freeSolo
      blurOnSelect={true}
      autoSelect
    />
  );
}
