import React, { useContext } from "react";
import { Box, FormControl, Grid, TextField } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import PhoneField from "../../common/input/PhoneField";
import ShowMore from "../../common/component/ShowMore";

export default function CustomerDetailEntry(props) {
  const { user } = useContext(UserContext);
  const { customer } = props;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              value={customer?.name}
              variant="outlined"
              fullWidth
              id="name"
              label="Name"
              name="name"
              onChange={(e) => props.onChange({ ...customer, name: e.target.value })}
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <PhoneField name="Phone" value={customer?.phone} onChange={(phone) => props.onChange({ ...customer, phone })} required />
          </FormControl>
        </Grid>
      </Grid>
      <Box>
        <ShowMore fullWidth>
          <FormControl variant="outlined" fullWidth>
            <TextField
              value={customer?.email}
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              name="email"
              onChange={(e) => props.onChange({ ...customer, email: e.target.value })}
            />
          </FormControl>
        </ShowMore>
      </Box>
    </Box>
  );
}
