import React, { useState } from "react";
import { Box, Chip, FormControl, FormHelperText, Typography } from "@mui/material";

export default function MultiSelectChips(props) {
  const [value, setValue] = useState(props.value ? props.value.split(",") : []);

  const handleClick = (clicked) => {
    let arr = [...value];
    if (value.find((e) => e === clicked)) {
      const index = value.findIndex((e) => e === clicked);
      arr.splice(index, 1);
    } else {
      arr.push(clicked);
    }
    setValue(arr);
    props.setValue(arr.join());
  };

  return (
    <FormControl fullWidth required style={{ marginBottom: "15px" }}>
      <Typography variant="body1">{props.label}</Typography>
      <Box component="ul" style={{ margin: "0", padding: "0", listStyle: "none", display: "flex", flexWrap: "wrap" }}>
        {props.options.map((option, idx) => {
          return (
            <li key={idx}>
              <Chip
                label={option}
                style={{ margin: "5px" }}
                onClick={() => handleClick(option)}
                variant={value.includes(option) ? "default" : "outlined"}
              />
            </li>
          );
        })}
      </Box>
      <FormHelperText>{props.desc}</FormHelperText>
    </FormControl>
  );
}
