import React, { useState, useEffect } from "react";
import { Box, Link, Typography } from "@mui/material";
import moment from "moment";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";

export default function OrderCalendar(props) {
  const [ordersByDate, setOrdersByDate] = useState();

  useEffect(() => {
    let tmp = {};
    for (const o of props.orders) {
      let arr = tmp[o.fulfillment.date] || [];
      arr.push(o);
      tmp[o.fulfillment.date] = arr;
    }
    setOrdersByDate(tmp);
  }, [props.orders]);

  const getOrderCountByDate = (dt) => {
    let count = 0;
    if (ordersByDate) {
      let orderData = ordersByDate[dt.format("YYYY-MM-DD")];
      count = (orderData && orderData.length) || 0;
    }

    return count;
  };

  let days = [];
  for (let i = 0; i < 7; i++) {
    const dt = moment(props.from).add(i, "days");
    days.push(dt);
  }

  return (
    <Box>
      <Box my={2} sx={{ display: "flex", gap: "10px", overflowX: "auto", maxWidth: "85vw" }}>
        {days.map((dt, idx) => {
          const isSelected = props.selected === dt.format("YYYY-MM-DD");
          return (
            <Link key={idx} onClick={() => props.onChange(dt.format("YYYY-MM-DD"))} underline="none" sx={{ cursor: "pointer", color: "inherit" }}>
              <Box
                sx={{
                  border: isSelected ? "1px solid #78374a" : "1px solid #CCC",
                  borderRadius: "4px",
                  padding: "7px",
                  minWidth: "100px",
                  textAlign: "center"
                }}
              >
                <Typography variant="h6" sx={{ color: isSelected ? "#78374a" : "inherit" }}>
                  {dt.format("ddd")}
                </Typography>
                <Typography variant="overline" color="textSecondary" display="block">
                  {dt.format("MMM DD")}
                </Typography>
                <Box mt={0.5}>
                  <Typography style={{ display: "flex", alignItems: "center", fontSize: ".8rem", justifyContent: "center" }}>
                    <LocalMallOutlinedIcon style={{ fontSize: "1rem", marginRight: "3px", color: "#777" }} />
                    {getOrderCountByDate(dt)}
                  </Typography>
                </Box>
              </Box>
            </Link>
          );
        })}
      </Box>
    </Box>
  );
}
