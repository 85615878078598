import React, { useContext, useEffect, useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { capitalize, formatPhoneNumber, getCustomerFirstNameLastInitial, getCustomerName, hasManagerAccess } from "../../common/util";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import CloseIcon from "@mui/icons-material/Close";
import bkstApi from "../../api";
import ClickToCopy from "../../common/ClickToCopy";
import ClickToCall from "../../common/ClickToCall";
import LoyaltyStatus from "./LoyaltyStatus";
import CustomerOrderSummary from "../../order/component/CustomerOrderSummary";
import QuoteSummary from "../../quote/component/CustomerQuoteSummary";
import { UserContext } from "../../context/UserContext";
import FeatureAccess from "../../baker/components/FeatureAccess";

export default function CustomerDetailsButton(props) {
  const { user } = useContext(UserContext);
  const { customer, src } = props;

  const name = getCustomerName(customer);
  const phone = customer?.phone;
  const email = customer?.email;

  const [data, setData] = useState("");
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (phone) {
      bkstApi(`/my/customer/${phone}`).then((res) => {
        setData(res.data.customer);
      });
    }
  }, [phone]);

  let isManager = hasManagerAccess(user.role);

  return (
    <span>
      <Box sx={{ display: "inline-flex", alignItems: "center", cursor: "pointer", gap: "3px" }} onClick={() => setOpen(true)}>
        <IconButton size="small">
          <PermIdentityOutlinedIcon />
        </IconButton>
        {name && <Typography variant="body2">{getCustomerFirstNameLastInitial(name)}</Typography>}
        {data?.level && <LoyaltyStatus status={data.level} />}
      </Box>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="xs" fullWidth>
        <DialogTitle style={{ margin: "0", padding: "0" }}>
          <Box p={2} alignItems="center" sx={{ display: "flex", gap: "3px" }}>
            <IconButton size="small">
              <PermIdentityOutlinedIcon />
            </IconButton>
            {name && <Typography variant="h6">{getCustomerFirstNameLastInitial(name)}</Typography>}
            {data?.level && <LoyaltyStatus status={data.level} />}
          </Box>
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ borderTop: "1px solid #EEE" }}>
            <Tabs value={tab} variant="fullWidth">
              <Tab key={0} label={"Profile"} onClick={() => setTab(0)} />
              {phone && <Tab key={1} label={"Orders"} onClick={() => setTab(1)} />}
              {isManager && phone && <Tab key={2} label={"Quotes"} onClick={() => setTab(2)} />}
            </Tabs>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ minHeight: "300px", backgroundColor: "#ECECEC" }}>
          {tab === 0 && (
            <Box py={4}>
              <Box>
                <Typography variant="overline" color="textSecondary">
                  NAME
                </Typography>
                <Typography variant="body1">{name}</Typography>
              </Box>
              {data?.level && (
                <Box my={2}>
                  <Typography variant="overline" color="textSecondary">
                    LOYALTY STATUS
                  </Typography>
                  <Typography variant="body1">{capitalize(data?.level)}</Typography>
                </Box>
              )}
              {phone && (
                <Box my={2}>
                  <Typography variant="overline" color="textSecondary">
                    PHONE
                  </Typography>
                  <Typography variant="body1">
                    {formatPhoneNumber(phone)} <ClickToCopy text={phone} />
                    <ClickToCall phone={phone} />
                  </Typography>
                </Box>
              )}
              {email && (
                <Box my={2}>
                  <Typography variant="overline" color="textSecondary">
                    EMAIL
                  </Typography>
                  <Typography variant="body1">
                    {email} <ClickToCopy text={email} />
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {tab === 1 && (
            <Box py={4}>
              <FeatureAccess plan={["professional", "premium"]} />
              {data.orders
                ?.sort((o1, o2) => o2.fulfillment.date.localeCompare(o1.fulfillment.date))
                .map((order) => {
                  return <CustomerOrderSummary key={order.id} order={order} />;
                })}
            </Box>
          )}
          {tab === 2 && (
            <Box py={4}>
              <FeatureAccess plan={["professional", "premium"]} />

              <Box mb={4} textAlign={"center"}>
                <Typography variant="body2" color="textSecondary">
                  Quotes for upcoming event dates
                </Typography>
              </Box>
              {data.quotes
                ?.sort((o1, o2) => o2.date.localeCompare(o1.date))
                .map((request, idx) => {
                  return <QuoteSummary key={idx} request={request} />;
                })}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </span>
  );
}
