import React from "react";
import { Box, Typography } from "@mui/material";

export default function LatePaymentPolicy(props) {
  return (
    <Box my={2} id="late-payment-policy">
      <Typography variant="body1">Late Payment Policy</Typography>
      <Box>
        <Typography variant="caption" color="textSecondary">
          If your order is not paid in full at least 7 days before the order due date, your order will be canceled and there will be no refunds.
        </Typography>
      </Box>
    </Box>
  );
}
