import React, { useEffect, useRef } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function StaticPage(props) {
  const Icon = props.icon;
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      //ref.current.scrollIntoView();
    }
  }, []);

  return (
    <Container maxWidth="sm" align="center" ref={ref}>
      <Box py={5} px={1}>
        <Icon style={{ fontSize: "5rem" }} />
        <Typography variant="h2" color="textPrimary" gutterBottom>
          {props.title}
        </Typography>
        <Typography component="h5" variant="subtitle1" color="textSecondary" gutterBottom>
          {props.desc}
        </Typography>
        {props.href && (
          <Box py={3}>
            <Button variant="contained" color="primary" size="large" component={RouterLink} to={props.href}>
              {props.btnText}
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
}
