import React, { useState } from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@mui/material";
import moment from "moment";
import bkstApi from "../../api";
import { useNavigate } from "react-router";

export default function MarkOrderCompleted(props) {
  const navigate = useNavigate();

  const { order } = props;

  const [status, setStatus] = useState(order.status);
  const [pics, setPics] = useState([]);
  const [error, setError] = useState("");

  const [open, setOpen] = useState(false);

  const markCompleted = (e) => {
    bkstApi
      .post(`/my/order/${order.id}/get-paid`, { pics })
      .then((res) => {
        setOpen(false);
        setStatus("done");
      })
      .catch((err) => {
        setError(err.response && err.response.data && err.response.data.msg);
      });
  };

  //const hoursSinceOrderPlaced = moment().diff(moment(order.ts._seconds * 1000), "hours");
  const isDue = moment().isSameOrAfter(moment(order.fulfillment.date), "day");
  const isPaidInFull = +order.payment.due === 0;

  let isReadyForCompletion = false;
  if (status === "confirmed" && isDue && isPaidInFull) {
    if (order.paymentType === "offline") {
      isReadyForCompletion = true;
    } else if (order.src === "direct" && order.transfers) {
      isReadyForCompletion = true;
    } else if (order.src === "mkt" && !order.transferId) {
      isReadyForCompletion = true;
    }
  }

  if (isReadyForCompletion) {
    return (
      <Box>
        <Alert severity="info">
          Is this order complete?{" "}
          <Link
            sx={{ fontWeight: "700", cursor: "pointer", padding: "3px 7px" }}
            onClick={() => (order.src === "mkt" ? navigate(`/order/${order.id}/get-paid`) : setOpen(true))}
          >
            YES
          </Link>
          /
          <Link sx={{ fontWeight: "700", cursor: "pointer", padding: "3px 7px" }} onClick={() => setStatus("hide")}>
            NO
          </Link>
        </Alert>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
          <DialogTitle>Complete this Order?</DialogTitle>
          <DialogContent>
            <Box my={2}>
              {error && (
                <Box mb={2}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
              <Typography variant="subtitle1" color="textSecondary">
                By marking this order complete, I confirm that the order was either picked up or delivered to the customer.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setOpen(false)} color="secondary">
              CANCEL
            </Button>
            <Button color="primary" onClick={markCompleted}>
              CONFIRM
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  } else if (status === "done") {
    return (
      <Box>
        <Alert severity="success">This order is now complete!</Alert>
      </Box>
    );
  } else {
    return <Box />;
  }
}
