import React, { useState, useEffect } from "react";
import { Box, Container, LinearProgress, Step, StepLabel, Stepper, Typography } from "@mui/material";
import bkstApi from "../../api";
import BakerOnboardingPayment from "../components/BakerOnboardingPayment";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import StaticPage from "../../common/StaticPage";
import BakerOnboardingOwner from "../components/BakerOnboardingOwner";
import BakerOnboardingBakery from "../components/BakerOnboardingBakery";

export default function BakerOnboarding(props) {
  const [step, setStep] = useState(window.location.pathname.endsWith("/payment") ? 2 : 0);
  const [account, setAccount] = useState();

  useEffect(() => {
    bkstApi.get("/my/onboarding").then((res) => {
      setAccount(res.data.account);
    });
  }, []);

  const gotoStep = (to) => {
    setStep(to);
    window.scrollTo(0, 0);
  };

  if (!account) {
    return <LinearProgress />;
  } else if (account.status === "pending" || account.status === "quote") {
    return (
      <Container maxWidth="sm">
        <Box mt={5}>
          <Box align="center">
            <Typography variant="h3" gutterBottom>
              Welcome to BakeStreet!
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Let's complete your onboarding to get you started
            </Typography>
          </Box>
          <Box my={4}>
            <Stepper activeStep={step} alternativeLabel>
              <Step key={0}>
                <StepLabel>OWNER</StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>BAKERY</StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel>PAYOUT</StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Box>
            <BakerOnboardingOwner data={account} step={step} done={() => gotoStep(1)} />
            <BakerOnboardingBakery data={account} step={step} done={() => gotoStep(2)} />
            <BakerOnboardingPayment data={account} step={step} />
          </Box>
        </Box>
      </Container>
    );
  } else if (account.status === "onboard") {
    return (
      <StaticPage
        title="Hooray!"
        desc="We're super excited to have you aboard. Please check out your public profile & add photos to showcase your work."
        icon={CheckCircleOutlineOutlinedIcon}
        href={`/${account.handle}`}
        btnText="View Profile"
      ></StaticPage>
    );
  }
}
