import React from "react";
import { Box } from "@mui/material";
import FilterChip from "../../common/component/FilterChip";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

export default function OrderFilter(props) {
  const { orders, filter } = props;

  if (orders) {
    return (
      <Box sx={{ display: "flex", gap: "7px", alignItems: "center", padding: "7px", border: "1px solid #EEE" }}>
        <FilterListOutlinedIcon sx={{ color: "#CCC" }} />
        <FilterChip
          label="Type"
          values={["custom", "standard"]}
          onChange={(value) => {
            props.onChange(value);
          }}
        />
      </Box>
    );
    /*return (
      <ToggleButtonGroup
        color="primary"
        sx={{ textTransform: "none" }}
        exclusive
        value={filter}
        onChange={(e, v) => {
          if (v) {
            props.onChange(v);
          }
        }}
        fullWidth
      >
        <ToggleButton value="custom" fullWidth>
          <Box mt={2} textAlign={"center"} sx={{ textTransform: "none" }}>
            <Badge
              color={filter === "custom" ? "primary" : "secondary"}
              showZero={true}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              badgeContent={orders.filter((o) => o.type !== "shop").length}
            >
              <DrawOutlinedIcon sx={{ fontSize: "2rem" }} />
            </Badge>
            <Box>
              <Typography variant="overline">CUSTOM</Typography>
            </Box>
          </Box>
        </ToggleButton>
        <ToggleButton value="standard" fullWidth>
          <Box mt={2} textAlign={"center"} sx={{ textTransform: "none" }}>
            <Badge
              color={filter === "standard" ? "primary" : "secondary"}
              showZero={true}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              badgeContent={orders.filter((o) => o.type === "shop").length}
            >
              <ShoppingCartOutlinedIcon sx={{ fontSize: "2rem" }} />
            </Badge>
            <Box>
              <Typography variant="overline">STANDARD</Typography>
            </Box>
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
    );*/
  } else {
    return <Box />;
  }
}
