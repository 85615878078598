import React from "react";
import { Badge, Box, Typography } from "@mui/material";
import moment from "moment";

export default function DayOfWeek(props) {
  const { date, count, highlight } = props;

  return (
    <Badge
      badgeContent={count}
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      showZero={true}
      slotProps={{ badge: { sx: { backgroundColor: "#DEDEDE" } } }}
      sx={{
        flex: "0 0 60px",
        height: "60px",
        border: `1px solid ${highlight ? "#0984e3" : "#DDD"}`,
        borderRadius: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box align="center" sx={{ marginTop: "15px", marginBottom: "15px" }}>
        <Typography sx={{ fontSize: "13px" }}>{moment(date).format("ddd")}</Typography>
        <Box>
          <Typography sx={{ fontSize: "11px", textTransform: "uppercase" }} color="textSecondary">
            {moment(date).format("MMM D")}
          </Typography>
        </Box>
      </Box>
    </Badge>
  );
}
