import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Button, Container, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import bkstApi from "../../api";
import BakerOrderSummary from "../components/BakerOrderSummary";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { UserContext } from "../../context/UserContext";
import BDatePicker from "../../common/input/BDatePicker";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { getQueryParam, isAllowedByFilter, sortByOrderDateTime } from "../../common/util";
import { useNavigate } from "react-router";
import OrderFilter from "../../order/component/OrderFilter";
import { STORAGE_BUCKET } from "../../common/constants";
import OrderCalendar from "../../order/component/OrderCalendar";

const FILTERS = ["recent", "upcoming", "canceled", "date"];

export default function Orders(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  let location = useLocation();

  const [search, setSearch] = useState("recent");
  const [category, setCategory] = useState("");
  const [filter, setFilter] = useState("");

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [loading, setLoading] = useState(true);

  // Check params to identify search criteria
  useEffect(() => {
    // first check filter
    const searchBy = getQueryParam(window.location.search, "search");
    if (FILTERS.includes(searchBy)) {
      if (searchBy === "date") {
        let [from, to] = [getQueryParam(window.location.search, "from"), getQueryParam(window.location.search, "to")];
        if (from && to) {
          runSearch(searchBy, from, to);
        } else {
          setSearch("date");
          setLoading(false);
        }
      } else if (searchBy === "upcoming") {
        const from = moment().format("YYYY-MM-DD");
        const to = moment().add(7, "days").format("YYYY-MM-DD");
        runSearch(searchBy, from, to);
      } else {
        runSearch(searchBy, "", "");
      }
    } else {
      const from = moment().subtract(7, "days").format("YYYY-MM-DD");
      const to = moment().format("YYYY-MM-DD");
      runSearch("recent", from, to);
    }
  }, [location.key]);

  useEffect(() => {
    if (toDt < fromDt) {
      setToDt("");
    }
  }, [fromDt]);

  useEffect(() => {
    applyFilters();
  }, [filter, category]);

  useEffect(() => {
    applyFilters();
  }, [orders]);

  const applyFilters = () => {
    setFilteredOrders([]);
    setFilteredOrders(
      orders.filter((o) => {
        if (search === "upcoming" && category && o.fulfillment.date !== category) {
          return false;
        } else {
          return isAllowedByFilter(o, filter);
        }
      })
    );
  };

  const getDescription = () => {
    if (search === "recent") {
      return `Orders placed in the last 24 hours`;
    } else if (search === "upcoming") {
      return `Orders due in the upcoming days`;
    } else if (search === "date") {
      return `Orders due within the specified date range`;
    }
  };

  const runDateSearch = (e) => {
    e.preventDefault();
    navigate(`/order?search=date&from=${fromDt}&to=${toDt}`);
  };

  const runSearch = (search, fromDate, toDate) => {
    setLoading(true);
    bkstApi.get(`/my/order/v2?search=${search}&fromDt=${fromDate}&toDt=${toDate}`).then((res) => {
      setSearch(search);
      setFromDt(fromDate);
      setToDt(toDate);

      setCategory(search === "upcoming" ? fromDate : "");

      let arr = [];
      if (search === "recent") {
        arr = res.data.orders;
      } else {
        arr = res.data.orders.sort(sortByOrderDateTime);
      }

      setOrders(arr);

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    const description = getDescription();

    return (
      <Box>
        <Container maxWidth="sm">
          <Box>
            <Box mt={4}>
              <Typography variant="h2">Orders</Typography>
              <Box my={1}>
                <Tabs value={search} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile sx={{ borderBottom: "1px solid #ECECEC" }}>
                  <Tab value="recent" label="Recent" component={RouterLink} to={`/order?search=recent`} />
                  <Tab value="upcoming" label="Upcoming" component={RouterLink} to={`/order?search=upcoming`} />
                  <Tab value="date" label="Date Range" component={RouterLink} to={`/order?search=date&from=&to=`} />
                </Tabs>
              </Box>
              <Box my={2}>
                <Alert severity="info">{description}</Alert>
              </Box>
              {search === "date" && (
                <Box px={2} py={4} sx={{ backgroundColor: "#FEFEFE", borderRadius: "4px" }}>
                  <form onSubmit={runDateSearch}>
                    <Box align="center" sx={{ display: "flex", justifyContent: "center" }}>
                      <BDatePicker value={fromDt} label="From Date" onChange={(d) => setFromDt(d)} required autoOk hideIcon={true} />
                      <BDatePicker value={toDt} label="To Date" onChange={(d) => setToDt(d)} required autoOk hideIcon={true} />
                      <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
                        <SearchIcon />
                      </Button>
                    </Box>
                  </form>
                </Box>
              )}
              {search === "upcoming" && (
                <OrderCalendar
                  selected={category}
                  orders={orders}
                  from={moment().format()}
                  to={moment().add(7, "days").format()}
                  onChange={(o) => setCategory(o)}
                />
              )}
              <Box my={2}>
                <OrderFilter orders={orders} filter={filter} onChange={(o) => setFilter(o)} />
              </Box>
            </Box>
          </Box>
          {filteredOrders.length > 0 && (
            <Box my={2}>
              <Typography variant="overline" color="textSecondary">
                Showing {filteredOrders.length} orders
              </Typography>
            </Box>
          )}
          {filteredOrders.map((order) => {
            return (
              <Box>
                <BakerOrderSummary key={order.id} order={order} filter={filter} showTs={search === "recent"} />
              </Box>
            );
          })}
          {filteredOrders.length === 0 && (
            <Box px={2} pb={2} textAlign={"center"} sx={{ border: "1px solid #DDD" }}>
              <img style={{ width: "250px" }} src={`${STORAGE_BUCKET}/img/icon/search-results.svg`} />
              <Typography variant="subtitle1">Nothing found</Typography>
              <Typography variant="body1" color="textSecondary">
                Please change your search criteria & try again.
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
    );
  }
}
