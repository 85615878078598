import React, { useState, useEffect, useContext } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Images from "../../request/components/Images";
import { Alert, AlertTitle } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import currency from "currency.js";

export default function GetPaid(props) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { orderId } = useParams();

  const [order, setOrder] = useState();
  const [pics, setPics] = useState([]);
  const [consent, setConsent] = useState(false);

  const [error, setError] = useState("");
  const [mode, setMode] = useState("load");
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    bkstApi(`/order/${orderId}`).then((res) => {
      setOrder(res.data);
      setMode("show");
    });
  }, [orderId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    bkstApi
      .post(`/my/order/${orderId}/get-paid`, { pics })
      .then((res) => {
        navigate(`/order`);
      })
      .catch((err) => {
        setError(err.response && err.response.data && err.response.data.msg);
        setProcessing(false);
      });
  };

  if (mode === "load") {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Container maxWidth="sm">
          <Box>
            <Box mt={5} mb={4} align="center">
              <Typography variant="h2" gutterBottom>
                Get Paid
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Simply upload a photo of the {order.details?.title} order handed over to the customer to initiate a{" "}
                {currency(order.payment.payable).format()} payment to your bank account
              </Typography>
            </Box>
            <Box my={4}>
              <form onSubmit={handleSubmit}>
                <Paper elevation={0}>
                  <Box px={2} py={2}>
                    <Grid container spacing={2}>
                      <Grid item sm={3} xs={4}>
                        <Images edit={true} pics={pics} onChange={(pics) => setPics(pics)} folder={`img/o/${orderId}`} />
                      </Grid>
                      <Grid item sm={9} xs={8}>
                        <Typography variant="h6">
                          Upload photo <Chip size="small" label={"required"} color={"info"} />
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          This creates an electronic trail with evidence that the order was fulfilled, thereby protecting you in case of a dispute.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mt={1}>
                          <FormControlLabel
                            className="consent"
                            control={
                              <Checkbox required checked={consent} onChange={(e) => setConsent(e.target.checked)} name="consent" color="primary" />
                            }
                            label={`Notify customer that the order ${order.fulfillment.type} has been completed`}
                          />
                        </Box>
                      </Grid>
                      {consent && !error && (
                        <Grid item xs={12}>
                          <Alert severity="error">
                            <AlertTitle>Important</AlertTitle>Claiming payment before the order is handed over to customer is a violation of
                            BakeStreet's terms & conditions and may result in your <u>account suspension</u>
                          </Alert>
                        </Grid>
                      )}
                    </Grid>
                    {error && <Alert severity="error">{error}</Alert>}
                  </Box>
                </Paper>
                <Box mt={2} align="right">
                  <Button type="submit" variant="contained" color="primary" disabled={pics.length === 0}>
                    SUBMIT
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
        {processing && (
          <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    );
  }
}
