import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { TIME_WINDOWS } from "../../common/constants";
import { Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import BDatePicker from "../../common/input/BDatePicker";
import moment from "moment";
import AddressAutocomplete from "../../common/gmaps/AddressAutocomplete";
import { getFormattedAddress } from "../../common/util";

export default function OrderFulfillmentEntry(props) {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [order, setOrder] = useState("");

  const [type, setType] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [instructions, setInstructions] = useState("");
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [consent, setConsent] = useState(false);

  const [storeLocations, setStoreLocations] = useState([]);

  const [timeslotsConfig, setTimeslotsConfig] = useState();
  const [timeslots, setTimeslots] = useState(TIME_WINDOWS);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/order/${orderId}`)
      .then((res) => {
        setOrder(res.data);

        const orderFulfillment = res.data.fulfillment;
        setType(orderFulfillment.type);
        setLocation(orderFulfillment.location || "");
        setAddress(orderFulfillment.address);

        setDate(orderFulfillment.date);
        setTime(orderFulfillment.time || "");
        setInstructions(orderFulfillment.instructions || "");
        setStatus(res.data.status);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // get timeslots
    bkstApi(`/my/location`).then((res) => {
      setStoreLocations(res.data.locations);
    });
  }, [orderId]);

  useEffect(() => {
    if (type && date && timeslotsConfig) {
      const configByType = timeslotsConfig[type];

      const dayOfWeek = moment(date).format("ddd").toLowerCase();
      const timeslotsByTypeAndDay = configByType[dayOfWeek];
      if (timeslotsByTypeAndDay) {
        setTimeslots(timeslotsByTypeAndDay);
      }
    } else {
      setTimeslots(TIME_WINDOWS);
    }
  }, [type, date]);

  const onTypeChange = (e) => {
    setType(e.target.value);
    setLocation("");
    setAddress("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fulfillment = {
      type,
      address,
      location,
      date,
      time,
      instructions
    };

    bkstApi.put(`/order/${orderId}/fulfillment`, { fulfillment, consent }).then((res) => {
      navigate(`/order/${orderId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box pt={3}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="overline">FULFILLMENT</Typography>
            </Grid>
          </Grid>
          <Paper>
            <Box px={2} py={2}>
              {order.fulfillment.type === "delivery" && order.fulfillment.provider?.name === "metrobi" && (
                <Box mb={2}>
                  <Alert severity="warning">
                    Metrobi doesn't support changes to scheduled deliveries at this time. After making changes here, be sure to update this delivery
                    on Metrobi's website.
                  </Alert>
                </Box>
              )}
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <RadioGroup row name="type" value={type} onChange={onTypeChange}>
                      <FormControlLabel value="pickup" control={<Radio />} label={`Pickup`} size="large" color="primary" />
                      <FormControlLabel value="delivery" control={<Radio />} label={`Delivery`} />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    {type === "pickup" && (
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="address-label">Address</InputLabel>
                        <Select
                          value={location}
                          labelId="location-label"
                          label="Location"
                          id="location"
                          name="location"
                          renderValue={(option) => {
                            return option.description || getFormattedAddress(option);
                          }}
                          onChange={(e) => {
                            let newVal = e.target.value;
                            setLocation(newVal);
                            setAddress(getFormattedAddress(newVal));
                          }}
                        >
                          {storeLocations.map((o, idx) => {
                            return (
                              <MenuItem key={idx} value={o.address}>
                                {getFormattedAddress(o.address)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                    {type === "delivery" && (
                      <FormControl variant="outlined" fullWidth>
                        <AddressAutocomplete
                          value={location || ""}
                          onPlaceSelect={(loc) => {
                            setLocation(loc);
                            setAddress(loc.description || getFormattedAddress(loc));
                          }}
                          label="Delivery Address"
                          required
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <BDatePicker value={date || null} label="Date" onChange={(d) => setDate(d)} disablePast required autoOk />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      value={time}
                      onChange={(e, value) => setTime(value)}
                      options={timeslots}
                      renderInput={(params) => <TextField variant="outlined" {...params} label="Time" />}
                      disableClearable
                      freeSolo
                      autoSelect
                      blurOnSelect={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="instructions"
                      value={instructions}
                      label="Instructions"
                      name="instructions"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setInstructions(e.target.value)}
                    />
                  </Grid>
                  {order.paymentType !== "offline" && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        className="consent"
                        control={
                          <Checkbox
                            required={status === "confirmed"}
                            checked={consent}
                            onChange={(e) => setConsent(e.target.checked)}
                            name="consent"
                            color="secondary"
                          />
                        }
                        label="I've discussed these changes with the customer and we're in agreement"
                      />
                      {consent && <Alert severity="info">Customer will be notified when you save these changes</Alert>}
                    </Grid>
                  )}
                  <Grid item xs={12} align="right">
                    <Button variant="text" onClick={(e) => navigate(`/order/${orderId}`)} style={{ marginRight: "1rem" }}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}
