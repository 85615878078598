import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LineItemEntry from "./LineItemEntry";

export default function LineItemEntryPopup(props) {
  const [item, setItem] = useState(props.item);
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    props.onChange(item);
    setOpen(false);
  };

  return (
    <>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <EditOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Edit Line Item</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <LineItemEntry item={item} onChange={(o) => setItem(o)} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ marginRight: "15px" }} variant="text" color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            DONE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
