import React from "react";
import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import currency from "currency.js";
import ShareButton from "../../common/component/ShareButton";

export default function Invoices(props) {
  const { order } = props;

  const getBgColor = (status) => {
    if (status === "open") {
      return "#e67e22";
    } else if (status === "paid" || status === "refund") {
      return "#1abc9c";
    } else {
      return "#AAA";
    }
  };

  return (
    <Box mt={4}>
      <Typography variant="overline" color="textSecondary">
        ADD-ONS
      </Typography>
      <Divider />
      <Box>
        {Object.entries(order.invoice)
          .sort()
          .map(([key, value]) => {
            return (
              <Box pt={2}>
                <Grid key={key} container alignItems="center">
                  <Grid item xs={8} sm={10}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={9}>
                        <Typography variant="body2">{value.description}</Typography>
                        {value.status === "open" && (
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            dangerouslySetInnerHTML={{
                              __html: `&#128338; sent ${moment(value.ts).fromNow()}`
                            }}
                          ></Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Chip size="small" label={value.status} style={{ color: "#fff", backgroundColor: getBgColor(value.status) }} />
                          {value.status === "open" && order.src === "direct" && <ShareButton type="add-on" link={`/order/${order.id}/in/${key}`} />}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} sm={2} align="right">
                    <Typography variant="overline">{currency(value.amount.addOn).format()}</Typography>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
