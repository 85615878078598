import React from "react";
import { Chip } from "@mui/material";

export default function LoyaltyStatus(props) {
  const { status } = props;

  let bgColor = "#ECECEC";
  let color = "#000";
  if (status === "bronze") {
    bgColor = "#ebccad";
    color = "#7b4c1e";
  } else if (status === "silver") {
    bgColor = "#BCC6CC";
    color = "#34495e";
  } else if (status === "gold") {
    bgColor = "#ffe34d";
    color = "#665600";
  }

  return <Chip size="small" label={status} sx={{ backgroundColor: bgColor, color }} />;
}
