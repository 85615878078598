import React, { useState, useEffect } from "react";
import { Box, Container, Chip, LinearProgress, TextField, Typography } from "@mui/material";
import moment from "moment";
import bkstApi from "../../api";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { useNavigate } from "react-router";

export default function KitchenDashboard(props) {
  const navigate = useNavigate();

  const [value, setValue] = useState();

  const [ordersByDate, setOrdersByDate] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fromDate = moment(value).startOf("month");
    const toDate = moment(value).endOf("month");

    bkstApi(`/my/order?filter=date&fromDt=${fromDate.format("YYYY-MM-DD")}&toDt=${toDate.format("YYYY-MM-DD")}`).then((res) => {
      let tmp = {};
      for (const o of res.data.orders) {
        let arr = tmp[o.fulfillment.date] || [];
        arr.push(o);
        tmp[o.fulfillment.date] = arr;
      }
      setOrdersByDate(tmp);

      setLoading(false);
    });
  }, [value]);

  const handleMonthChange = (date) => {
    setValue(date);
  };

  const getDateTotals = (dt) => {
    let count = 0;
    let orderData = ordersByDate && ordersByDate[moment(dt).format("YYYY-MM-DD")];
    if (orderData) {
      count = (orderData && orderData.length) || 0;
    }

    return { count };
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="md" disableGutters>
        <Box my={6} align="center">
          <Typography variant="h2" gutterBottom>
            Order Calendar
          </Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            views={["month", "day"]}
            value={value}
            /* onChange={(newValue) => {
              console.log('inside onChange');
              const date = moment(newValue).format("YYYY-MM-DD");
              console.log(date);
              navigate(`/order?filter=date&from=${date}&to=${date}`);
            }} */
            onMonthChange={handleMonthChange}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              "& .MuiDateCalendar-root": {
                width: "100%",
                maxWidth: "100%",
                height: "auto",
                maxHeight: "inherit",
                backgroundColor: "#F7F7F7"
              },
              "& .MuiDayCalendar-monthContainer": { border: "1px solid #EEE" },
              "& .MuiDayCalendar-weekContainer": { margin: 0 },
              //"& .MuiPickersCalendarHeader-root": { backgroundColor: "#FFFFFF" },
              //"& .MuiDayCalendar-header": { borderTop: "1px solid #EEE", borderLeft: "1px solid #EEE", borderRight: "1px solid #EEE" },
              "& .MuiDayCalendar-weekDayLabel": { width: "100%", border: "1px solid #EEE", margin: "0" },
              "& .MuiDayCalendar-slideTransition": { minHeight: "500px", height: "auto", maxWidth: "100%", overflow: "scroll" },
              "& .MuiPickersDay-root": {
                width: "100%",
                height: "100%",
                border: "none",
                margin: "1px"
              },
              "& .MuiPickersDay-root:not(.Mui-selected)": {
                border: "none"
              }
            }}
            slots={{
              day: (props) => {
                const { day, selectedDay, ...other } = props;

                const summary = getDateTotals(day);

                return (
                  <PickersDay
                    day={day}
                    {...other}
                    onClick={(e) => {
                      const date = moment(day).format("YYYY-MM-DD");
                      navigate(`/order?filter=date&from=${date}&to=${date}`);
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                        minHeight: "75px",
                        overflow: "hidden",
                        color: "#000",
                        backgroundColor: "#fff",
                        paddingBottom: "3px"
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginBottom: "5px" }}>
                        <Typography variant="overline">{props.day.getDate()}</Typography>
                      </Box>
                      {Boolean(summary.count) && (
                        <Box>
                          <Chip
                            icon={<LocalMallOutlinedIcon style={{ fontSize: "12px" }} />}
                            label={summary.count}
                            size="small"
                            sx={{ fontSize: "10px", height: "20px", margin: "2px" }}
                          />
                        </Box>
                      )}
                    </Box>
                  </PickersDay>
                );
              }
            }}
          />
        </LocalizationProvider>
      </Container>
    );
  }
}
