import React from "react";
import { Box, Chip, IconButton, Paper, Typography } from "@mui/material";
import ImageCarousel from "../../common/component/ImageCarousel";
import IconText from "../../common/IconText";
import moment from "moment";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { Link as RouterLink } from "react-router-dom";
import currency from "currency.js";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import WarningIcon from "@mui/icons-material/Warning";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorIcon from "@mui/icons-material/Error";

export default function CustomerQuoteSummary(props) {
  const { request } = props;

  let status = request.quote?.status;
  if (request.quote?.exdate && moment(request.quote?.exdate).diff(moment(), "minutes") < 0) {
    status = "expired";
  }

  return (
    <Paper key={request.id} elevation={0}>
      <Box mb={4}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box sx={{ width: "125px", height: "125px" }}>
            <ImageCarousel images={request.pics} />
          </Box>
          <Box p={1} sx={{ flexGrow: "1" }}>
            <Box>
              <Typography variant="body1" gutterBottom>
                {request.title}
              </Typography>
              <Box style={{ color: "#888", marginTop: "5px" }}>
                <IconText icon={EventOutlinedIcon} text={`${moment(request.date).format("MMM D, YYYY")}`} />
                {request.quote?.quote && <IconText icon={LocalAtmIcon} text={currency(request.quote.quote).format()} />}
              </Box>
              <Box mt={1}>
                {status === "pending" && <Chip variant="outlined" size="small" icon={<AccessTimeIcon />} label={"pending"} color="info" />}
                {status === "expired" && <Chip variant="outlined" size="small" icon={<WarningIcon />} label={"expired"} color="warning" />}
                {status === "unavailable" && <Chip variant="outlined" size="small" icon={<ErrorIcon />} label={"unavailable"} color="error" />}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexGrow: "1", alignItems: "flex-end", justifyItems: "center" }}>
              <IconButton to={`/quote/${request.id}`} component={RouterLink}>
                <VisibilityOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
