import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Container, Grid, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import Product from "../../common/Product";
import PicUploadMultiple from "../../common/upload/PicUploadMultiple";
import { Link as RouterLink } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

export default function BakerPhotos(props) {
  const { user } = useContext(UserContext);

  const [cursor, setCursor] = useState("");
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(true);

  const getPhotos = (append) => {
    bkstApi(`/baker/${user.acctId || user.uid}/profile/photo?status=ACTIVE&cursor=${cursor}`).then((res) => {
      if (append) {
        setProducts((arr) => [...products, ...res.data.photos]);
      } else {
        setProducts(res.data.photos);
      }
      setCursor(res.data.cursor);
      setLoading(false);
    });
  };

  const addPhotos = (images) => {
    setLoading(true);
    bkstApi.post(`/my/photos`, { images }).then((res) => {
      setProducts((arr) => [...res.data.photos, ...products]);
      setLoading(false);
    });
  };

  useEffect(() => {
    getPhotos();
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="md">
        <Box>
          <Box align="center" my={7}>
            <Typography variant="h2">Photos</Typography>
            <Typography color="textSecondary" variant="body1">
              Add photos of your best work to showcase your talent
            </Typography>
            <Box my={4}>
              <PicUploadMultiple
                id="add-photos"
                title="Add Photos"
                color="secondary"
                variant="outlined"
                onComplete={(images) => addPhotos(images)}
                folder={`img/p`}
              />
            </Box>
          </Box>
          <Grid container spacing={3}>
            {products &&
              products.map((value, index) => {
                return (
                  <Grid item xs={12} sm={4} key={index} align="center">
                    <Box>
                      <Product img={value.img}>
                        <Box>
                          <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            disableElevation
                            component={RouterLink}
                            to={`/photo/${value.id}/edit`}
                          >
                            Edit
                          </Button>
                        </Box>
                      </Product>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
          {cursor && (
            <Box mt={5} align="center">
              <Button variant="contained" color="primary" size="large" onClick={() => getPhotos(true)}>
                Show More
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    );
  }
}
