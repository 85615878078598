import React from "react";
import currency from "currency.js";
import { Box, Grid, Typography } from "@mui/material";

export default function PaymentTotal(props) {
  const { payment } = props;

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Typography variant="overline" color="textSecondary">
            Subtotal
          </Typography>
        </Grid>
        <Grid item xs={3} align="right">
          <Typography variant="overline" color="textSecondary">
            {currency(payment.subtotal).format()}
          </Typography>
        </Grid>
      </Grid>
      {payment.delivery > 0 && (
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography variant="overline" color="textSecondary">
              Delivery fee
            </Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <Typography variant="overline" color="textSecondary">
              {currency(payment.delivery).format()}
            </Typography>
          </Grid>
        </Grid>
      )}
      {+payment.tip > 0 && (
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography variant="overline" color="textSecondary">
              Tip
            </Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <Typography variant="overline" color="textSecondary">
              {currency(payment.tip).format()}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography variant="overline" color="textSecondary">
              TOTAL (USD)
            </Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <Typography variant="overline" color="textSecondary">
              {currency(payment.total).format()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
