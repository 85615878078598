import React from "react";
import { Box, Typography } from "@mui/material";
import QuoteDeliveryFee from "./QuoteDeliveryFee";

export default function QuoteFooterInfo(props) {
  if (props.quote.quote) {
    return (
      <Box p={2} sx={{ backgroundColor: "#F7F7F7", color: "#1E4620" }}>
        <Typography variant="h4">${Math.ceil(+props.quote.quote)}</Typography>
        <QuoteDeliveryFee option={props.quote.option} fee={props.quote.delivery} />
      </Box>
    );
  } else {
    return <Box />;
  }
}
