import React, { useContext } from "react";
import { Avatar, Box, Button, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { STORAGE_BUCKET, IMG_PLACEHOLDER, bakeStreetSquareLogo } from "../../common/constants";
import { Link as RouterLink } from "react-router-dom";
import MarkOrderCompleted from "../../order/component/MarkOrderCompleted";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { getCustomerName, hasManagerAccess } from "../../common/util";
import OrderProgress from "../../order/component/OrderProgress";
import OrderPrint from "../screen/OrderPrint";
import { UserContext } from "../../context/UserContext";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

export default function BakerOrderSummary(props) {
  const { order, readOnly } = props;
  const { user } = useContext(UserContext);

  const printed = order?.activity?.print;
  const lastPrintTs = printed && printed[printed.length - 1].ts;

  let image = `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`;
  if (order.items && order.items.length > 0) {
    for (const item of order.items) {
      if (item.images && item.images.length > 0) {
        image = item.images[0];
        break;
      }
    }
  } else if (order.details && order.details.pics) {
    image = order.details.pics[0];
  }

  let isManager = hasManagerAccess(user.role);

  return (
    <Box mb={4}>
      <Paper elevation={0}>
        <Box px={2} pt={2} pb={1}>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={4} sx={{}}>
              <img src={image} style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }} />
            </Grid>
            <Grid item sm={9} xs={8}>
              <Typography variant="h6" gutterBottom>
                {getCustomerName(order.customer)}
              </Typography>
              <Box style={{ color: "#888" }}>
                <Box my={1}>
                  <Typography style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase" }}>
                    <EventOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                    {moment(order.fulfillment.date).format("ddd, MMM D")}
                    {order.fulfillment.time && ` (${order.fulfillment.time})`}
                  </Typography>
                </Box>
                <Box my={1}>
                  <Typography style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase" }}>
                    {order.fulfillment.type === "delivery" ? (
                      <LocalShippingOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                    ) : (
                      <LocalMallOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                    )}
                    {order.items?.length} item{order.items?.length === 1 ? "" : 's'} for {order.fulfillment.type}
                  </Typography>
                </Box>
                {props.showTs && (
                  <Typography style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase" }}>
                    <AccessTimeOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                    Ordered {moment(order.ts._seconds * 1000).fromNow()}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {!readOnly && (
          <Box px={2} pb={1}>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={4}>
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  {isManager && <OrderProgress progress={order.progress} orderId={order.id} />}
                  {isManager && printed && lastPrintTs && <OrderPrint order={order} />}
                </Box>
              </Grid>
              <Grid item sm={9} xs={8}>
                <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                  {isManager && order.autoQuote && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
                  {order.src === "mkt" && <img src={bakeStreetSquareLogo} style={{ width: "32px", height: "32px" }} />}
                  {order.baker?.id === "m7w2nldh2VlpJijvH2tC" && isManager && order.fulfillment?.type === "pickup" && (
                    <Avatar sx={{ width: 24, height: 24, fontSize: "10px" }}>{order.fulfillment.address.includes("Brooklyn") ? "BK" : "NY"}</Avatar>
                  )}
                  <Button
                    color={order.status === "pending_review" ? "warning" : "primary"}
                    disableElevation
                    variant="contained"
                    to={`/order/${order.id}`}
                    component={RouterLink}
                  >
                    {order.status === "pending_review" ? "CONFIRM ORDER" : "VIEW ORDER"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <MarkOrderCompleted order={order} />
      </Paper>
    </Box>
  );
}
