import React, { useState, useEffect } from "react";
import { Box, Button, Container, Grid, LinearProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import bkstApi from "../../api";
import { useNavigate } from "react-router";

export default function BakerEditPhotos(props) {
  const navigate = useNavigate();
  const { photoId } = useParams();

  const [baker, setBaker] = useState("");
  const [photo, setPhoto] = useState();
  const [loading, setLoading] = useState(true);

  const deletePhoto = () => {
    bkstApi.delete(`/my/photo/${photoId}`).then((res) => {
      navigate(`/photos`);
    });
  };

  useEffect(() => {
    bkstApi(`/my/photo/${photoId}`)
      .then((res) => {
        setBaker(res.data.baker);
        setPhoto(res.data.photo);
        setLoading(false);
      })
      .catch((error) => {
        navigate(`/photos`);
      });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box my={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} align="center">
              <img src={photo.img} style={{ width: "100%" }} />
              <Box py={1}>
                <Button variant="contained" onClick={deletePhoto}>
                  Delete
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}
