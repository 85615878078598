import React, { useState } from "react";
import { Box, Button, Dialog, DialogTitle, DialogContent, Grid, Typography, DialogActions, Divider, IconButton } from "@mui/material";
import moment from "moment";
import currency from "currency.js";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

export default function Transfers(props) {
  const [open, setOpen] = useState(false);

  return (
    <span>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <AccountBalanceOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Transfers</DialogTitle>
        <DialogContent>
          <Box>
            {props.data &&
              Object.entries(props.data)
                .sort()
                .map(([key, value], idx) => {
                  return (
                    <Box key={key} py={1}>
                      <Grid container spacing={2}>
                        <Grid item sm={9} xs={8}>
                          <Typography variant="body2">{value.description}</Typography>
                        </Grid>
                        <Grid item sm={3} xs={4} align="right">
                          <Typography variant="caption" color="textSecondary">
                            {currency(value.amount, { fromCents: true }).format()}
                          </Typography>
                        </Grid>
                      </Grid>
                      {value.metadata && value.metadata.paymentFee && (
                        <Grid container spacing={2}>
                          <Grid item sm={9} xs={8}>
                            <Typography variant="caption" color="textSecondary">
                              Payment Fee
                            </Typography>
                          </Grid>
                          <Grid item sm={3} xs={4} align="right">
                            <Typography variant="caption" color="textSecondary">
                              ({currency(value.metadata.paymentFee, { fromCents: true }).format()})
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {value.metadata && value.metadata.successFee && (
                        <Grid container spacing={2}>
                          <Grid item sm={9} xs={8}>
                            <Typography variant="caption" color="textSecondary">
                              Success Fee
                            </Typography>
                          </Grid>
                          <Grid item sm={3} xs={4} align="right">
                            <Typography variant="caption" color="textSecondary">
                              ({currency(value.metadata.successFee, { fromCents: true }).format()})
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {value.metadata && value.metadata.loanFee && (
                        <Grid container spacing={2}>
                          <Grid item sm={9} xs={8}>
                            <Typography variant="caption" color="textSecondary">
                              Loan Payment
                            </Typography>
                          </Grid>
                          <Grid item sm={3} xs={4} align="right">
                            <Typography variant="caption" color="textSecondary">
                              ({currency(value.metadata.loanFee, { fromCents: true }).format()})
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={2}>
                        <Grid item sm={9} xs={8}>
                          <Typography variant="body2" display="block">
                            Transfer initiated
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {moment(value.ts).format("MMM D, YYYY h:m a")}
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={4} align="right">
                          <Typography variant="caption">{currency(value.amount - value.applicationFee, { fromCents: true }).format()}</Typography>
                        </Grid>
                      </Grid>
                      {Object.keys(props.data).length > idx + 1 && (
                        <Grid item xs={12}>
                          <Box my={1}>
                            <Divider />
                          </Box>
                        </Grid>
                      )}
                    </Box>
                  );
                })}
            {!props.data && (
              <Box mt={2} mb={4}>
                <Typography variant="subtitle1" gutterBottom>
                  Nothing Yet!
                </Typography>
                <Typography color="textSecondary">Please note that it may take upto 3 hours for your payment to show up here.</Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={() => setOpen(false)}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
