import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { formatPhoneNumber, getCustomerFirstNameLastInitial, getCustomerName } from "../util";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ClickToCopy from "../ClickToCopy";
import ClickToCall from "../ClickToCall";

export default function CustomerContactInfoButton(props) {
  const { customer } = props;

  const name = getCustomerName(customer);
  const phone = customer?.phone;

  const [open, setOpen] = useState(false);

  return (
    <span>
      <Box sx={{ display: "inline-flex", alignItems: "center", cursor: "pointer", gap: "3px" }} onClick={() => setOpen(true)}>
        <IconButton size="small">
          <PermIdentityOutlinedIcon />
        </IconButton>
        {name && <Typography variant="body2">{getCustomerFirstNameLastInitial(name)}</Typography>}
      </Box>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="xs" fullWidth>
        <DialogTitle style={{ margin: "0", padding: "0" }}>
          <Box p={2} alignItems="center" sx={{ display: "flex", gap: "3px" }}>
            <IconButton size="small">
              <PermIdentityOutlinedIcon />
            </IconButton>
            {name && <Typography variant="h6">{getCustomerFirstNameLastInitial(name)}</Typography>}
          </Box>
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minHeight: "300px", backgroundColor: "#ECECEC" }}>
          <Box py={4}>
            <Box>
              <Typography variant="overline" color="textSecondary">
                NAME
              </Typography>
              <Typography variant="body1">{name}</Typography>
            </Box>
            {phone && (
              <Box my={2}>
                <Typography variant="overline" color="textSecondary">
                  PHONE
                </Typography>
                <Typography variant="body1">
                  {formatPhoneNumber(phone)} <ClickToCopy text={phone} />
                  <ClickToCall phone={phone} />
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </span>
  );
}
