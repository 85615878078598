import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function ClickToCopy(props) {
  const Icon = props.icon || ContentCopyIcon;

  return (
    <Tooltip title={props.text}>
      <IconButton
        size="small"
        target="_blank"
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard.writeText(props.text);
        }}
        disabled={!props.text}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
}
