import React, { useState, useContext } from "react";
import { Box, Container, Button, Grid, Typography, IconButton, Alert, AlertTitle } from "@mui/material";
import OrderDetails from "../component/OrderDetails";
import OrderFulfillment from "../component/OrderFulfillment";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link as RouterLink } from "react-router-dom";
import currency from "currency.js";
import CustomerDetailsButton from "../../common/component/CustomerDetailsButton";
import OrderChat from "../component/OrderChat";
import bkstApi from "../../api";

export default function PendingReviewOrder(props) {
  const { user } = useContext(UserContext);
  let location = useLocation();

  const navigate = useNavigate();

  const [order, setOrder] = useState(props.order);

  const finalize = () => {
    bkstApi.put(`/my/order/${order.id}/finalize`, {}).then((res) => {
      navigate(`/order/${order.id}`);
    });
  };

  return (
    <Container maxWidth="sm">
      <Box my={6}>
        <Box textAlign={"center"}>
          <Typography variant="h4" gutterBottom>
            Confirm Order Details
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Review the order details below and add anything that's missing. Be sure to complete this step to ensure this order stays on track.
          </Typography>
        </Box>
        <Box my={4}>
          <Alert severity="success">
            <AlertTitle>Payment Received</AlertTitle>
            {currency(order.payment.paid).format()} payment received.{" "}
            {order.payment.paid !== order.payment.total
              ? `Balance will be charged to card
              on file automatically.`
              : ""}
          </Alert>
        </Box>
        <Box my={4}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <CustomerDetailsButton src={order.src} customer={order.customer} />
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "flex-end" }}>
                <OrderChat order={order} />
                <IconButton size="small" component={RouterLink} to={`/order/${order.id}/edit`}>
                  <EditOutlinedIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <OrderDetails order={order} />
        </Box>
        <Box my={4}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                ORDER FULFILLMENT
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <IconButton size="small" component={RouterLink} to={`/order/${order.id}/fulfillment/edit`}>
                <EditOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          <Box my={4}>
            <Button fullWidth color="primary" variant="contained" startIcon={<CheckIcon />} onClick={finalize}>
              Confirm Order Details
            </Button>
            <Box my={1} align="center">
              <Typography color="textSecondary" variant="body2">
                Customer will receive an order confirmation with a link to their order page
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
