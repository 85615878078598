import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
  Radio,
  RadioGroup
} from "@mui/material";
import bkstApi from "../../api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";

export default function CancelQuote() {
  const { reqId } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [nogo, setNogo] = useState("");

  const submit = (e) => {
    e.preventDefault();

    bkstApi
      .put(`/my/request/${reqId}`, { status: "closed", nogo })
      .then((res) => {
        setOpen(false);
        navigate(0);
      })
      .catch((error) => {
        console.error("Failure");
      });
  };

  return (
    <span>
      <Button onClick={() => setOpen(true)} fullWidth color="secondary" variant="contained" disableElevation>
        Cancel Quote
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="xs">
        <form onSubmit={submit}>
          <DialogTitle>
            Cancel Quote{" "}
            <IconButton
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box pt={2}>
              <RadioGroup value={nogo} name="nogo" onChange={(e) => setNogo(e.target.value)}>
                <FormControlLabel value="duplicate" control={<Radio required={true} />} label="Duplicate" />
                <FormControlLabel value="rejected" control={<Radio required={true} />} label={`Rejected by customer`} />
                <FormControlLabel value="unavailable" control={<Radio required={true} />} label="Unable to fulfill" />
              </RadioGroup>
              <Box pt={1}>
                <Typography variant="caption" color="textSecondary">
                  Canceling a quote removes it from your dashboard. Active quotes are expired & can no longer be booked by customer.
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </span>
  );
}
