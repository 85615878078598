import React, { useEffect } from "react";
import bkstApi from "../../api";
import { LinearProgress } from "@mui/material";
import { getQueryParam } from "../util";

export default function Redirect() {
  const from = getQueryParam(window.location.search, "from");
  const to = getQueryParam(window.location.search, "to");
  const id = getQueryParam(window.location.search, "id");

  useEffect(() => {
    bkstApi(`/redirect?from=${from}&id=${id}&to=${to}`);
  }, []);

  return <LinearProgress />;
}
