import React from "react";
import { Box, Typography } from "@mui/material";

export default function BakerMktOrderPolicy(props) {
  return (
    <Box>
      <Typography variant="body1">Cancelation policy</Typography>
      <Typography variant="caption" color="textSecondary">
        A cancelation fee is applicable when you cancel a confirmed order. The cancelation fee is 20% of the order amount for orders due in 7 days or
        more. The cancelaton fee will increase to 30% of the order amount for orders due in 3-7 days. For orders due in 2 days or less, the "No Show"
        policy will apply. The cancelation fee will be deducted from your next payout. If you cancel orders often, we may suspend or deactivate your
        account.
      </Typography>
      <Box my={2}>
        <Typography variant="body1">Lateness policy</Typography>
        <Typography variant="caption" color="textSecondary">
          Your order is considered "Late", if you fail to deliver the order during the stated one-hour delivery window. This policy is also applicable
          to pickup orders that are not ready during the pickup time window. A late-fee in the amount of 15% of the order amount is applicable for
          orders upto 1 hour late. The late-fee will increase to 30% of the order amount for orders that are 1-2 hours late. The late-fee will be 50%
          of the order amount for orders that are more than 2 hours late. The late-fee will be deducted from your next payout. If the customer refuses
          to accept a late order, the "No Show" policy may apply.
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant="body1">No-Show policy</Typography>
        <Typography variant="caption" color="textSecondary">
          Your order is considered a "No-Show", if you fail to fulfill a confirmed order or if the customer refuses to accept a "Late" order. You will
          be charged a no-show fee, which is two times (200%) of the order amount. The no-show fee will be deducted from your next payout.
        </Typography>
      </Box>
    </Box>
  );
}
