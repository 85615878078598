import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import { useLocation } from "react-router-dom";
import { getQueryParam, isAllowedByFilter, sortByOrderDateTime } from "../../common/util";
import moment from "moment";
import OrderFilter from "../../order/component/OrderFilter";
import KitchenOrderSummary from "../../order/component/KitchenOrderSummary";

export default function KitchenOrdersByDate(props) {
  let location = useLocation();

  const [filter, setFilter] = useState("");

  const [searchCriteria, setSearchCriteria] = useState("");
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const filter = getQueryParam(window.location.search, "filter");
    let from = getQueryParam(window.location.search, "from") || moment().format("YYYY-MM-DD");
    let to = getQueryParam(window.location.search, "to") || moment().format("YYYY-MM-DD");

    if (filter === "today") {
      const today = moment().format("YYYY-MM-DD");
      from = today;
      to = today;
    } else if (filter === "tomorrow") {
      const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
      from = tomorrow;
      to = tomorrow;
    }

    console.log(filter, from, to);

    setLoading(true);
    bkstApi.get(`/my/order?filter=date&fromDt=${from}&toDt=${to}`).then((res) => {
      const arr = res.data.orders.sort(sortByOrderDateTime);
      setOrders(arr);
      setSearchCriteria(res.data.search);

      setFilteredOrders(arr);

      setLoading(false);
    });
  }, [location.key]);

  useEffect(() => {
    setFilteredOrders([]);
    setFilteredOrders(
      orders.filter((o) => {
        return isAllowedByFilter(o, filter);
      })
    );
  }, [filter]);

  if (loading) {
    return <LinearProgress />;
  } else {
    let notStarted = [];
    let inProgress = [];
    let completed = [];

    for (const o of filteredOrders) {
      if (o.progress) {
        if (+o.progress?.step === 4) {
          completed.push(o);
        } else if (+o.progress?.step > 0 && o.progress.step < 4) {
          inProgress.push(o);
        } else {
          notStarted.push(o);
        }
      } else {
        notStarted.push(o);
      }
    }

    return (
      <Box>
        <Container maxWidth="lg">
          <Box>
            <Box my={4} align="center">
              <Typography variant="h3" gutterBottom>
                {moment(searchCriteria.from).format("dddd, MMMM D")} Orders
              </Typography>
            </Box>
          </Box>
          <OrderFilter orders={orders} filter={filter} onChange={(o) => setFilter(o)} />
          <Box p={1} my={2} sx={{ backgroundColor: "#ECECEC" }}>
            <Typography variant="caption" color="textSecondary">
              {`Showing ${filteredOrders.length} ${filter} orders`}
            </Typography>
          </Box>
          <Box my={4}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <Typography variant="h5" gutterBottom>
                  Not Started ({notStarted.length})
                </Typography>
                <Box sx={{ color: "#e74c3c" }}>
                  <LinearProgress variant="determinate" value={100} color="inherit" />
                </Box>
                {notStarted.map((order, idx) => {
                  return (
                    <Box key={idx} my={2}>
                      <KitchenOrderSummary order={order} />
                    </Box>
                  );
                })}
              </Grid>
              <Grid item sm={4}>
                <Typography variant="h5" gutterBottom>
                  In-Progress ({inProgress.length})
                </Typography>
                <Box sx={{ color: "#fdcb6e" }}>
                  <LinearProgress variant="determinate" value={100} color="inherit" />
                </Box>
                {inProgress.map((order, idx) => {
                  return (
                    <Box key={idx} my={2}>
                      <KitchenOrderSummary order={order} />
                    </Box>
                  );
                })}
              </Grid>
              <Grid item sm={4}>
                <Typography variant="h5" gutterBottom>
                  Completed ({completed.length})
                </Typography>
                <Box sx={{ color: "#00b894" }}>
                  <LinearProgress variant="determinate" value={100} color="inherit" />
                </Box>
                {completed.map((order, idx) => {
                  return (
                    <Box key={idx} my={2}>
                      <KitchenOrderSummary order={order} />
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    );
  }
}
