import React, { useContext, useState, useEffect } from "react";
import { Backdrop, Box, Button, CircularProgress, Container, Grid, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import OrderChat from "../component/OrderChat";
import { UserContext } from "../../context/UserContext";
import OrderDetailsPopup from "../component/OrderDetailsPopup";
import QuillRTE from "../../common/component/QuillRTE";
import FeatureAccess from "../../baker/components/FeatureAccess";

export default function KitchenInstructionsEntry(props) {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const { orderId } = useParams();

  const [order, setOrder] = useState("");
  const [notes, setNotes] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/order/${orderId}`)
      .then((res) => {
        setOrder(res.data);
        setNotes(res.data?.kitchen?.notes || "");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [orderId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const kitchen = {
      notes
    };

    bkstApi.put(`/order/${orderId}/kitchen`, { notes }).then((res) => {
      navigate(`/order/${orderId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <FeatureAccess plan={["premium"]} />
        <form onSubmit={handleSubmit}>
          <Box py={6}>
            <Box mb={4} align="center">
              <Typography variant="h4" gutterBottom>
                Instructions
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Add order-specific instructions for your staff. This is for internal use only and will not be visible to the customer.
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Box sx={{ display: "flex", gap: "3px", alignItems: "center", justifyContent: "flex-end" }}>
                  {user && user.plan && order.src === "direct" && order.paymentType !== "offline" && <OrderChat order={order} />}
                  <OrderDetailsPopup order={order} />
                </Box>
              </Grid>
            </Grid>
            <Paper elevation={0}>
              <Box px={3} pt={4} pb={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <QuillRTE value={notes} onChange={(html) => setNotes(html)} />
                  </Grid>
                  <Grid item xs={12} align="right"></Grid>
                </Grid>
              </Box>
            </Paper>
            <Box my={2} sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
              <Button fullWidth variant="outlined" color="secondary" onClick={(e) => navigate(`/order/${orderId}`)} style={{ marginRight: "1rem" }}>
                Cancel
              </Button>
              <Button fullWidth type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Container>
    );
  }
}
