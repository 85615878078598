import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import OrderPrintVersion from "../../order/component/OrderPrintVersion";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import moment from "moment";
import bkstApi from "../../api";
import { getCustomerName } from "../../common/util";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import WarningIcon from "@mui/icons-material/Warning";

export default function OrderPrint(props) {
  const printRef = useRef();

  const [type, setType] = useState(props.order.type === "shop" ? "compact" : "expanded");

  const [order, setOrder] = useState(props.order);
  const [printActivity, setPrintActivity] = useState(order?.activity?.print || []);

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("preview");

  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${getCustomerName(order.customer)} ${moment(order.fulfillment.date).format("MMM DD, YYYY")}`
  });

  const handlePrint = () => {
    print();

    // show confirmation dialog after a delay
    setTimeout(() => {
      setStatus("sent");
    }, 2000);
  };

  const markPrintSuccessful = () => {
    bkstApi.put(`/my/order/${order.id}/activity/print`, { ts: Date.now() }).then((res) => {
      setPrintActivity([...printActivity, { ts: Date.now() }]);
      setOpen(false);
    });
  };

  useEffect(() => {
    if (!open) {
      setStatus("preview");
    }
  }, [open]);

  const lastPrintTs = (printActivity && printActivity.length > 0 && printActivity[printActivity.length - 1].ts) || "";

  const lastUpdTs = order.updTs || order.ts._seconds * 1000;

  let dialogWidth = "md";
  let title = "Print Order Details";
  let content = (
    <Box>
      {lastPrintTs && (
        <Alert severity="info">
          This order was last updated on {moment(lastUpdTs).format("llll")} ({moment(lastUpdTs).fromNow()}) and was last printed on{" "}
          {moment(lastPrintTs).format("llll")} ({moment(lastPrintTs).fromNow()})
        </Alert>
      )}
      {order.src === "direct" && order.items && (
        <Box mb={4}>
          <Box my={4}>
            <ToggleButtonGroup
              color="primary"
              sx={{ textTransform: "none" }}
              exclusive
              value={type}
              onChange={(e, v) => {
                if (v) {
                  setType(v);
                }
              }}
            >
              <ToggleButton value="compact" fullWidth>
                <Box textAlign={"center"} sx={{ textTransform: "none" }}>
                  <Typography variant="h5" gutterBottom>
                    Compact
                  </Typography>
                  <Typography variant="body1">Prints multiple items on one page. Ideal for standard orders.</Typography>
                </Box>
              </ToggleButton>
              <ToggleButton value="expanded" fullWidth>
                <Box textAlign={"center"} sx={{ textTransform: "none" }}>
                  <Typography variant="h5" gutterBottom>
                    Expanded
                  </Typography>
                  <Typography variant="body1">Prints each item on a separate page. Ideal for custom orders.</Typography>
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      )}
      <Box mt={4}></Box>
      <Box ref={printRef}>{order && <OrderPrintVersion type={type} order={order} />}</Box>
    </Box>
  );
  let cta = (
    <Button color="primary" onClick={handlePrint}>
      PRINT
    </Button>
  );
  let secondaryCta = (
    <Button variant="text" color="secondary" onClick={() => setOpen(false)}>
      CANCEL
    </Button>
  );

  if (status === "sent") {
    dialogWidth = "sm";
    title = "Confirmation";
    content = (
      <Box my={4} align="center">
        <Typography variant="h5" gutterBottom>
          Was the print successful?
        </Typography>
        <Typography variant="body1" color="textSecondary">
          We'll help you keep track of orders you've printed & alert you about upcoming orders you haven't yet printed
        </Typography>
      </Box>
    );
    cta = (
      <Button color="primary" onClick={markPrintSuccessful}>
        LOOKS GOOD!
      </Button>
    );
    secondaryCta = (
      <Button variant="text" color="secondary" onClick={() => setStatus("preview")}>
        RE-PRINT
      </Button>
    );
  }

  let badgeContent = 0;
  if (lastPrintTs) {
    //stale print
    if (order.updTs && order.updTs > lastPrintTs) {
      badgeContent = <WarningIcon sx={{ fontSize: "16px", color: "#e67e22" }} />;
    } else {
      // up-to-date print
      badgeContent = <CheckOutlinedIcon sx={{ fontSize: "16px", color: "#888" }} />;
    }
  }

  return (
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          badgeContent={badgeContent}
        >
          <PrintOutlinedIcon />
        </Badge>
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={dialogWidth}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {secondaryCta}
          {cta}
        </DialogActions>
      </Dialog>
    </span>
  );
}
