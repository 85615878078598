import React, { useState } from "react";
import { Button, Box, Collapse } from "@mui/material";

export default function ShowMore(props) {
  const { openCta, closeCta, fullWidth } = props;
  const [show, setShow] = useState(false);

  return (
    <Box mt={1}>
      {!show && (
        <Button size="small" variant="text" onClick={() => setShow(true)}>
          {openCta || "show more.."}
        </Button>
      )}
      {show && (
        <Box mt={2}>
          <Button disableElevation size="small" color="secondary" onClick={() => setShow(false)}>
            {closeCta || "show less.."}
          </Button>
        </Box>
      )}
      <Collapse in={show}>
        <Box px={2} py={2} sx={{ borderLeft: "5px solid #ECECEC" }}>
          {props.children}
        </Box>
      </Collapse>
    </Box>
  );
}
