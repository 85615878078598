import React, { useState, useEffect } from "react";
import { Box, Button, Container, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import PicUpload from "../../common/PicUpload";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import MultiSelectChips from "../../common/input/MultiSelectChips";
import { FROSTING } from "../../common/constants";

const SHAPES = ["single-tier", "multi-tier", "carved", "sheet"];
const DIETARY = ["eggless", "halal", "kosher", "nut free"];

export default function BakerEditProfile(props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [handle, setHandle] = useState("");

  const [bakerId, setBakerId] = useState("");
  const [logo, setLogo] = useState("");
  const [bizName, setBizName] = useState("");
  const [bizType, setBizType] = useState("");

  const [year, setYear] = useState("");
  const [shape, setShape] = useState("");
  const [frosting, setFrosting] = useState("");
  const [dietary, setDietary] = useState("");
  const [bio, setBio] = useState("");

  useEffect(() => {
    bkstApi.get("/my/account/profile").then((res) => {
      setHandle(res.data.baker.handle);

      setBakerId(res.data.baker.id);
      setLogo(res.data.privateData.logo);
      setBizName(res.data.baker.bizName);

      setBizType(res.data.publicData.bizType || "");
      setYear(res.data.publicData.year || "");
      setShape(res.data.publicData.shape || "");
      setFrosting(res.data.publicData.frosting || "");
      setDietary(res.data.publicData.dietary || "");
      setBio(res.data.publicData.bio || "");

      setLoading(false);
    });
  }, []);

  const submit = (e) => {
    e.preventDefault();

    const baker = { bizName };
    const publicData = { bizType, year, shape, frosting, dietary, bio };
    const privateData = { logo };

    bkstApi.put("/my/account", { baker, publicData, privateData }).then((res) => {
      navigate("/dashboard");
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="md">
        <Box mt={4}>
          <Paper elevation={3}>
            <Box px={3} pt={5} pb={3}>
              <form onSubmit={submit}>
                <Grid container spacing={4}>
                  <Grid item sm={3} xs={8}>
                    <PicUpload id="logo" required onChange={(url) => setLogo(url)} folder={`img/b/${bakerId}`} src={logo} width="100%" />
                  </Grid>
                  <Grid item sm={9} xs={12}>
                    <Grid container spacing={4}>
                      <Grid item sm={12} xs={12}>
                        <TextField
                          name="bizName"
                          value={bizName}
                          variant="outlined"
                          required
                          fullWidth
                          id="bizName"
                          label="Business Name"
                          inputProps={{ maxLength: 25 }}
                          onChange={(e) => setBizName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth required>
                          <InputLabel id="bizType-label">Business Type</InputLabel>
                          <Select
                            value={bizType}
                            labelId="bizType-label"
                            label="Business Type"
                            id="bizType"
                            name="bizType"
                            onChange={(e) => setBizType(e.target.value)}
                          >
                            <MenuItem value="bakery">Bakery</MenuItem>
                            <MenuItem value="ck">Commercial kitchen</MenuItem>
                            <MenuItem value="home">Home baker</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="year"
                          value={year}
                          variant="outlined"
                          fullWidth
                          id="year"
                          label="Year"
                          type="number"
                          inputProps={{ max: 2022 }}
                          onChange={(e) => setYear(e.target.value)}
                          helperText="When did you start this business?"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="bio"
                          value={bio}
                          label="Bio"
                          name="bio"
                          variant="outlined"
                          multiline
                          rows={4}
                          fullWidth
                          onChange={(e) => setBio(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="h6">What type of cakes do you make?</Typography>
                        <Paper elevation={0} style={{ border: "1px solid #CCC" }}>
                          <Box px={2} pt={2}>
                            <MultiSelectChips value={shape} setValue={setShape} options={SHAPES} label="Shape" />
                            <MultiSelectChips value={frosting} setValue={setFrosting} options={FROSTING} label="Frosting" />
                            <MultiSelectChips value={dietary} setValue={setDietary} options={DIETARY} label="Dietary options" />
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button size="large" onClick={() => navigate("/dashboard")}>
                      Cancel
                    </Button>
                    <Button variant="contained" color="primary" size="large" type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}
