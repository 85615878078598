import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ExpandableImage from "../../common/ExpandableImage";
import IconText from "../../common/IconText";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { createSnippets } from "../../common/util";
import HTMLViewer from "../../common/component/HTMLViewer";

export default function QuoteDescriptionV2(props) {
  const status = props.status;

  if (status === "unavailable" || status === "block") {
    return (
      <Box style={{ color: "#777" }}>
        <Typography>
          {props.quote.resTs
            ? `You responded that you're unavailable and did not send a quote. ${props.quote.note}`
            : "You did not respond to this quote request"}
        </Typography>
      </Box>
    );
  } else {
    const pics = props.quote.pics || [props.quote.pic1, props.quote.pic2, props.quote.pic3].filter((i) => i);
    const snippets = createSnippets(props.quote.productType, props.quote.shape, props.quote.size, props.quote.servings);

    return (
      <Box my={2} style={{ color: "#777" }}>
        <Box my={1}>
          <Grid container>
            {snippets.map((e, idx) => (
              <Grid item sm={12} xs={12} key={idx}>
                <IconText icon={CheckOutlinedIcon} text={e} variant="body2" />
              </Grid>
            ))}
            {props.quote.frosting && (
              <Grid item sm={12} xs={12}>
                <IconText icon={CheckOutlinedIcon} text={`${props.quote.frosting} frosting`} variant="body2" />
              </Grid>
            )}
            {props.quote.tags &&
              props.quote.tags.map((e, idx) => (
                <Grid item sm={12} xs={12} key={idx}>
                  <IconText icon={CheckOutlinedIcon} text={e} variant="body2" />
                </Grid>
              ))}
          </Grid>
        </Box>
        {props.quote.note && (
          <Box py={1}>
            <HTMLViewer content={props.quote.note} style={{ fontSize: ".85rem" }} />
          </Box>
        )}
        {pics &&
          pics.map((p, idx) => (
            <span key={idx} style={{ marginRight: "15px" }}>
              <ExpandableImage src={p} />
            </span>
          ))}
      </Box>
    );
  }
}
