import React, { useContext } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import BDatePicker from "../../input/BDatePicker";
import Price from "../Price";
import { UserContext } from "../../../context/UserContext";
import PlacesAutocomplete from "../../PlacesAutocomplete";

export default function QuoteFulfillmentEntry(props) {
  const { user } = useContext(UserContext);
  const { fulfillment } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
          <BDatePicker
            label="Date"
            value={fulfillment?.date || ""}
            onChange={(date) => props.onChange({ ...fulfillment, date })}
            disablePast
            required
            autoOk
          />
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl variant="outlined" required fullWidth>
          <InputLabel id="delivery-option-label">Delivery</InputLabel>
          <Select
            value={fulfillment?.option?.delivery?.type || ""}
            labelId="option-label"
            label="Delivery"
            id="option"
            name="option"
            onChange={(e) => {
              let o = { ...fulfillment };
              o.option.delivery.type = e.target.value;
              props.onChange(o);
            }}
          >
            <MenuItem value="no">Not Available</MenuItem>
            <MenuItem value="in-house">In-house Delivery</MenuItem>
            {user.config?.fulfillment?.delivery?.["third-party"] === "metrobi" && <MenuItem value="metrobi">Metrobi Delivery</MenuItem>}
          </Select>
        </FormControl>
      </Grid>
      {fulfillment.option.delivery.type === "in-house" && (
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <PlacesAutocomplete
              value={fulfillment?.option?.delivery?.location || ""}
              onChange={(loc) => {
                let o = { ...fulfillment };
                o.option.delivery.location = loc;
                props.onChange(o);
              }}
              label="Delivery Zip Code"
              required
            />
          </FormControl>
        </Grid>
      )}
      {fulfillment.option.delivery.type === "in-house" && (
        <Grid item xs={12} sm={6}>
          <Price
            value={{ amount: fulfillment?.option?.delivery?.price || "", includeFees: fulfillment?.option?.delivery?.includeFees }}
            onChange={(value) => {
              let o = { ...fulfillment };
              o.option.delivery.price = value.amount;
              o.option.delivery.includeFees = value.includeFees;
              o.option.delivery.total = value.total;
              
              props.onChange(o);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
