import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import FlavorVariantEntry from "../variant/FlavorVariantEntry";
import SizeVariantEntry from "./SizeVariantEntry";
import FrostingAutocomplete from "../FrostingAutocomplete";

export default function ItemVariantEntry(props) {
  const { productType, value } = props;

  const [size, setSize] = useState(value.size || "");
  const [frosting, setFrosting] = useState(value.frosting?.name || "");
  const [flavor, setFlavor] = useState(value.flavor || "");

  useEffect(() => {
    let payload = {};
    if (size) {
      payload.size = size;
    }
    if (frosting) {
      payload.frosting = { name: frosting };
    }
    if (flavor) {
      payload.flavor = [flavor];
    }
    props.onChange(payload);
  }, [size, frosting, flavor]);

  const showFrostingOptions = productType === "Cake" || productType === "Cookies" || productType === "Cupcakes";

  return (
    <Box>
      {productType && (
        <Box my={3}>
          <SizeVariantEntry productType={productType} value={size} onChange={(o) => setSize(o)} />
        </Box>
      )}
      {showFrostingOptions && (
        <Box my={3}>
          <FrostingAutocomplete productType={productType} value={frosting} label="Frosting" onChange={(v) => setFrosting(v)} />
        </Box>
      )}
      <Box my={3}>
        <FlavorVariantEntry productType={productType} value={flavor} onChange={(o) => setFlavor(o)} />
      </Box>
    </Box>
  );
}
