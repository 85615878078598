import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import { auth } from "./firebase";
import { Alert } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [processing, setProcessing] = useState(false);

  const login = (e) => {
    e.preventDefault();
    setProcessing(true);

    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      setProcessing(false);
      setMsg("Login failed");
    });
  };

  const reset = () => {
    if (email) {
      var actionCodeSettings = {
        url: "https://bakestreet.co//login",
        handleCodeInApp: false
      };

      auth
        .sendPasswordResetEmail(email, actionCodeSettings)
        .then(() => {
          setEmail("");
          setPassword("");
          setMsg("Password reset email sent!");
        })
        .catch((error) => {
          setMsg("Invalid email");
        });
    } else {
      setMsg("Please enter your email");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={6}>
        <Box mb={3} align="center">
          <Typography variant="h2">Login</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            to your bakestreet account
          </Typography>
        </Box>
        <Paper>
          <form onSubmit={login} autoComplete="on">
            <Box p={6}>
              {msg && (
                <Box mb={4}>
                  <Alert severity="error">{msg}</Alert>
                </Box>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    value={email}
                    name="username"
                    variant="outlined"
                    fullWidth
                    id="email"
                    label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={password}
                    name="password"
                    variant="outlined"
                    fullWidth
                    id="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
                    LOG IN
                  </Button>
                  <Box mt={2} align="center">
                    <Link href="#" onClick={reset} underline="hover">
                      forgot password?
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Paper>
      </Box>
      <Backdrop open={processing} style={{ zIndex: "1000", color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
export default Login;
