import React, { useContext, useState, useEffect, useRef } from "react";
import { Box, Container, Chip, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import moment from "moment";
import bkstApi from "../../api";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import currency from "currency.js";
import { UserContext } from "../../context/UserContext";
import BakerOrderSummary from "../components/BakerOrderSummary";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import { sortByOrderDateTime } from "../../common/util";
import FeatureAccess from "../components/FeatureAccess";

export default function MyCalendar(props) {
  const { user } = useContext(UserContext);
  const ref = useRef();

  const [value, setValue] = useState(new Date());

  const [totalSales, setTotalSales] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);

  const [ordersByDate, setOrdersByDate] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMonthlyData(new Date());
  }, []);

  const getMonthlyData = (date) => {
    setLoading(true);
    const fromDate = moment(date).startOf("month");
    const toDate = moment(date).endOf("month");

    bkstApi(`/my/order?filter=date&fromDt=${fromDate.format("YYYY-MM-DD")}&toDt=${toDate.format("YYYY-MM-DD")}`).then((res) => {
      const sortedOrders = res.data.orders.sort(sortByOrderDateTime);

      let tmp = {};
      for (const o of sortedOrders) {
        let arr = tmp[o.fulfillment.date] || [];
        arr.push(o);
        tmp[o.fulfillment.date] = arr;
      }
      setOrdersByDate(tmp);

      let [count, sales] = [0, 0];
      for (const o of sortedOrders) {
        count++;
        sales += +o.payment.total;
      }
      setTotalOrders(count);
      setTotalSales(sales);

      setValue(date);
      setLoading(false);
    });
  };

  const handleMonthChange = (date) => {
    getMonthlyData(date);
  };

  const getDateTotals = (dt) => {
    let count = 0;
    let sales = 0;
    let orderData = ordersByDate && ordersByDate[moment(dt).format("YYYY-MM-DD")];
    if (orderData) {
      count = (orderData && orderData.length) || 0;

      sales = Object.values(orderData).reduce((acc, obj) => {
        return +acc + +obj.payment.total;
      }, 0);
    }

    return { count, sales };
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    const isOwner = user.role === "baker" || user.role === "owner";
    const isManager = user.role === "rep" || user.role === "manager";

    const ordersForSelectedDate = (ordersByDate && value && ordersByDate[moment(value).format("YYYY-MM-DD")]) || [];

    return (
      <Container maxWidth="md" disableGutters sx={{ paddingBottom: "40rem" }}>
        <FeatureAccess plan={["starter", "professional", "premium"]} />
        <Box my={6} align="center">
          <Typography variant="h2" gutterBottom>
            Calendar
          </Typography>
          <Typography variant="body1" gutterBottom color="textSecondary">
            A bird's eye view of your monthly data
          </Typography>
        </Box>
        {isOwner && (
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item xs={6} align="center">
                <Box p={2} style={{ backgroundColor: "#ECECEC" }}>
                  <Typography variant="h6">{totalOrders}</Typography>
                  <Typography variant="overline" color="textSecondary">
                    ORDERS
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} align="center">
                <Box p={2} style={{ backgroundColor: "#ECECEC" }}>
                  <Typography variant="h6">{currency(totalSales, { precision: "0" }).format()}</Typography>
                  <Typography variant="overline" color="textSecondary">
                    SALES
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            views={["month", "day"]}
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              if (ordersByDate && value) {
                ref.current?.scrollIntoView();
              }
            }}
            onMonthChange={handleMonthChange}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              "& .MuiDateCalendar-root": {
                width: "100%",
                maxWidth: "100%",
                height: "auto",
                maxHeight: "inherit",
                backgroundColor: "#F7F7F7"
              },
              "& .MuiDayCalendar-monthContainer": { border: "1px solid #EEE" },
              "& .MuiDayCalendar-weekContainer": { margin: 0 },
              //"& .MuiPickersCalendarHeader-root": { backgroundColor: "#FFFFFF" },
              //"& .MuiDayCalendar-header": { borderTop: "1px solid #EEE", borderLeft: "1px solid #EEE", borderRight: "1px solid #EEE" },
              "& .MuiDayCalendar-weekDayLabel": { width: "100%", border: "1px solid #EEE", margin: "0" },
              "& .MuiDayCalendar-slideTransition": { minHeight: "500px", height: "auto", maxWidth: "100%", overflow: "scroll" },
              "& .MuiPickersDay-root": {
                width: "100%",
                height: "100%",
                border: "none",
                margin: "1px"
              },
              "& .MuiPickersDay-root:not(.Mui-selected)": {
                border: "none"
              }
            }}
            slots={{
              day: (props) => {
                const { day, selectedDay, ...other } = props;

                const summary = getDateTotals(day);

                return (
                  <PickersDay day={day} {...other}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                        minHeight: "75px",
                        overflow: "hidden",
                        color: "#000",
                        backgroundColor: "#fff",
                        paddingBottom: "3px"
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginBottom: "5px" }}>
                        <Typography variant="overline">{props.day.getDate()}</Typography>
                      </Box>
                      {Boolean(summary.count) && (
                        <Box>
                          <Chip
                            icon={<LocalMallOutlinedIcon style={{ fontSize: "12px" }} />}
                            label={summary.count}
                            size="small"
                            sx={{ fontSize: "10px", height: "20px", margin: "2px" }}
                          />
                          {isOwner && (
                            <Chip
                              icon={<AttachMoneyOutlinedIcon style={{ fontSize: "12px" }} />}
                              label={Math.round(summary.sales)}
                              size="small"
                              sx={{ fontSize: "10px", height: "20px", margin: "2px", "& .MuiChip-label": { paddingLeft: "2px" } }}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  </PickersDay>
                );
              }
            }}
          />
        </LocalizationProvider>
        <Box py={6} ref={ref}>
          {ordersForSelectedDate && (
            <Box>
              <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                  {moment(value).format("ddd, MMM D")} Orders
                </Typography>
              </Box>
              {ordersForSelectedDate.map((order) => {
                return <BakerOrderSummary key={order.id} order={order} />;
              })}
              {ordersForSelectedDate.length === 0 && (
                <Box px={2} py={4} align="center" sx={{ border: "1px solid #DDD" }}>
                  <DoNotDisturbAltOutlinedIcon sx={{ fontSize: "5rem", color: "#AAA" }} />
                  <Typography variant="body1" color="textSecondary">
                    No orders found!
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Container>
    );
  }
}
