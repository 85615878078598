import React, { useState, useContext } from "react";
import Login from "./Login";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes, Navigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Box, Hidden, LinearProgress, Paper } from "@mui/material";
import Header from "./Header";
import DynamicPage from "./common/DynamicPage";
import OrderFulfillmentEntry from "./order/screen/OrderFulfillmentEntry";
import Order from "./order/screen/Order";
import BakerOnboarding from "./baker/screen/BakerOnboarding";
import BakerEditProfile from "./baker/screen/BakerEditProfile";
import BakerEditFlavors from "./baker/screen/BakerEditFlavors";
import BakerEditPhoto from "./baker/screen/BakerEditPhoto";
import Reroute from "./common/Redirect";
import MyQuote from "./baker/screen/MyQuote";
import GetPaid from "./order/screen/GetPaid";
import OrderDetailsEntry from "./order/component/OrderDetailsEntry";
import ResponsiveDrawer from "./common/component/ResponsiveDrawer";
import { THEME } from "./common/constants";
import BakerLoan from "./order/screen/BakerLoan";
import MyPlan from "./baker/screen/MyPlan";
import CreateAddOn from "./invoice/screen/CreateAddOn";
import MyNotifications from "./baker/screen/MyNotifications";
import BakerPhotos from "./baker/screen/BakerPhotos";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { Link as RouterLink } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import Dashboard from "./home/screen/Dashboard";
import OrderDashboard from "./baker/screen/OrderDashboard";
import MyReviews from "./baker/screen/MyReviews";
import Quotes from "./baker/screen/Quotes";
import SalesReport from "./report/screen/SalesReport";
import PayoutsReport from "./report/screen/PayoutsReport";
import PayoutDetails from "./report/screen/PayoutDetails";
import Redirect from "./common/component/Redirect";
import MyCalendar from "./baker/screen/MyCalendar";
import KitchenInstructionsEntry from "./order/screen/KitchenInstructionsEntry";
import OrderCancel from "./order/screen/OrderCancel";
import OnlineShop from "./product/screen/OnlineShop";
import ProductEntry from "./product/screen/ProductEntry";
import CustomerDetail from "./customer/screen/CustomerDetail";
import FulfillmentPlanner from "./planner/screen/FulfillmentPlanner";
import CreateOrder from "./order/screen/CreateOrder";
import KitchenDashboard from "./baker/screen/KitchenDashboard";
import TipsReport from "./report/screen/TipsReport";
import RefundsReport from "./report/screen/RefundsReport";
import { APIProvider } from "@vis.gl/react-google-maps";
import EditQuote from "./baker/screen/EditQuote";
import Invoice from "./order/screen/Invoice";

const theme = createTheme(THEME);

export default function App(props) {
  const { loading, user } = useContext(UserContext);

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  if (loading) {
    return <LinearProgress />;
  } else if (user) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <APIProvider apiKey={"AIzaSyB-fYVyiXH2_vKis8ySShV-5mY33bQLXXI"}>
          <div style={{ display: "flex" }}>
            {user.profile.status === "onboard" && <ResponsiveDrawer open={mobileDrawerOpen} toggle={() => setMobileDrawerOpen(!mobileDrawerOpen)} />}
            <main style={{ flexGrow: "1", overflowX: "hidden" }}>
              {user.profile.status === "onboard" && <Header toggleMobileDrawer={() => setMobileDrawerOpen(!mobileDrawerOpen)} />}
              <Routes>
                <Route path="/order/:orderId/fulfillment/edit" element={<OrderFulfillmentEntry />} />
                <Route path="/order/:orderId/kitchen/edit" element={<KitchenInstructionsEntry />} />
                <Route path="/onboarding" element={<BakerOnboarding />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/create" element={<CreateOrder />} />
                <Route path="/order/create" element={<CreateOrder />} />
                <Route path="/order/:orderId/create-invoice" element={<CreateAddOn />} />
                <Route path="/order/:orderId/get-paid" element={<GetPaid />} />
                <Route path="/order/:orderId/mark-completed" element={<GetPaid />} />
                <Route path="/order/:orderId/edit" element={<OrderDetailsEntry />} />
                <Route path="/order/:orderId/cancel" element={<OrderCancel key={Date.now()} />} />
                <Route path="/order/:orderId" element={<Order key={Date.now()} />} />
                <Route path="/my/order/:orderId" element={<Order key={Date.now()} />} />
                <Route path="/order" element={<OrderDashboard key={Date.now()} />} />
                <Route path="/quote/:reqId/edit" element={<EditQuote />} key={Date.now()} />
                <Route path="/quote/:reqId" element={<MyQuote />} key={Date.now()} />
                <Route path="/my/quote/:reqId" element={<MyQuote />} key={Date.now()} />
                <Route path="/quote" element={<Quotes {...props} key={Date.now()} />} />
                <Route path="/loan" element={<BakerLoan />} />
                <Route path="/calendar" element={user.role === "kitchen" || user.role === "kitchen leader" ? <KitchenDashboard /> : <MyCalendar />} />
                <Route path="/notification" element={<MyNotifications />} />
                <Route path="/profile" element={<BakerEditProfile />} />
                <Route path="/shop" element={<OnlineShop />} />
                <Route path="/shop/add" element={<ProductEntry />} />
                <Route path="/shop/:productId" element={<ProductEntry />} />
                <Route path="/customer/:customerId" element={<CustomerDetail />} />
                <Route path="/photos" element={<BakerPhotos />} />
                <Route path="/flavors" element={<BakerEditFlavors />} key={Date.now()} />
                <Route path="/reviews" element={<MyReviews />} />
                <Route path="/photo/:photoId/edit" element={<BakerEditPhoto />} />
                <Route path="/report/sales" element={<SalesReport key={Date.now()} />} />
                <Route path="/report/tips" element={<TipsReport key={Date.now()} />} />
                <Route path="/report/refunds" element={<RefundsReport key={Date.now()} />} />
                <Route path="/report/payouts" element={<PayoutsReport key={Date.now()} />} />
                <Route path="/payout/:payoutId" element={<PayoutDetails key={Date.now()} />} />
                <Route path="/plan" element={<MyPlan />} />
                <Route path="/planning/fulfillment" element={<FulfillmentPlanner key={Date.now()} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/privacy-policy" element={<DynamicPage {...props} handle="privacy-policy" />} />
                <Route path="/terms-of-service" element={<DynamicPage {...props} handle="terms-of-service" />} />
                <Route path="/to/:cat/:id" element={<Reroute />} />
                <Route path="/redirect" element={<Redirect />} />
                <Route path="*" element={<Navigate replace to="/dashboard" />} />
              </Routes>
              <Box my={10}></Box>
              <Hidden smUp>
                <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
                  <BottomNavigation showLabels>
                    <BottomNavigationAction label="Quotes" icon={<LocalAtmOutlinedIcon />} to={`/quote`} component={RouterLink} />
                    <BottomNavigationAction label="Orders" icon={<LocalMallOutlinedIcon />} to={`/order`} component={RouterLink} />
                  </BottomNavigation>
                </Paper>
              </Hidden>
            </main>
          </div>
        </APIProvider>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      </ThemeProvider>
    );
  }
}
