import React, { useState } from "react";
import { Box, Button, Paper, Slide, Typography } from "@mui/material";
import bkstApi from "../../api";

export default function BakerOnboardingPayment(props) {
  const [loading, setLoading] = useState(false);

  const gotoStripe = () => {
    setLoading(true);
    bkstApi.get("/my/account/stripe-connect").then((res) => {
      window.location.href = res.data.url;
    });
  };

  return (
    <Slide direction="up" in={props.step === 2} mountOnEnter unmountOnExit>
      <Paper elevation={3}>
        <Box px={3} py={3}>
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              Secure Payouts
            </Typography>
            <Typography variant="body1" color="textSecondary">
              A payout is a transfer of funds from BakeStreet to your bank account. Add your bank account to get automated payouts on a rolling basis.
            </Typography>
          </Box>
          <Box textAlign={"center"}>
            <Box mb={1}>
              <Button variant="contained" color="primary" size="large" onClick={gotoStripe} disabled={loading}>
                Add Bank Account
              </Button>
            </Box>
            <Typography variant="caption" color="textSecondary">
              You'll be redirected to Stripe to complete the onboarding process
            </Typography>
          </Box>
          <Box mt={5} align="center">
            <img
              width="125"
              src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/img%2Fh%2Fstripe.svg?alt=media&token=86fe6d00-eae6-45e2-924b-d9e0e5da25a9"
            />
            <Typography display="block" variant="caption" color="textSecondary">
              BakeStreet works with Stripe, a market leader in online payments to securely process your payments. BakeStreet can't see or store your
              bank details.{" "}
              <a target="_blank" href="https://stripe.com/">
                Learn More
              </a>
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Slide>
  );
}
