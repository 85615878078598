import React, { useState, useEffect } from "react";
import { Box, Grid, TextField } from "@mui/material";

export default function ItemCustomizationEntry(props) {
  const { type, value } = props;
  const [inscription, setInscription] = useState(value.inscription?.text || "");

  useEffect(() => {
    let payload = {};
    if (inscription) {
      payload.inscription = { text: inscription };
    }
    props.onChange(payload);
  }, [inscription]);

  return (
    <Box>
      <Grid container spacing={3}>
          <Grid item sm={12} xs={12}>
            <TextField
              id="inscription"
              value={inscription}
              label="Inscription"
              name="inscription"
              variant="outlined"
              fullWidth
              onChange={(e) => setInscription(e.target.value)}
            />
          </Grid>
      </Grid>
    </Box>
  );
}
