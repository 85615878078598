import React, { useState, useRef } from "react";
import { Box, Dialog, DialogContent, IconButton, Link, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { STORAGE_BUCKET, IMG_PLACEHOLDER } from "../constants";
import { bakeStreetLogo } from "../../common/constants";
import { useReactToPrint } from "react-to-print";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";

const navBtnStyle = {
  position: "absolute",
  zIndex: 2,
  top: "50%",
  transform: "translateY(-50%)",
  color: "#000",
  backgroundColor: "#fff",
  borderColor: "#ebebeb",
  opacity: ".7",
  padding: "0"
};

const navBtnPrevStyle = Object.assign({ left: "8px" }, navBtnStyle);
const navBtnNextStyle = Object.assign({ right: "8px" }, navBtnStyle);

export default function ImageCarousel(props) {
  const printRef = useRef();

  const [open, setOpen] = useState(false);

  let images = props.images.filter((item) => item) || [];
  if (!images || images.length === 0) {
    images.push(`${STORAGE_BUCKET}${IMG_PLACEHOLDER}`);
  }

  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: window.document.title
  });

  let header = (
    <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
      <IconButton color="inherit" onClick={() => setOpen(false)}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box sx={{ flexGrow: "1", textAlign: "center" }}>
        <Box>
          <Typography variant="h6">Image Gallery</Typography>
          <Typography sx={{ fontSize: ".75rem" }}>
            {images.length} IMAGE{images.length > 1 ? "S" : ""}
          </Typography>
        </Box>
      </Box>
      <IconButton color="inherit" onClick={print}>
        <PrintOutlinedIcon />
      </IconButton>
      <IconButton color="inherit" onClick={() => setOpen(false)}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

  return (
    <Box class="carousel-container" style={{ cursor: "pointer", borderRadius: "3px" }} align="center">
      <Link onClick={() => setOpen(true)} underline="hover">
        <Box sx={{ backgroundColor: "#ECECEC" }}>
          <Carousel showArrows={false} showIndicators={false} showStatus={false} showThumbs={false}>
            {images.map((pic, idx) => {
              return <img alt="" src={pic} key={idx} style={{ width: "100%", aspectRatio: "1", objectFit: "cover", borderRadius: "4px" }} />;
            })}
          </Carousel>
        </Box>
        {props.images.length > 1 && (
          <Typography color="textSecondary" sx={{ fontSize: ".7rem" }}>
            {props.images.length} IMAGES
          </Typography>
        )}
      </Link>
      <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
        <DialogContent style={{ backgroundColor: "#000", color: "#fff", alignItems: "center", paddingTop: "3px" }}>
          {header}
          <Box mt={2} align="center">
            <Carousel
              interval={5000}
              transitionTime={1000}
              showArrows={true}
              showIndicators={true}
              showStatus={true}
              showThumbs={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnPrevStyle}>
                    <ChevronLeftIcon />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnNextStyle}>
                    <ChevronRightIcon />
                  </IconButton>
                )
              }
            >
              {images.map((pic, idx) => {
                return (
                  <Box key={idx}>
                    <img alt="" src={pic} style={{ height: "80vh", objectFit: "contain" }} />
                    <Box sx={{ display: "block", paddingTop: ".5rem", paddingBottom: "2.5rem" }}>
                      <Link underline="always" color="inherit" component="a" href={pic} download target="_blank">
                        download
                      </Link>
                    </Box>
                  </Box>
                );
              })}
            </Carousel>
          </Box>
          <div style={{ display: "none" }}>
            <Box ref={printRef}>
              {props.images.map((pic, idx) => {
                return (
                  <Box>
                    {idx > 0 && <div class="pagebreak"> </div>}
                    <div style={{ height: "9in" }}>
                      <Box style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        <Box align="center" my={4}>
                          <img alt="logo" src={bakeStreetLogo} style={{ maxHeight: "20pt" }}></img>
                        </Box>
                        <img alt="" src={pic} key={idx} style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain" }} />
                      </Box>
                    </div>
                  </Box>
                );
              })}
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
