import React, { useState, useEffect } from "react";
import { Box, Container, Grid, LinearProgress } from "@mui/material";
import bkstApi from "../../api";
import FlavorInputList from "../../common/input/FlavorInputList";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

export default function BakerEditFlavors(props) {
  const navigate = useNavigate();
  let location = useLocation();

  const [loading, setLoading] = useState(true);

  const [baker, setBaker] = useState("");
  const [flavors, setFlavors] = useState("");
  const [fillings, setFillings] = useState("");

  useEffect(() => {
    setLoading(true);
    bkstApi.get("/my/account/profile").then((res) => {
      setBaker(res.data.baker);
      setFlavors(res.data.publicData.flavors);
      setFillings(res.data.publicData.fillings);

      setLoading(false);
    });
  }, [location.key]);

  const submit = (payload) => {
    bkstApi.put("/my/account", { publicData: payload }).then((res) => {
      navigate("/flavors");
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="md">
        <Box my={7}>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <FlavorInputList type="Flavor" value={flavors} onChange={(data) => submit({ flavors: data })} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FlavorInputList type="Filling" value={fillings} onChange={(data) => submit({ fillings: data })} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}
