import React, { useContext, useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Button,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Grid,
  TextField,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  AlertTitle
} from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import currency from "currency.js";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../../context/UserContext";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";

export default function OrderRefund(props) {
  const { user, setToast } = useContext(UserContext);

  const { order } = props;

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [refundType, setRefundType] = useState("custom");
  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");

  const [error, setError] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    setAmount(0);
    setError("");

    if (order) {
      if (refundType === "full") {
        setAmount(currency(order.payment.paid));
      } else if (refundType === "custom") {
        setAmount("");
      }
    }
  }, [order, refundType]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setStatus("processing");
    setError("");

    let payload = { amount, type: refundType, reason, note };

    bkstApi
      .put(`/my/order/${order.id}/refund`, payload)
      .then((res) => {
        setToast({ severity: "success", msg: "Success - refund has been issued" });
        navigate(`/order/${order.id}`);
      })
      .catch((error) => {
        setStatus("");
        setError(error.response?.data?.msg);
      });
  };

  const isFullRefundAvailable = moment().isSameOrAfter(moment(order.fulfillment.date), "day");

  return (
    <span>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <CurrencyExchangeIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            Refund Payment
            <IconButton
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Box mt={1} mb={4}>
                <RadioGroup row value={refundType} name="refundType" onChange={(e) => setRefundType(e.target.value)}>
                  <FormControlLabel value="custom" control={<Radio required={true} />} label="Partial Refund" />
                  <FormControlLabel value="full" control={<Radio required={true} />} label={`Full Refund`} />
                </RadioGroup>
              </Box>
              {refundType === "full" && !isFullRefundAvailable ? (
                <Box mb={2}>
                  <Alert severity="error">
                    <AlertTitle>Can't issue full refund for an open order</AlertTitle>
                    This order needs to be canceled in order to issue a full refund. Click the button below to go to the Cancel Order page.
                  </Alert>
                </Box>
              ) : (
                <Grid container spacing={3}>
                  <Grid item sm={4} xs={6}>
                    <TextField
                      error={+amount > +order.payment.paid}
                      id="amount"
                      type="number"
                      value={amount}
                      label="Amount"
                      name="amount"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setAmount(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyOutlinedIcon />
                          </InputAdornment>
                        )
                      }}
                      disabled={refundType !== "custom"}
                      helperText={+amount > +order.payment.paid ? `Can't exceed ${currency(order.payment.paid).format()}` : ""}
                    />
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <FormControl variant="outlined" required fullWidth>
                      <InputLabel id="reason-label">Refund Reason</InputLabel>
                      <Select
                        value={reason}
                        labelId="reason-label"
                        label="Refund Reason"
                        id="reason"
                        name="reason"
                        onChange={(e) => setReason(e.target.value)}
                      >
                        <MenuItem value="mistake">Mistake - order incorrectly made</MenuItem>
                        <MenuItem value="change">Change - items changed or removed</MenuItem>
                        <MenuItem value="delay">Delay - order not fulfilled on time</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth required={reason === "other"}>
                      <TextField
                        value={note}
                        variant="outlined"
                        fullWidth
                        id="note"
                        label={"Note"}
                        name="note"
                        multiline
                        rows={2}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box sx={{ width: "100%" }}>
              {error && (
                <Box mt={1} mb={2}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
              {refundType === "full" && !isFullRefundAvailable ? (
                <Button variant="contained" color="primary" fullWidth component={RouterLink} to={`/order/${order.id}/cancel`}>
                  GO TO - CANCEL ORDER
                </Button>
              ) : (
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  REFUND {amount && currency(amount).format()}
                </Button>
              )}
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      {status === "processing" && (
        <Backdrop open={true} style={{ zIndex: "5000", color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </span>
  );
}
