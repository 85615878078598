import React, { useState, useEffect, useRef } from "react";
import { Badge, Box, Menu, MenuItem, Grid, ListItemIcon, ListItemText, IconButton, Typography } from "@mui/material";
import bkstApi from "../../api";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import moment from "moment";
import NotificationIcon from "./NotificationIcon";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const menuId = "notif";
export default function UserNotificationButton(props) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const isMenuOpen = Boolean(anchorEl);

  const menuRef = useRef(anchorEl);
  menuRef.current = anchorEl;

  useEffect(() => {
    getData();
    setInterval(() => {
      if (Boolean(menuRef.current)) {
        console.log("skip auto-refresh");
      } else {
        console.log("auto-refresh");
        getData();
      }
    }, 10000);

    // refresh notifications when active
    document.addEventListener("visibilitychange", () => {
      getData();
    });
  }, []);

  const sort = (o1, o2) => {
    if (o1.priority > o2.priority) {
      return 1;
    } else if (o1.priority < o2.priority) {
      return -1;
    } else {
      return o1.link.localeCompare(o2.link);
    }
  };

  const getData = () => {
    if (document.visibilityState === "visible") {
      bkstApi.get("/my/notification").then((res) => {
        setNotifications(res.data.notifications.sort(sort));
      });
    }
  };

  const handleProfileMenuOpen = (event) => {
    getData();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const clearNotification = (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    let arr = notifications.filter((x) => x.id !== id);
    setNotifications(arr);

    bkstApi.put(`/my/notification/${id}/mark-read`).then((res) => {
      if (arr.length === 0) {
        getData();
      }
    });
  };

  const markRead = (id) => {
    setNotifications(notifications.filter((x) => x.id !== id));
    handleMenuClose();

    bkstApi.put(`/my/notification/${id}/mark-read`).then((res) => {
      getData();
    });
  };

  return (
    <span>
      <IconButton onClick={handleProfileMenuOpen} size="large">
        <Badge badgeContent={notifications.length} max={30} color="error">
          <NotificationsActiveOutlinedIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{ width: "330px" }}
      >
        <Box pl={2} pb={2} mb={2} style={{ borderBottom: "1px solid #DDD" }}>
          <Typography variant="h6">Unread Notifications</Typography>
        </Box>
        {notifications.length === 0 && (
          <MenuItem onClick={handleMenuClose}>
            <ListItemIcon>
              <CheckCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="No unread notifications" />
          </MenuItem>
        )}
        {notifications.map((item) => (
          <MenuItem key={item.id} component={RouterLink} to={item.link} onClick={(e) => markRead(item.id)} sx={{ paddingRight: "10px" }}>
            <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
              <Box pt={0.5} component={RouterLink} to={item.link} onClick={(e) => markRead(item.id)}>
                <Badge badgeContent={item.count} color="secondary" overlap="circular">
                  <NotificationIcon type={item.heading} priority={item.priority} />
                </Badge>
              </Box>
              <Box sx={{ flexGrow: "1" }}>
                <Typography variant="body2">{item.heading}</Typography>
                <Typography
                  title={item.text}
                  variant="body2"
                  color="textSecondary"
                  style={{ whiteSpace: "normal", maxHeight: "1.2rem", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {item.text}
                </Typography>
                <Box mt={0.5} style={{ fontSize: ".8rem", color: "#888" }}>
                  <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em", color: "#999" }}>
                    <AccessTimeOutlinedIcon style={{ fontSize: "1.1em", color: "#999", marginRight: "3px" }} />
                    {moment(item.ts._seconds * 1000).fromNow()}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <IconButton onClick={(e) => clearNotification(e, item.id)}>
                  <CloseIcon sx={{ color: "#999", fontSize: "16px" }} />
                </IconButton>
              </Box>
            </Box>
          </MenuItem>
        ))}
        <MenuItem
          component={RouterLink}
          to="/notification"
          onClick={handleMenuClose}
          style={{ borderTop: "1px solid #DDD", justifyContent: "center", marginTop: "1rem" }}
        >
          <Typography variant="overline">see all notifications</Typography>
        </MenuItem>
      </Menu>
    </span>
  );
}
