import React, { useRef, useEffect } from "react";
import { Box, CircularProgress, Grid, Link, InputAdornment, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import bkstApi from "../../api";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { formatPhoneNumber, getCustomerFirstNameLastInitial } from "../util";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import LocalMallIcon from "@mui/icons-material/LocalMall";

export default function QuickSearch() {
  const [q, setQ] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    useEffect(() => {
      // Cleanup the previous timeout on re-render
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    const debouncedCallback = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };

    return debouncedCallback;
  };

  const handleSearch = useDebounce((query) => {
    callApi(query);
  }, 1000);

  const callApi = () => {
    bkstApi(`/my/search?q=${q}`).then((res) => {
      const results = res.data;
      setOptions(results);
      setLoading(false);
    });
  };

  const handleClick = () => {
    setQ("");
  };

  React.useEffect(() => {
    if (q.length > 1) {
      setLoading(true);
      // Debounce the search callback
      handleSearch(q);
    } else {
      setOptions([]);
    }
  }, [q]);

  const getListItem = (obj, option) => {
    let link = "";
    if (option.type === "order") {
      link = `/${option.type}/${option.objectID}`;
    } else if (option.type === "quote") {
      link = `/quote/${option.objectID}`;
    }

    let icon = "";
    if (option.type === "quote") {
      icon = <LocalAtmOutlinedIcon style={{ fontSize: "1.1em", color: "#999", marginRight: "3px" }} />;
    } else if (option.type === "order") {
      icon = <LocalMallIcon style={{ fontSize: "1.1em", color: "#16a085", marginRight: "3px" }} />;
    }

    return (
      <Link key={option.objectID} component={RouterLink} to={link} style={{ textDecoration: "none", color: "inherit" }} onClick={handleClick}>
        <Box p={1}>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item align="center">
              <img src={option.pic} style={{ width: "50px", height: "50px", objectFit: "cover" }} />
            </Grid>
            {option.name ? (
              <Grid item>
                <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em" }}>
                  {icon}
                  {getCustomerFirstNameLastInitial(option.name)} &bull; {moment(option.date).format("MMM DD")}
                </Typography>
                {option.phone && (
                  <Typography variant="caption" display="block" color="textSecondary">
                    {formatPhoneNumber(option.phone) || ""}
                  </Typography>
                )}
              </Grid>
            ) : (
              <Grid item>
                <Typography variant="body2">{option.title}</Typography>
                <Typography variant="caption" display="block" color="textSecondary">
                  {option.desc} &bull; {option.date && moment(option.date).format("MMM DD")}
                </Typography>
                <Typography style={{ color: "#0984e3", fontSize: "11px" }}>{option.type.toUpperCase()}</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Link>
    );
  };

  return (
    <Autocomplete
      id="quick-search"
      freeSolo
      noOptionsText={"No matches found"}
      style={{ maxWidth: "250px", flexGrow: "1" }}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.bizName === value.bizName}
      getOptionLabel={(option) => option.title}
      renderOption={getListItem}
      options={options}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          value={q}
          variant="outlined"
          size="small"
          placeholder="Name or Phone (last 4)"
          onChange={(e) => setQ(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon style={{ color: "#888" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }
          }}
        />
      )}
    />
  );
}
