import React, { useContext, useState } from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import LineItemEntry from "./LineItemEntryV2";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../../../context/UserContext";
import { getOS } from "../../util";

export default function LineItemEntryPopupV2(props) {
  const { user } = useContext(UserContext);

  const fullScreen = ["iOS", "Android"].includes(getOS());

  const { mode, immutablePrice, idx } = props;

  const [item, setItem] = useState(props.item || "");
  const [errors, setErrors] = useState([]);

  const [open, setOpen] = useState(false);

  const validate = () => {
    let arr = [];
    if (!item.type?.trim()) {
      arr.push("Item Type is required");
    }
    if (item.type === "Other" && !item.title?.trim()) {
      arr.push("Title is required");
    }
    if (!(+item.price > 0)) {
      arr.push("Item Price is required");
    }
    if (!(+item.quantity > 0)) {
      arr.push("Quantity is required");
    }

    return arr;
  };

  const handleSubmit = () => {
    const arr = validate();
    setErrors(arr);

    if (arr.length > 0) {
      return;
    } else {
      props.onChange(item);
      handleClose();
    }
  };

  const handleDelete = () => {
    props.onChange("", "delete");
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setItem("");
    setErrors([]);
  };

  return (
    <>
      {mode === "add" ? (
        <Button
          disableElevation
          startIcon={<AddIcon />}
          variant={"contained"}
          color={idx === 0 ? "primary" : "secondary"}
          onClick={() => {
            setItem({ images: [], title: "", price: "", quantity: 1, includeFees: user.config?.transferFees === "false" ? false : true });
            setOpen(true);
          }}
          fullWidth
        >
          ADD ITEM
        </Button>
      ) : (
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            setItem(props.item);
            setOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      )}
      {open && (
        <Dialog fullScreen={fullScreen} open={open} fullWidth={true} maxWidth="sm">
          <DialogTitle>
            Item Details
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 10
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box py={3}>
              <LineItemEntry mode={mode} item={item} immutablePrice={Boolean(immutablePrice)} onChange={(o) => setItem(o)} />
            </Box>
            {fullScreen && <Box sx={{ height: "30vh" }}></Box>}
          </DialogContent>
          <DialogActions sx={{ display: "block" }}>
            {errors.length > 0 && (
              <Box mb={1} sx={{ display: "block" }}>
                <Alert severity="error">{errors.join(". ")}</Alert>
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
              {mode !== "add" && !immutablePrice && (
                <Box sx={{ flexGrow: "1" }}>
                  <Button variant="outlined" color="error" onClick={handleDelete} fullWidth>
                    Delete
                  </Button>
                </Box>
              )}
              <Box sx={{ justifyContent: "flex-end", gap: "15px", flexGrow: "1" }}>
                <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
                  {mode === "add" ? "ADD ITEM" : "UPDATE ITEM"}
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
