import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Button, Container, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import currency from "currency.js";
import SearchIcon from "@mui/icons-material/Search";
import { UserContext } from "../../context/UserContext";
import BDatePicker from "../../common/input/BDatePicker";
import { useLocation } from "react-router-dom";
import { getQueryParam } from "../../common/util";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link as RouterLink } from "react-router-dom";
import FeatureAccess from "../../baker/components/FeatureAccess";
import DrawOutlinedIcon from "@mui/icons-material/DrawOutlined";

const columns = [
  {
    field: "id",
    headerName: "Order",
    renderCell: (params) => (
      <IconButton component={RouterLink} to={`/order/${params.value}`}>
        <VisibilityOutlinedIcon />
      </IconButton>
    ),
    width: 50
  },
  {
    field: "date",
    headerName: "Fulfillment Date",
    renderCell: (params) => (
      <div>
        <Box py={2}>
        <Typography variant="caption" display="block">
          {moment(params.value).format("ll")}
        </Typography>
        </Box>
      </div>
    ),
    width: 120
  },
  {
    field: "tip",
    headerName: "Tip Amount",
    type: "number",
    valueFormatter: ({ value }) => currency(value).format(),
    width: 100
  },
  {
    field: "name",
    headerName: "Customer",
    width: 160
  }
];

export default function TipsReport(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  let location = useLocation();

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [orders, setOrders] = useState([]);
  const [tips, setTips] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const [loading, setLoading] = useState(false);

  // Check params to identify search criteria
  useEffect(() => {
    let [qFrom, qTo] = [getQueryParam(window.location.search, "from"), getQueryParam(window.location.search, "to")];
    if (!qFrom || !qTo) {
      // reset dates
      qFrom = moment().subtract(7, "days").format("YYYY-MM-DD");
      qTo = moment().format("YYYY-MM-DD");
    }
    search(qFrom, qTo);
  }, [location.key]);

  useEffect(() => {
    if (toDt < fromDt) {
      setToDt("");
    }
  }, [fromDt]);

  const runDateSearch = (e) => {
    e.preventDefault();
    navigate(`/report/tips?from=${fromDt}&to=${toDt}`);
  };

  const search = (fromDate, toDate) => {
    setLoading(true);
    bkstApi.get(`/my/report/tips?fromDt=${fromDate}&toDt=${toDate}`).then((res) => {
      setOrders(res.data.orders);

      let [count, tips, subtotal] = [0, 0, 0];
      for (const o of res.data.orders) {
        count++;
        tips += +o.payment.tip;
        subtotal += +o.payment.subtotal + +o.payment.delivery;
      }

      setTips(tips);
      setPercentage((tips * 100) / subtotal);

      setFromDt(fromDate);
      setToDt(toDate);

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Container maxWidth="sm">
          <FeatureAccess plan={["starter", "professional", "premium"]} />
          <Box mt={5} align="center">
            <Typography variant="h3" gutterBottom>
              Tips Report
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Tips earned over a given time period
            </Typography>
            <Box py={2} mt={2}>
              <form onSubmit={runDateSearch}>
                <Grid container>
                  <Grid item xs={12} sm={2}></Grid>
                  <Grid item xs={12} sm={8}>
                    <Box align="center" sx={{ display: "flex", justifyContent: "center" }}>
                      <BDatePicker value={fromDt} label="From Date" onChange={(d) => setFromDt(d)} required autoOk disableFuture hideIcon={true} />
                      <BDatePicker value={toDt} label="To Date" onChange={(d) => setToDt(d)} required autoOk disableFuture hideIcon={true} />
                      <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
                        <SearchIcon />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
          {orders && orders.length > 0 && (
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} align="center">
                  <Box px={2} py={3} style={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h4">{currency(tips).format()}</Typography>
                    <Typography variant="overline" color="textSecondary">
                      TOTAL
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} align="center">
                  <Box px={2} py={3} style={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h4">{Math.round(percentage)}%</Typography>
                    <Typography variant="overline" color="textSecondary">
                      AVERAGE
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ width: "100%", display: "grid" }}>
            <DataGrid
              rows={orders.map((o) => {
                return {
                  id: o.id,
                  date: o.fulfillment.date,
                  name: `${o.customer.name} ${o.customer.lastName || ""}`,
                  tip: o.payment.tip,
                  type: o.type
                };
              })}
              columns={columns}
              autoHeight
              getRowHeight={(params) => "auto"}
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "time", sort: "desc" }]
                }
              }}
            />
          </Box>
        </Container>
      </Box>
    );
  }
}
