import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import OrderCalendar from "./OrderCalendar";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

export default function OrderCalendarPopup(props) {
  const [open, setOpen] = useState(false);

  return (
    <span>
      <IconButton onClick={() => setOpen(true)}>
        <CalendarMonthOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Order Calendar</DialogTitle>
        <DialogContent>
          <OrderCalendar date={props.date} />
        </DialogContent>
        <DialogActions>
          <Box align="right">
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </span>
  );
}
