import React, { useEffect, useState } from "react";
import { Alert, Box, Container, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import bkstApi from "../../api";
import BakerQuoteSummary from "../components/BakerQuoteSummary";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { getQueryParam } from "../../common/util";
import { STORAGE_BUCKET } from "../../common/constants";

const FILTERS = ["pending", "active"];

export default function Quotes(props) {
  let location = useLocation();

  const [filter, setFilter] = useState();

  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const qFilter = getQueryParam(window.location.search, "filter");
    if (FILTERS.includes(qFilter)) {
      search(qFilter);
    } else {
      //default search
      search("pending");
    }
  }, [location.key]);

  const search = (filter) => {
    setLoading(true);
    bkstApi.get(`/my/quote?filter=${filter}`).then((res) => {
      let data = res.data.quotes;
      //if (filter === "pending") {
      //data.sort(triage);
      //} else {
      data.sort((q1, q2) => (q1.date < q2.date ? -1 : 1));
      //}

      setQuotes(data);
      setFilter(filter);
      setLoading(false);
    });
  };

  const getDescription = () => {
    if (filter === "pending") {
      return `Quote requests awaiting your quote. Timely responses go a long way in improving customer satisfaction & generating more business.`;
    } else if (filter === "active") {
      return `Quotes sent to customers that haven't expired yet`;
    }
  };

  /*const triage = (q1, q2) => {
    const q1Score = q1.servings / moment(q1.date).diff(moment(), "days").toFixed(2);
    const q2Score = q2.servings / moment(q2.date).diff(moment(), "days").toFixed(2);

    return q1Score > q2Score ? -1 : 1;
  };*/

  const description = getDescription();

  const header = (
    <Box mt={4} mb={4}>
      <Box>
        <Typography variant="h2">Quotes</Typography>
        <Box my={2}>
          <Tabs value={filter} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile sx={{ borderBottom: "1px solid #ECECEC" }}>
            <Tab value="pending" label="Pending" component={RouterLink} to={`/quote?filter=pending`} />
            <Tab value="active" label="Active" component={RouterLink} to={`/quote?filter=active`} />
          </Tabs>
        </Box>
        <Box my={2}>
          <Alert severity="info">{description}</Alert>
        </Box>
        {quotes.length > 0 && (
          <Box my={2}>
            <Typography variant="overline" color="textSecondary">
              Showing {quotes.length} quotes
            </Typography>
          </Box>
        )}
        {loading && <LinearProgress />}
      </Box>
    </Box>
  );

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        {header}
        <Box>
          {quotes.map((request, idx) => {
            return <BakerQuoteSummary key={idx} request={request} />;
          })}
        </Box>
        {quotes.length === 0 && (
          <Box px={2} pb={2} textAlign={"center"} sx={{ border: "1px solid #DDD" }}>
            <img style={{ width: "250px" }} src={`${STORAGE_BUCKET}/img/icon/search-results.svg`} />
            <Typography variant="subtitle1">Nothing found</Typography>
          </Box>
        )}
      </Container>
    );
  }
}
