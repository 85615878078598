import React, { useState, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, TextField } from "@mui/material";
import Images from "../../../request/components/Images";
import { QUOTE_TAGS } from "../../../common/constants";
import { Autocomplete } from "@mui/material";
import ItemCustomizationEntry from "./ItemCustomizationEntry";
import ItemVariantEntry from "./ItemVariantEntry";
import { ExpandMoreOutlined } from "@mui/icons-material";
import QuillRTE from "../QuillRTE";
import ProductTypeAutocomplete from "../ProductTypeAutocomplete";

export default function LineItemEntry(props) {
  const { item } = props;

  const [quantity, setQuantity] = useState(item.quantity || "1");

  const [title, setTitle] = useState(item.title || "");
  const [type, setType] = useState(item.type || "");
  const [images, setImages] = useState(item.images || []);
  const [price, setPrice] = useState(item.price || "");

  const [variant, setVariant] = useState(item.variant || "");
  const [customization, setCustomization] = useState(item.customization || "");

  const [tags, setTags] = useState(item.tags || []);
  const [description, setDescription] = useState(item.description || "");

  useEffect(() => {
    props.onChange({ type, images, title, quantity, price, variant, customization, tags, description });
  }, [quantity, title, type, images, price, variant, customization, tags, description]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={3} xs={4}>
        <Images edit={true} pics={images} onChange={(pics) => setImages(pics)} folder={`tmp`} />
      </Grid>
      <Grid item sm={9} xs={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="title"
              value={title}
              label="Title"
              name="title"
              variant="outlined"
              fullWidth
              required
              onChange={(e) => setTitle(e.target.value)}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          <Grid item sm={3} xs={6}>
            <TextField
              id="quantity"
              value={quantity}
              label="Quantity"
              name="quantity"
              variant="outlined"
              required
              onChange={(e) => setQuantity(e.target.value)}
              inputProps={{ maxLength: 3 }}
              disabled={true}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              id="price"
              value={price}
              label="Price"
              name="price"
              variant="outlined"
              required
              onChange={(e) => setPrice(e.target.value)}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Accordion elevation={0} sx={{ border: "1px solid #DEDEDE" }}>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />} sx={{ fontSize: ".9rem", backgroundColor: "#F7F7F7" }}>
            Item Details (size, flavor, etc)
          </AccordionSummary>
          <AccordionDetails sx={{ borderTop: "2px solid #DEDEDE" }}>
            <Box my={3}>
              <ProductTypeAutocomplete value={type} label="Product Type" onChange={(value) => setType(value)} />
            </Box>
            <ItemVariantEntry productType={type} frosting={item.variant?.frosting?.name} value={variant} onChange={(o) => setVariant(o)} />
            {type === "Cake" && (
              <Box my={3}>
                <ItemCustomizationEntry value={customization} type={item.type} onChange={(o) => setCustomization(o)} />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Accordion elevation={0} sx={{ border: "1px solid #DEDEDE" }}>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />} sx={{ fontSize: ".9rem", backgroundColor: "#F7F7F7" }}>
            Other (notes, etc)
          </AccordionSummary>
          <AccordionDetails sx={{ borderTop: "2px solid #DEDEDE" }}>
            <Box>
              <Box my={3}>
                <Autocomplete
                  value={tags}
                  onChange={(e, val) => setTags(val)}
                  options={QUOTE_TAGS}
                  filterSelectedOptions
                  renderInput={(params) => <TextField variant="outlined" {...params} label="Tags" />}
                  multiple
                  freeSolo
                />
              </Box>
              <Box my={3}>
                <QuillRTE value={description} onChange={(html) => setDescription(html)} />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
