import React, { useState } from "react";
import { Box, Button, Divider, Grid, Paper, Slide, TextField, Typography } from "@mui/material";
import PhoneField from "../../common/input/PhoneField";
import bkstApi from "../../api";

export default function BakerOnboardingOwner(props) {
  const [firstName, setFirstName] = useState(props.data.firstName || "");
  const [lastName, setLastName] = useState(props.data.lastName || "");
  const [mobile, setMobile] = useState(props.data.mobile || "");

  const submit = (e) => {
    e.preventDefault();

    const privateData = { firstName, lastName, mobile };

    bkstApi.put("/my/account", { baker: "", privateData }).then((res) => {
      props.done();
    });
  };

  return (
    <Slide direction="up" in={props.step === 0} mountOnEnter unmountOnExit>
      <Paper elevation={3}>
        <Box px={3} py={3}>
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              Business Owner
            </Typography>
            <Typography variant="body1" color="textSecondary">
              You have the authority to manage staff, locations & online assets
            </Typography>
          </Box>
          <form onSubmit={submit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={firstName}
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  required
                  id="firstName"
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={lastName}
                  variant="outlined"
                  fullWidth
                  required
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <PhoneField name="Mobile" value={mobile} onChange={(phone) => setMobile(phone)} required />
              </Grid>
              <Grid item xs={12} align="right">
                <Button variant="contained" color="primary" size="large" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Slide>
  );
}
