import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import OrderDetails from "./OrderDetails";

export default function OrderDetailsPopup(props) {
  const { order } = props;

  const [open, setOpen] = useState(false);

  return (
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      <IconButton onClick={() => setOpen(true)}>
        <DescriptionOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Order Details</DialogTitle>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ paddingTop: "0" }}>
          <OrderDetails order={order} />
        </DialogContent>
      </Dialog>
    </span>
  );
}
