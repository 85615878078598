import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { getItemMetadata } from "../../common/util";
import { Link as RouterLink } from "react-router-dom";
import { IMG_PLACEHOLDER, STORAGE_BUCKET } from "../../common/constants";
import currency from "currency.js";
import ScheduleIcon from "@mui/icons-material/Schedule";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import moment from "moment";

export default function ProductSummary(props) {
  const { product } = props;

  const metadata = getItemMetadata(product);

  return (
    <Grid item xs={12} sm={6}>
      <Box mb={2}>
        <Link underline="none" component={RouterLink} to={`/shop/${product.id}`} sx={{ color: "inherit" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#FEFEFE",
              borderRadius: "3px"
            }}
          >
            <Box p={1} textAlign={"left"}>
              <Typography variant="h6">{product.title}</Typography>
              <Box mt={0.5} mb={1.5}>
                {metadata.leadTime === 0 && (
                  <Box my={0.5}>
                    <Typography style={{ display: "flex", alignItems: "center", fontSize: ".85em", color: "#555" }}>
                      <ShoppingBagOutlinedIcon style={{ fontSize: "1em", color: "#555", marginRight: "3px" }} />
                      Ready to-go
                    </Typography>
                  </Box>
                )}
                {metadata.leadTime > 0 && (
                  <Box my={0.5}>
                    <Typography style={{ display: "flex", alignItems: "center", fontSize: ".85em", color: "#555" }}>
                      <ScheduleIcon style={{ fontSize: "1em", color: "#555", marginRight: "3px" }} />
                      Pre-order (ready {moment().add(metadata.leadTime, "hours").fromNow()})
                    </Typography>
                  </Box>
                )}
                {/* metadata.size?.length > 0 && (
                  <Box my={0.5}>
                    <Typography style={{ display: "flex", alignItems: "center", fontSize: ".85em", color: "#555" }}>
                      <OpenInFullOutlinedIcon style={{ fontSize: "1em", color: "#555", marginRight: "3px" }} />
                      {metadata.size.length === 1 ? `${metadata.size[0]}` : "Multiple sizes"}
                    </Typography>
                  </Box>
                )*/}
                {metadata.dietary?.length > 0 && (
                  <Box my={0.5}>
                    <Typography style={{ display: "flex", alignItems: "center", fontSize: ".85em", color: "#555" }}>
                      <RestaurantOutlinedIcon style={{ fontSize: "1em", color: "#555", marginRight: "3px" }} />
                      {metadata.dietary.join(", ")} options
                    </Typography>
                  </Box>
                )}
                 <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    color: "#777",
                    overflow: "hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "2",
                    "line-clamp": "2",
                    "-webkit-box-orient": "vertical"
                  }}
                  dangerouslySetInnerHTML={{
                    __html: product.description
                  }}
                />
              </Box>
              <Box>
                <Typography variant="body2" sx={{ fontWeight: "600" }} display="block">
                  {currency(metadata.price.min).format()}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ minWidth: "150px", maxWidth: "150px" }}>
              <img
                src={product.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`}
                style={{ width: "100%", height: "150px", objectFit: "cover", borderRadius: "3px" }}
              />
            </Box>
          </Box>
        </Link>
      </Box>
    </Grid>
  );
}
