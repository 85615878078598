import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

export default function PlacesAutocomplete(props) {
  const { onChange } = props;

  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);

  const [query, setQuery] = useState(props.value?.zip || "");
  const [place, setPlace] = useState(props.value || "");

  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      componentRestrictions: { country: "us" },
      types: ["postal_code"],
      fields: ["geometry", "name", "address_components"]
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      const o = placeAutocomplete.getPlace();
      const address = getAddress(o.address_components);

      const coord = { lat: o.geometry.location.lat(), lng: o.geometry.location.lng() };

      setPlace(address);
      setQuery(address.zip);

      onChange({ ...address, coord, description: getDescription(address) });
    });
  }, [onChange, placeAutocomplete]);

  const getDescription = (o) => {
    return `${o.city} ${o.state} (${o.zip})`;
  };

  const getAddress = (address_components) => {
    let [street, neighborhood, sublocality, locality, state, zip] = ["", "", "", "", "", ""];

    for (const component of address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          street = `${component.long_name} ${street}`;
          break;
        }

        case "route": {
          street += component.short_name;
          break;
        }

        case "postal_code": {
          zip = `${component.long_name}${zip}`;
          break;
        }

        case "postal_code_suffix": {
          //zip = `${zip}-${component.long_name}`;
          break;
        }
        case "locality":
          locality = component.short_name;
          break;
        case "sublocality_level_1":
          sublocality = component.short_name;
          break;
        case "neighborhood":
          neighborhood = component.short_name;
          break;
        case "administrative_area_level_1": {
          state = component.short_name;
          break;
        }
        case "country":
          break;
      }
    }
    const city = locality || sublocality || neighborhood;

    return { street: "", city, state, zip };
  };

  return (
    <div className="autocomplete-container">
      <TextField
        required
        name="zip"
        variant="outlined"
        fullWidth
        id="zip"
        label="Zip Code"
        inputRef={inputRef}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={(e) => {
          if (isNaN(e.key)) {
            e.preventDefault();
          }
        }}
        onBlur={(e) => {
          if (place) {
            setQuery(place.zip);
          } else {
            setQuery("");
          }
        }}
        helperText={place ? getDescription(place) : ""}
      />
    </div>
  );
}
