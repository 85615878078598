import React from "react";
import { Box, Chip, IconButton, Paper, Typography } from "@mui/material";
import IconText from "../../common/IconText";
import moment from "moment";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ImageCarousel from "../../common/component/ImageCarousel";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link as RouterLink } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function CustomerOrderSummary(props) {
  const order = props.order;

  let images = order.details.pics || [];
  if (images.length === 0 && order.items) {
    for (const item of order.items) {
      images.push(...item.images);
    }
  }

  return (
    <Box mb={4}>
      <Paper elevation={0}>
        <Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box sx={{ width: "125px", height: "125px" }}>
              <ImageCarousel images={images} />
            </Box>
            <Box p={1} sx={{ flexGrow: "1" }}>
              <Typography variant="body1">{order.details.title}</Typography>
              <Box style={{ color: "#888", marginTop: "5px" }}>
                {order.fulfillment && <IconText icon={EventOutlinedIcon} text={moment(order.fulfillment.date).format("MMM D, YYYY")} />}
                {order.fulfillment && (
                  <IconText
                    icon={order.fulfillment.type === "delivery" ? LocalShippingOutlinedIcon : LocalMallOutlinedIcon}
                    text={order.fulfillment.type}
                  />
                )}
                {(order.status === "fulfilled" || order.status === "completed") && (
                  <Chip variant="outlined" size="small" icon={<CheckCircleIcon />} label={"completed"} color="success" />
                )}
                {order.status === "pending_review" && <Chip variant="outlined" icon={<WarningIcon />} size="small" color="warning" label="pending" />}
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", flexGrow: "1", alignItems: "flex-end", justifyItems: "center" }}>
                <IconButton to={`/order/${order.id}`} component={RouterLink}>
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
