import React, { useContext } from "react";
import { Alert, Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import ReviewRatingBar from "./ReviewRatingBar";
import { BAKER_PIC_FOLDER } from "../../common/constants";
import { UserContext } from "../../context/UserContext";
import moment from "moment";

export default function Review(props) {
  const { user } = useContext(UserContext);

  let source = props.data.source;
  if (props.data.source === "goog") {
    source = "google";
  } else if (props.data.source === "fb") {
    source = "facebook";
  }

  return (
    <Paper elevation={0}>
      <Box px={2} pt={2} pb={3}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Avatar style={{ height: "32px", width: "32px" }}>{props.data.name.charAt(0).toUpperCase()}</Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h6" display="inline">
              {props.data.name}
            </Typography>
            {props.data.source !== "bkst" && (
              <Typography variant="caption" color="textSecondary">
                &nbsp;&nbsp;{`via ${source}`}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Box pt={1}>
          <Grid container spacing={1}>
            <Grid item>
              <Rating precision={0.25} name="rating" value={Number(props.data.rating)} readOnly size="small" />
            </Grid>
            <Grid item>
              <Typography variant="overline" style={{ fontWeight: "600" }}>
                {props.data.rating}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="overline" color="textSecondary">
                {moment(props.data.ts._seconds * 1000).format("MMM DD")}
              </Typography>
            </Grid>
          </Grid>
          <Box pb={3}>
            <Grid container spacing={1}>
              <Grid item sm={8} xs={12}>
                <Grid container spacing={1}>
                  {Boolean(props.data.decoration) && (
                    <Grid item sm={6} xs={9}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Decor
                        </Typography>
                        <ReviewRatingBar value={props.data.decoration} />
                      </Box>
                    </Grid>
                  )}
                  {Boolean(props.data.taste) && (
                    <Grid item sm={6} xs={9}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Taste
                        </Typography>
                        <ReviewRatingBar value={props.data.taste} />
                      </Box>
                    </Grid>
                  )}
                  {Boolean(props.data.communication) && (
                    <Grid item sm={6} xs={9}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Communication
                        </Typography>
                        <ReviewRatingBar value={props.data.communication} />
                      </Box>
                    </Grid>
                  )}
                  {Boolean(props.data.fulfillment) && (
                    <Grid item sm={6} xs={9}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          On-time
                        </Typography>
                        <ReviewRatingBar value={props.data.fulfillment} />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Typography>{props.data.text}</Typography>
          {props.data.response && (
            <Paper elevation={0} style={{ backgroundColor: "#F7F7F7", marginTop: "1rem", padding: "1rem" }}>
              <Grid container spacing={1} style={{ flexWrap: "noWrap" }}>
                <Grid item>
                  <Avatar style={{ height: "20px", width: "20px" }} src={`${BAKER_PIC_FOLDER}/${user.id}/photo400.webp`} />
                </Grid>
                <Grid item>
                  <Box>
                    <Typography variant="caption" color="textSecondary">
                      {props.data.response}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          )}
          {props.data.destination === "google" && (
            <Alert severity="success">This customer was sent to Google Business to help build your online reviews</Alert>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
