import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import HTMLViewer from "../../common/component/HTMLViewer";

export default function KitchenInstructions(props) {
  const { data } = props;

  return (
    <Paper elevation={0}>
      <Box px={2} py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HTMLViewer content={data?.notes} />
            {!data?.notes && (
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Add order-specific instructions for your staff. This is for internal use only and will not be visible to the customer.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
