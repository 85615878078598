import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Avatar, Box, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { auth } from "../../firebase";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link as RouterLink } from "react-router-dom";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import { STORAGE_BUCKET } from "../../common/constants";
import { UserContext } from "../../context/UserContext";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";

const menuId = "user-menu";
export default function UserLoggedInButton(props) {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAnchorEl(null);
    auth
      .signOut()
      .then(() => {
        setUser("");
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <span>
      <Avatar src={`${STORAGE_BUCKET}/img/u/${user.uid}.webp`} onClick={handleProfileMenuOpen} style={{ cursor: "pointer" }}></Avatar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {user && (user.role === "baker" || user.role === "owner" || user.role === "rep" || user.role === "manager") && (
          <Box>
            {/* (user.role === "baker" || user.role === "owner") && (
              <MenuItem component={RouterLink} to={`/profile`} onClick={handleMenuClose}>
                <ListItemIcon>
                  <AccountBoxOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </MenuItem>
            )*/}
            { (user.role === "baker" || user.role === "owner") && user.plan && <MenuItem component={RouterLink} to={`/plan`} onClick={handleMenuClose}>
              <ListItemIcon>
                <CardMembershipOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Plan" />
            </MenuItem> }
            <MenuItem component={RouterLink} to={`/photos`} onClick={handleMenuClose}>
              <ListItemIcon>
                <PhotoLibraryOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Photos" />
            </MenuItem>
            <MenuItem component={RouterLink} to={`/flavors`} onClick={handleMenuClose}>
              <ListItemIcon>
                <SpaOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Flavors" />
            </MenuItem>
            <MenuItem component={RouterLink} to={`/reviews`} onClick={handleMenuClose}>
              <ListItemIcon>
                <StarHalfOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Reviews" />
            </MenuItem>
          </Box>
        )}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </span>
  );
}
