import React, { useContext, useEffect, useState } from "react";
import { Box, Container, IconButton, Grid, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import { capitalize, formatPhoneNumber, getCustomerFirstNameLastInitial, hasManagerAccess } from "../../common/util";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import bkstApi from "../../api";
import { useParams } from "react-router-dom";
import ClickToCopy from "../../common/ClickToCopy";
import ClickToCall from "../../common/ClickToCall";
import LoyaltyStatus from "../../common/component/LoyaltyStatus";
import CustomerOrderSummary from "../../order/component/CustomerOrderSummary";
import QuoteSummary from "../../quote/component/CustomerQuoteSummary";
import { UserContext } from "../../context/UserContext";

export default function CustomerDetail(props) {
  const { user } = useContext(UserContext);
  const { customerId } = useParams();

  const [customer, setCustomer] = useState("");
  const [loading, setLoading] = useState(true);

  const [tab, setTab] = useState(0);

  useEffect(() => {
    bkstApi(`/my/customer/${customerId}`).then((res) => {
      setCustomer(res.data.customer);
      setLoading(false);
    });
  }, [customerId]);

  let isManager = hasManagerAccess(user.role);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box my={6}>
          <Box mb={4}>
            <Typography variant="h4">
              {customer.firstName} {customer.lastName}
            </Typography>
            {customer?.level && <LoyaltyStatus status={customer.level} />}
          </Box>
          <Box sx={{ borderTop: "3px solid #EEE" }}>
            <Tabs value={tab} variant="fullWidth">
              <Tab key={0} label={"Profile"} onClick={() => setTab(0)} />
              {customer.phone && <Tab key={1} label={"Orders"} onClick={() => setTab(1)} />}
              {isManager && customer.phone && <Tab key={2} label={"Quotes"} onClick={() => setTab(2)} />}
            </Tabs>
          </Box>
          {tab === 0 && (
            <Box py={4}>
              <Box>
                <Typography variant="overline" color="textSecondary">
                  First Name
                </Typography>
                <Typography variant="body1">{customer.firstName}</Typography>
              </Box>
              <Box my={2}>
                <Typography variant="overline" color="textSecondary">
                  Last Name
                </Typography>
                <Typography variant="body1">{customer.lastName}</Typography>
              </Box>
              {customer?.level && (
                <Box my={2}>
                  <Typography variant="overline" color="textSecondary">
                    LOYALTY STATUS
                  </Typography>
                  <Typography variant="body1">{capitalize(customer?.level)}</Typography>
                </Box>
              )}
              <Box my={2}>
                <Typography variant="overline" color="textSecondary">
                  Phone
                </Typography>
                {customer.phone && (
                  <Typography variant="body1">
                    {formatPhoneNumber(customer.phone)} <ClickToCopy text={customer.phone} />
                    <ClickToCall phone={customer.phone} />
                  </Typography>
                )}
              </Box>
              <Box my={2}>
                <Typography variant="overline" color="textSecondary">
                  Email
                </Typography>
                {customer.email && (
                  <Typography variant="body1">
                    {customer.email} <ClickToCopy text={customer.email} />
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {tab === 1 && (
            <Box py={4}>
              {customer.orders
                ?.sort((o1, o2) => o2.fulfillment.date.localeCompare(o1.fulfillment.date))
                .map((order) => {
                  return <CustomerOrderSummary key={order.id} order={order} />;
                })}
            </Box>
          )}
          {tab === 2 && (
            <Box py={4}>
              <Box mb={4} textAlign={"center"}>
                <Typography variant="body2" color="textSecondary">
                  Quotes for upcoming event dates
                </Typography>
              </Box>
              {customer.quotes
                ?.sort((o1, o2) => o2.date.localeCompare(o1.date))
                .map((request, idx) => {
                  return <QuoteSummary key={idx} request={request} />;
                })}
            </Box>
          )}
        </Box>
      </Container>
    );
  }
}
