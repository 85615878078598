import React, { useContext } from "react";
import { Backdrop, Box, Button, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/UserContext";
import ReportIcon from "@mui/icons-material/Report";
import { Link as RouterLink } from "react-router-dom";

export default function FeatureAccess(props) {
  const { plan } = props;
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  if (plan.includes(user.plan)) {
    return <></>;
  } else {
    return (
      <Backdrop open={true} style={{ zIndex: "1000", color: "#fff", backgroundColor: "rgba(0, 0, 0, 0.75)" }}>
        <Box sx={{ display: "flex", textAlign: "center" }}>
          <Box sx={{ maxWidth: "360px" }}>
            <ReportIcon sx={{ fontSize: "4rem" }} />
            <Typography variant="h4" gutterBottom>
              This feature is not available on your current plan
            </Typography>
            <Typography variant="subtitle1">Click the button below to compare plans and upgrade to a new plan.</Typography>
            <Box my={4}>
              <Button fullWidth variant="contained" color="secondary" size="large" component={RouterLink} to={`/plan?view=all`}>
                View Plans
              </Button>
              <Box mt={1}>
                <Link underline="hover" color="inherit" onClick={() => navigate(-1)}>
                  Go Back
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Backdrop>
    );
  }
}
