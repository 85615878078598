import React, { useEffect, useState } from "react";
import { Autocomplete, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import BDatePicker from "../../input/BDatePicker";
import bkstApi from "../../../api";
import { TIME_WINDOWS } from "../../constants";
import AddressAutocomplete from "../../gmaps/AddressAutocomplete";
import { getFormattedAddress } from "../../util";

export default function FulfillmentEntry(props) {
  const { fulfillment } = props;

  const [storeLocations, setStoreLocations] = useState([]);

  useEffect(() => {
    // get timeslots
    bkstApi(`/my/location`).then((res) => {
      const arr = res.data.locations;
      setStoreLocations(arr);

      // THIS DOESN'T WORK
      /*if (arr.length === 1) {
        props.onChange({ ...fulfillment, address: arr[0] });
      }*/
    });
  }, []);

  useEffect(() => {
    // clear address and instructions
    props.onChange({ ...fulfillment, address: "", instructions: "" });
  }, [fulfillment?.type]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RadioGroup row name="type" value={fulfillment?.type} onChange={(e) => props.onChange({ ...fulfillment, type: e.target.value })}>
          <FormControlLabel value="pickup" control={<Radio />} label={`Pickup`} size="large" color="primary" />
          <FormControlLabel value="delivery" control={<Radio />} label={`Delivery`} />
        </RadioGroup>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
          <BDatePicker
            label="Date"
            value={fulfillment?.date || ""}
            onChange={(date) => props.onChange({ ...fulfillment, date })}
            disablePast
            required
            autoOk
          />
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Autocomplete
          options={TIME_WINDOWS}
          renderInput={(params) => <TextField variant="outlined" {...params} label="Time" />}
          disableClearable
          freeSolo
          autoSelect
          blurOnSelect={true}
          value={fulfillment?.time || ""}
          onChange={(e, value) => props.onChange({ ...fulfillment, time: value })}
        />
      </Grid>
      <Grid item xs={12}>
        {fulfillment?.type === "pickup" && (
          <FormControl variant="outlined" fullWidth required>
            <InputLabel id="address-label">Pickup Location</InputLabel>
            <Select
              value={fulfillment?.location || ""}
              labelId="address-label"
              label="Pickup Location"
              id="address"
              name="address"
              renderValue={(option) => {
                return getFormattedAddress(option);
              }}
              onChange={(e) => {
                props.onChange({ ...fulfillment, location: e.target.value });
              }}
            >
              {storeLocations.map((o, idx) => {
                return (
                  <MenuItem key={idx} value={o.address}>
                    {getFormattedAddress(o.address)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {fulfillment?.type === "delivery" && (
          <FormControl variant="outlined" fullWidth>
            <AddressAutocomplete
              value={fulfillment?.location || ""}
              onPlaceSelect={(loc) => {
                props.onChange({ ...fulfillment, location: loc });
              }}
              label="Delivery Address"
              required
            />
          </FormControl>
        )}
      </Grid>
      {fulfillment?.type === "delivery" && (
        <Grid item xs={12}>
          <TextField
            id="instructions"
            label="Instructions"
            name="instructions"
            variant="outlined"
            fullWidth
            value={fulfillment?.instructions || ""}
            onChange={(e) => props.onChange({ ...fulfillment, instructions: e.target.value })}
          />
        </Grid>
      )}
    </Grid>
  );
}
