import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router";
import { Box, Button, Container, Grid, Link, LinearProgress, Paper, Typography, Alert } from "@mui/material";
import bkstApi from "../../api";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";

export default function OwnerDashboard(props) {
  const { user } = useContext(UserContext);
  let location = useLocation();
  const navigate = useNavigate();

  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi.get(`/my/dash`).then((res) => {
      setSummary(res.data);
      setLoading(false);
    });
  }, [location.key]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box>
          <Box mt={4} mb={2}>
            <Typography variant="h4">Dashboard</Typography>
          </Box>
          {summary.announcement.map((o) => (
            <Box my={2}>
              <Alert severity="info">{o.text}</Alert>
            </Box>
          ))}
          <Grid container spacing={4} sx={{ textAlign: "center" }}>
            <Grid item sm={6} xs={12}>
              <Link underline="none" component={RouterLink} to={`/invoice`}>
                <Paper elevation={0}>
                  <Box p={2} sx={{ borderBottom: "1px solid #EEE" }}>
                    <SendIcon sx={{ fontSize: "3rem", color: "#555" }} />
                    <Box>
                      <Typography variant="caption" color="textSecondary">
                        Send a quote that customer can checkout on their own device
                      </Typography>
                    </Box>
                  </Box>
                  <Button disableElevation variant="contained" color="primary" fullWidth>
                    Send Invoice
                  </Button>
                </Paper>
              </Link>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Link underline="none" component={RouterLink} to={`/quote?filter=pending`}>
                <Paper elevation={0}>
                  <Box p={2} sx={{ borderBottom: "1px solid #EEE" }}>
                    <Typography variant="h2">{summary.quote.pending.count}</Typography>
                    <Typography variant="overline">Pending Quotes</Typography>
                    <Box my={1}>
                      <Typography variant="caption" color="textSecondary">
                        Quotes waiting for your response
                      </Typography>
                    </Box>
                  </Box>
                  <Button disableElevation variant="contained" color="secondary" fullWidth>
                    Send Quotes
                  </Button>
                </Paper>
              </Link>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Link underline="none" component={RouterLink} to={`/order?search=recent`}>
                <Paper elevation={0}>
                  <Box p={2} sx={{ borderBottom: "1px solid #EEE" }}>
                    <Typography variant="h2">{summary.order.recent.count}</Typography>
                    <Typography variant="overline">Recent Orders</Typography>
                    <Box my={1}>
                      <Typography variant="caption" color="textSecondary">
                        Orders placed in the last 24 hours
                      </Typography>
                    </Box>
                  </Box>
                  <Button disableElevation variant="contained" color="secondary" fullWidth>
                    View Recent Orders
                  </Button>
                </Paper>
              </Link>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Link underline="none" component={RouterLink} to={`/order?search=upcoming`}>
                <Paper elevation={0}>
                  <Box p={2} sx={{ borderBottom: "1px solid #EEE" }}>
                    <Typography variant="h2">{summary.order.upcoming.count}</Typography>
                    <Typography variant="overline">Upcoming Orders</Typography>
                    <Box my={1}>
                      <Typography variant="caption" color="textSecondary">
                        Orders due today
                      </Typography>
                    </Box>
                  </Box>
                  <Button disableElevation variant="contained" color="secondary" fullWidth>
                    View Upcoming Orders
                  </Button>
                </Paper>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}
