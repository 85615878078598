import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  DialogActions,
  IconButton
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import currency from "currency.js";
import AddIcon from "@mui/icons-material/Add";
import ProductSizeAutocomplete from "../../common/component/ProductSizeAutocomplete";
import ProductServingsAutocomplete from "../../common/component/ProductServingsAutocomplete";
import { getAmountWithoutTxnFee } from "../../common/util";

export default function PricingVariant(props) {
  const { productType, value } = props;

  const onChange = (mode, idx, data) => {
    const arr = [...value];

    if (mode === "add") {
      arr.push(data);
    } else if (mode === "edit") {
      arr[idx] = data;
    } else if (mode === "delete") {
      arr.splice(idx, 1);
    }
    props.onChange(arr);
  };

  return (
    <Box>
      <Box>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <Typography variant="h6">Variant Pricing</Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <Entry mode="add" value="" productType={productType} onChange={(mode, data) => onChange(mode, 0, data)} />
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={0}>
        <Box px={2} pt={4} pb={2}>
          {value &&
            Object.entries(value).map(([k, v], idx) => {
              return <Row key={k} idx={idx} productType={productType} variant={v} onChange={onChange} />;
            })}
          {value.length === 0 && (
            <Box px={2} py={4} align="center">
              <Typography variant="subtitle1">Add Variant</Typography>
              <Typography variant="body1" color="textSecondary">
                Click + to add one or more variants
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

function Row(props) {
  const { idx, variant, productType } = props;

  const onChange = (mode, data) => {
    props.onChange(mode, idx, data);
  };

  let title = variant.size;
  if (variant.servings) {
    title += ` (${variant.servings} servings)`;
  }

  return (
    <Box mb={1}>
      <Box>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={9}>
            <Typography variant="body1">{title}</Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <Typography variant="body2">{currency(variant.pricing.amount).format()}</Typography>
          </Grid>
          <Grid item xs={1} align="right">
            <Entry mode="edit" productType={productType} variant={variant} onChange={onChange} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function Entry(props) {
  const { variant, productType, mode } = props;

  const [open, setOpen] = useState(false);

  const [size, setSize] = useState(variant?.size || "");
  const [servings, setServings] = useState(variant?.servings || "");

  const [price, setPrice] = useState(variant?.pricing?.amount || "");
  const [includeFees, setIncludeFees] = useState(variant?.pricing?.includeFees || "");

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.onChange(mode, { size, servings, pricing: { type: "fixed", amount: currency(price).value } });
    setOpen(false);
    reset();
  };

  const handleDelete = () => {
    props.onChange("delete");
    setOpen(false);
    reset();
  };

  const reset = () => {
    setSize("");
    setServings("");
    setPrice("");
  };

  return (
    <span>
      <IconButton size="small" onClick={() => setOpen(true)}>
        {mode === "edit" ? <EditOutlinedIcon /> : <AddIcon />}
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <form onSubmit={submit}>
          <DialogTitle>Variant Details</DialogTitle>
          <DialogContent>
            <Box pt={4}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <ProductSizeAutocomplete required productType={productType} value={size} label="Size" onChange={(value) => setSize(value)} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ProductServingsAutocomplete productType={productType} value={servings} label="Servings" onChange={(value) => setServings(value)} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="price"
                    value={price}
                    variant="outlined"
                    fullWidth
                    id="price"
                    label="Price"
                    onChange={(e) => setPrice(e.target.value)}
                    onWheel={(e) => {
                      e.target && e.target.blur();
                    }}
                    type="number"
                    required
                    helperText="Enter final price, including all mandatory fees"
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={4}>
                <Button onClick={handleDelete} style={{ color: "#e74c3c" }}>
                  Remove
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Box align="right">
                  <Button style={{ marginRight: "15px" }} onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Confirm
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </span>
  );
}
