import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import KitchenOrdersByDate from "./KitchenOrdersByDate";
import Orders from "./Orders";

export default function OrderDashboard(props) {
  const { user } = useContext(UserContext);

  if (user.role === "baker" || user.role === "owner") {
    return <Orders />;
  } else if (user.role === "rep" || user.role === "manager") {
    return <Orders />;
  } else if (user.role === "clerk") {
    return <Orders />;
  } else if (user.role === "kitchen" || user.role === "kitchen leader") {
    return <KitchenOrdersByDate />;
  }
}
