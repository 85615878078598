import React, { useState } from "react";
import { Badge, IconButton } from "@mui/material";
import { upload } from "../firebase";
import CancelIcon from "@mui/icons-material/Cancel";
import { placeholderImg, spinnerImg } from "./constants";

export default function PicUpload(props) {
  const [pic, setPic] = useState(props.src);

  function handleUpload(event, folder, onChange) {
    const uploadTask = upload(event.target.files[0], folder);
    uploadTask.then((snapshot) => {
      snapshot.ref.getDownloadURL().then(function (downloadURL) {
        onChange(downloadURL);
      });
    });
    setPic(spinnerImg);
  }

  function onUpload(url) {
    setPic(url);
    props.onChange(url);
  }

  function remove(e) {
    e.preventDefault();
    onUpload("");
  }

  const photo = (
    <img
      alt=""
      src={pic || placeholderImg}
      style={{ cursor: "pointer", border: "1px solid #eee", height: props.height || "auto", width: props.width || "auto" }}
    />
  );

  return (
    <span style={{ marginRight: "20px" }}>
      <input accept="image/*" id={props.id} type="file" onChange={(e) => handleUpload(e, props.folder, onUpload)} style={{ display: "none" }} />
      <label htmlFor={props.id}>
        {props.required ? (
          photo
        ) : (
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            badgeContent={
              pic ? (
                <IconButton onClick={(e) => remove(e)} size="large">
                  <CancelIcon />
                </IconButton>
              ) : (
                ""
              )
            }
          >
            {photo}
          </Badge>
        )}
      </label>
    </span>
  );
}
