import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  Grid,
  Link,
  LinearProgress,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import bkstApi from "../../api";
import OrderDetails from "../component/OrderDetails";
import OrderFulfillment from "../component/OrderFulfillment";
import BakerOrderPayment from "../component/BakerOrderPayment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link as RouterLink } from "react-router-dom";
import { Alert } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import OrderCancelationPolicy from "../component/OrderCancelationPolicy";
import LatePaymentPolicy from "../component/LatePaymentPolicy";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { hasManagerAccess } from "../../common/util";
import CustomerDetailsButton from "../../common/component/CustomerDetailsButton";
import BakerMktOrderPolicy from "../component/BakerMktOrderPolicy";
import { UserContext } from "../../context/UserContext";
import OrderChat from "../component/OrderChat";
import MarkOrderCompleted from "../component/MarkOrderCompleted";
import { useLocation } from "react-router-dom";
import OrderPrint from "../../baker/screen/OrderPrint";
import Review from "../../review/component/Review";
import PendingReviewOrder from "./PendingReviewOrder";
import KitchenInstructions from "../component/KitchenInstructions";
import OrderProgress from "../component/OrderProgress";
import CustomerContactInfoButton from "../../common/component/CustomerContactInfoButton";
import KitchenOrder from "../../baker/components/KitchenOrder";
import { ExpandMoreOutlined } from "@mui/icons-material";

export default function Order(props) {
  const { user } = useContext(UserContext);
  let location = useLocation();

  const navigate = useNavigate();

  const { orderId } = useParams();
  const [order, setOrder] = useState();

  useEffect(() => {
    setOrder();
    bkstApi(`/my/order/${orderId}`)
      .then((res) => {
        document.title = res.data.details?.title;
        setOrder(res.data);

        window.HubSpotConversations.widget.refresh();
      })
      .catch((error) => {
        navigate(`/dashboard`);
      });
  }, [orderId, location.key]);

  if (!order) {
    return <LinearProgress />;
  } else {
    let isManager = hasManagerAccess(user.role) || user.role === "kitchen leader";

    const showAddOnButton = isManager && order.status === "confirmed" && order.cid;
    const showEditButton = isManager && order.status === "confirmed";
    const showChat =
      (isManager && order.src === "direct" && order.paymentType !== "offline") ||
      (isManager && order.src === "mkt" && (order.status === "confirmed" || order.status === "fulfilled"));

    const orderDetailsWidget = (
      <Box my={6}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            {order.src === "direct" ? (
              <CustomerDetailsButton customer={order.customer} src={order.src} />
            ) : (
              <CustomerContactInfoButton
                customer={{
                  firstName: order.customer.firstName,
                  lastName: order.customer.lastName,
                  name: order.customer.name,
                  phone: ((order.status === "confirmed" || order.status === "fulfilled") && order.customer.phone) || ""
                }}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "flex-end" }}>
              {showChat && <OrderChat order={order} />}
              {showAddOnButton && (
                <IconButton size="small" component={RouterLink} to={`/order/${orderId}/create-invoice`}>
                  <AddShoppingCartOutlinedIcon />
                </IconButton>
              )}
              {showEditButton && (
                <IconButton size="small" component={RouterLink} to={`/order/${orderId}/edit`}>
                  <EditOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
        <OrderDetails order={order} allowDownload={true} />
      </Box>
    );

    const kitchenWidget = (
      <Box my={6}>
        <Grid container style={{ marginBottom: "5px", alignItems: "center" }}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              INSTRUCTIONS
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {order.status === "confirmed" && (
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "flex-end" }}>
                <OrderProgress progress={order.progress} orderId={order.id} />
                <OrderPrint order={order} />
                <IconButton size="small" component={RouterLink} to={`/order/${orderId}/kitchen/edit`}>
                  <EditOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
        </Grid>
        <KitchenInstructions data={order.kitchen} />
      </Box>
    );

    let content = <Box />;
    if (order.status === "pending") {
      content = (
        <Box>
          <Box my={6}>
            <OrderDetails order={order} allowDownload={true} />
          </Box>
          <Box my={6}>
            <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          </Box>
        </Box>
      );
    } else if (order.status === "canceled" && order.src === "mkt") {
      content = (
        <Box>
          <Alert severity="error">This order has been canceled</Alert>
          <Box my={6}>
            <OrderDetails order={order} />
          </Box>
        </Box>
      );
    } else if (order.status === "confirmed") {
      content = (
        <Box>
          {order.paymentType === "offline" && (
            <Alert severity="warning">
              Cash orders are for internal tracking only. They don't track payment, support in-app chat or send automated notifications like order
              confirmation, reminders or solicit review.
            </Alert>
          )}
          {orderDetailsWidget}
          {isManager && kitchenWidget}
          <Box my={6}>
            <Grid container alignItems="center" style={{ marginBottom: "5px" }}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  {order.fulfillment.type} DETAILS
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                {isManager && (
                  <IconButton size="small" component={RouterLink} to={`/order/${orderId}/fulfillment/edit`}>
                    <EditOutlinedIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          </Box>
          <Box my={6}>
            <BakerOrderPayment order={order} />
          </Box>
        </Box>
      );
    } else if (order.status === "fulfilled") {
      content = (
        <Box>
          {orderDetailsWidget}
          {isManager && kitchenWidget}
          <Box my={6}>
            <Typography variant="overline" color="textSecondary">
              {order.fulfillment.type} Details
            </Typography>
            <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          </Box>
          <Box my={6}>
            <BakerOrderPayment order={order} />
          </Box>
        </Box>
      );
    } else if (order.status === "completed" || order.status === "canceled") {
      content = (
        <Box>
          {order.status === "completed" && <Alert severity="success">This order has been completed!</Alert>}
          {order.status === "canceled" && <Alert severity="error">This order has been canceled</Alert>}
          {orderDetailsWidget}
          {isManager && kitchenWidget}
          <Box my={6}>
            <Typography variant="overline" color="textSecondary">
              {order.fulfillment.type} Details
            </Typography>
            <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          </Box>
          <Box my={6}>
            <BakerOrderPayment order={order} />
          </Box>
        </Box>
      );
    }

    if (user.role === "kitchen") {
      return (
        <Container maxWidth="md">
          <KitchenOrder order={order} />{" "}
        </Container>
      );
    } else if (order.status === "pending_review") {
      return <PendingReviewOrder order={order} />;
    } else {
      return (
        <Container maxWidth="sm">
          {order.status === "confirmed" && hasManagerAccess(user.role) && (
            <Box mb={2}>
              <MarkOrderCompleted order={order} />
            </Box>
          )}
          <Box my={4} align="center">
            <Typography variant="h3" gutterBottom>
              Order Details
            </Typography>
            {(user.role === "owner" || user.role === "manager") && (
              <Typography variant="body2" color="textSecondary">
                Manage all aspects of this order, including communication with customer, payments, add-ons, order updates & more..
              </Typography>
            )}
          </Box>
          <Box my={6}>{content}</Box>
          {isManager && order.review && (
            <Box my={6}>
              <Typography variant="overline" color="textSecondary">
                REVIEW
              </Typography>
              <Review data={order.review} />
            </Box>
          )}
          {order.src === "mkt" && (
            <Box my={6}>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                BAKESTREET'S POLICIES
              </Typography>
              <Box p={2} sx={{ border: "1px solid #DDD" }}>
                <Box mb={2}>
                  <Typography variant="body2" color="textSecondary">
                    This is a BakeStreet marketplace order. The following policies are applicable to you.
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    ---
                  </Typography>
                </Box>
                <BakerMktOrderPolicy />
              </Box>
            </Box>
          )}
          {order.src === "direct" && order.paymentType !== "offline" && (
            <Box my={6}>
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Order Policy</AccordionSummary>
                <AccordionDetails sx={{ marginLeft: "15px", borderLeft: "3px solid #EEE" }}>
                  <Box p={2}>
                    <Box mb={2}>
                      <Typography variant="body2" color="textSecondary">
                        These policies were shown to the customer during checkout. They are also visible to the customer on their order page.
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        ---
                      </Typography>
                    </Box>
                    <OrderCancelationPolicy
                      date={order.fulfillment.date}
                      total={order.payment.total}
                      tip={order.payment.tip}
                      delivery={order.payment.delivery}
                      ts={order.ts._seconds * 1000}
                    />
                    <LatePaymentPolicy />
                  </Box>
                </AccordionDetails>
              </Accordion>
              {order.reqId && hasManagerAccess(user.role) && (
                <Accordion elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Miscellaneous</AccordionSummary>
                  <AccordionDetails sx={{ marginLeft: "15px", borderLeft: "3px solid #EEE" }}>
                    <Box p={2}>
                      <Box mb={2}>
                        <Link component={RouterLink} to={`/quote/${order.reqId}`}>
                          View Quote
                        </Link>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          )}
          {isManager && order.status === "confirmed" && order.src === "direct" && (
            <Box align="center" my={6}>
              <Button color="error" variant="outlined" fullWidth to={`/order/${order.id}/cancel`} component={RouterLink}>
                Cancel This Order
              </Button>
              <Typography mt={2} variant="body2" color="textSecondary">{`This order was confirmed on ${moment(order.ts._seconds * 1000).format(
                "ddd, MMM DD, YYYY h:mm a"
              )} (${moment(order.ts._seconds * 1000).fromNow()})`}</Typography>
            </Box>
          )}
        </Container>
      );
    }
  }
}
