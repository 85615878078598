import React, { useState, useEffect } from "react";
import { Box, Button, Container, Grid, LinearProgress, Paper, Tab, Tabs, Typography } from "@mui/material";
import bkstApi from "../../api";
import { Link as RouterLink } from "react-router-dom";
import ProductSummary from "../component/ProductSummary";
import { useLocation } from "react-router-dom";
import { capitalize, getQueryParam } from "../../common/util";
import FeatureAccess from "../../baker/components/FeatureAccess";

const FILTERS = ["active", "draft"];

export default function OnlineShop(props) {
  let location = useLocation();

  const [filter, setFilter] = useState("");

  const [products, setProducts] = useState([]);

  const [category, setCategory] = useState("");
  const [categoryMap, setCategoryMap] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let qFilter = getQueryParam(window.location.search, "filter");
    if (!FILTERS.includes(qFilter)) {
      qFilter = "active";
    }

    setLoading(true);
    bkstApi(`/my/product?filter=${qFilter}`).then((res) => {
      let sortedByRank = res.data.products.sort((o1, o2) => {
        let rslt = (+o2.rank || 0) - (+o1.rank || 0);
        if (rslt === 0) {
          rslt = o1.title > o2.title ? 1 : -1;
        }
        return rslt;
      });
      setProducts(sortedByRank);

      let map = {};
      for (const p of sortedByRank) {
        const cat = p.type.toLowerCase();
        const arr = map[cat] || [];
        arr.push(p);
        map[cat] = arr;
      }
      setCategoryMap(map);

      setFilter(qFilter);
      setLoading(false);

      if (window.location.hash) {
        setTimeout(() => {
          setCategory(window.location.hash.substring(1));
          scrollToCategory(window.location.hash.substring(1).replace("-", " "));
        }, 500);
      } else {
        window.scrollTo(0, 0);
      }
    });
  }, [location.key]);

  const scrollToCategory = (cat) => {
    const el = document.getElementById(cat);
    if (el) {
      el.scrollIntoView();
    }
  };

  const sortCategories = (o1, o2) => {
    if (o1.includes("4th of July") || o1.includes("Featured")) {
      return -1;
    } else if (o2.includes("4th of July") || o2.includes("Featured")) {
      return 1;
    } else {
      return o1.localeCompare(o2);
    }
  };

  if (loading) {
    return <LinearProgress />;
  } else if (products.length === 0 && filter === "active") {
    return (
      <Container maxWidth="md">
        <FeatureAccess plan={["professional", "premium"]} />
        <Box mt={6} mb={4} textAlign={"center"}>
          <Typography variant="h3" gutterBottom>
            Grow your Online Sales
          </Typography>
        </Box>
        <Box>
          <Paper elevation={0}>
            <Box px={2} py={2}>
              <ul>
                <li>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Diversify your business by offering everyday cakes, treats & desserts for pre-order.
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Launch seasonal & limited time items to keep things exciting & build buzz around your business.
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Leverage powerful rules to set lead time for each item to ensure you have sufficient time to prepare each item.
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Limit product availability between specific dates or a specific day of the week!
                  </Typography>
                </li>
              </ul>
            </Box>
          </Paper>
          <Box mt={2}>
            <Button variant="contained" color="primary" component={RouterLink} to={`/shop/add`} fullWidth>
              Add Your First Item!
            </Button>
          </Box>
        </Box>
      </Container>
    );
  } else {
    return (
      <Box>
        <Container maxWidth="md">
          <Box mt={6}>
            <Box>
              <Box sx={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
                <Typography variant="h3">Online Shop</Typography>
                <Button variant="contained" color="primary" component={RouterLink} to={`/shop/add`}>
                  Add Item
                </Button>
              </Box>
              <Box my={2}>
                <Tabs value={filter} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile sx={{ borderBottom: "1px solid #ECECEC" }}>
                  <Tab value="active" label="Active" component={RouterLink} to={`/shop?filter=active`} />
                  <Tab value="draft" label="Draft" component={RouterLink} to={`/shop?filter=draft`} />
                </Tabs>
              </Box>
            </Box>

            {/*products.length > 0 && (
              <Box my={2}>
                <Typography variant="overline" color="textSecondary">
                  Showing {products.length} items
                </Typography>
              </Box>
            )*/}
            <Box
              mt={4}
              mb={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#FEFEFE",
                position: "-webkit-sticky",
                position: "sticky",
                top: "0",
                boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)"
              }}
            >
              <Tabs
                value={false}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{
                  ".MuiButtonBase-root": {
                    padding: "7px"
                  }
                }}
              >
                {Object.keys(categoryMap)
                  .sort(sortCategories)
                  .map((cat, idx) => (
                    <Tab key={idx} label={cat} onClick={() => scrollToCategory(cat)} />
                  ))}
              </Tabs>
            </Box>
            {Object.keys(categoryMap)
              .sort(sortCategories)
              .map((key) => {
                return (
                  <Box mb={4} id={key} sx={{ scrollMarginTop: "75px" }}>
                    <Box mb={2} textAlign={"left"}>
                      <Typography variant="h5">{capitalize(key)}</Typography>
                    </Box>
                    <Grid container spacing={3}>
                      {categoryMap[key].map((product, index) => {
                        return <ProductSummary key={index} product={product} />;
                      })}
                    </Grid>
                  </Box>
                );
              })}
          </Box>
        </Container>
      </Box>
    );
  }
}
