import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import currency from "currency.js";
import { UserContext } from "../../context/UserContext";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";

const columns = [
  {
    field: "link",
    headerName: "View",
    renderCell: (params) => (
      <IconButton component={RouterLink} to={params.value} disabled={!params.value}>
        <VisibilityOutlinedIcon />
      </IconButton>
    ),
    width: 50
  },
  {
    field: "type",
    headerName: "Type",
    renderCell: (params) => (
      <Box sx={{ display: "flex", color: "#999" }}>{params.value === "shop" ? <ShoppingCartOutlinedIcon /> : <LocalAtmOutlinedIcon />}</Box>
    ),
    width: 50
  },
  {
    field: "time",
    headerName: "Timestamp",
    type: "datetime",
    valueGetter: ({ value }) => value && new Date(value),
    renderCell: (params) => (
      <div>
        <Box py={1}>
          <Typography variant="caption" display="block">
            {moment(params.value).format("ll")}
          </Typography>
          <Typography variant="overline" display="block" color="textSecondary">
            {moment(params.value).format("LT")}
          </Typography>
        </Box>
      </div>
    ),
    width: 120
  },
  {
    field: "netAmount",
    headerName: "Net Amount",
    type: "number",
    valueFormatter: ({ value }) => currency(value, { fromCents: true }).format(),
    width: 120
  },
  {
    field: "grossAmount",
    headerName: "Gross Amount",
    type: "number",
    valueFormatter: ({ value }) => currency(value, { fromCents: true }).format(),
    width: 120
  },
  {
    field: "paymentFee",
    headerName: "Payment Fee",
    type: "number",
    valueFormatter: ({ value }) => currency(value, { fromCents: true }).format(),
    width: 100
  },
  {
    field: "successFee",
    headerName: "Success Fee",
    type: "number",
    valueFormatter: ({ value }) => currency(value, { fromCents: true }).format(),
    width: 100
  },
  {
    field: "loanFee",
    headerName: "Loan Repayment",
    type: "number",
    valueFormatter: ({ value }) => currency(value, { fromCents: true }).format(),
    width: 120
  }
];

export default function PayoutDetails(props) {
  const { user } = useContext(UserContext);
  const { payoutId } = useParams();

  const [payout, setPayout] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi.get(`/my/payouts/${payoutId}`).then((res) => {
      setPayout(res.data.payout);
      setLoading(false);
    });
  }, [payoutId]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Container maxWidth="md">
          <Box>
            <Box my={5} align="center">
              <Typography variant="h2" gutterBottom>
                Payout on {moment(payout.summary.created * 1000).format("ll")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Showing a breakdown of all transactions included in this payout
              </Typography>
            </Box>
          </Box>
          {payout && (
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} align="center">
                  <Box p={2} style={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h6">{payout.transactions.length}</Typography>
                    <Typography variant="overline" color="textSecondary">
                      TRANSACTIONS
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} align="center">
                  <Box p={2} style={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h6">{currency(Math.abs(payout.summary.amount), { fromCents: true }).format()}</Typography>
                    <Typography variant="overline" color="textSecondary">
                      NET AMOUNT
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ width: "100%", display: "grid" }}>
            <DataGrid
              rows={payout.transactions.map((o) => {
                return {
                  id: o.id,
                  time: o.created * 1000,
                  netAmount: o.netAmount,
                  grossAmount: o.grossAmount,
                  paymentFee: o.paymentFee,
                  successFee: o.successFee,
                  loanFee: o.loanFee,
                  description: o.description,
                  type: o.type,
                  link: o.orderId ? `/order/${o.orderId}` : o.reqId ? `/redirect?from=quote&id=${o.reqId}&to=order` : ""
                };
              })}
              columns={columns}
              autoHeight
              getRowHeight={(params) => "auto"}
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "time", sort: "desc" }]
                }
              }}
            />
          </Box>
        </Container>
      </Box>
    );
  }
}
