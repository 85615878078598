import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import StaticPage from "../../common/StaticPage";
import BakerQuoteEntry from "../components/BakerQuoteEntry";
import { useNavigate } from "react-router";
import moment from "moment";
import SendQuoteHeader from "../components/SendQuoteHeader";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import QuoteItemGeneric from "../../quote/component/QuoteItemGeneric";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { getCustomerName, getSaasWebsiteUrl, isV2SaaSPlan } from "../../common/util";
import { UserContext } from "../../context/UserContext";
import ShareButton from "../../common/component/ShareButton";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import AssistedCheckout from "../../common/component/AssistedCheckout";
import QuoteStatusHeader from "../../quote/component/QuoteStatusHeader";
import CustomerDetailsButton from "../../common/component/CustomerDetailsButton";
import LineItemV2 from "../../common/component/lineitem/LineItemV2";
import CustomerContactInfoButton from "../../common/component/CustomerContactInfoButton";
import BakerChat from "../../quote/component/action/BakerChat";
import IconText from "../../common/IconText";
import { CheckOutlined } from "@mui/icons-material/";
import CloseIcon from "@mui/icons-material/Close";
import currency from "currency.js";
import RequestSummary from "../../request/components/RequestSummary";
import { Timeline, TimelineContent, TimelineDot, TimelineConnector, TimelineSeparator } from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import CancelQuote from "../../quote/screen/CancelQuote";
import { ExpandMoreOutlined } from "@mui/icons-material";

export default function MyQuote(props) {
  const { user } = useContext(UserContext);
  let location = useLocation();

  const navigate = useNavigate();
  const { hash } = useLocation();

  const { reqId } = useParams();
  const [request, setRequest] = useState();
  const [quote, setQuote] = useState();
  const [mode, setMode] = useState("view");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    setRequest("");
    setQuote("");
    setMode("view");

    bkstApi(`/my/quote/${reqId}`).then((res) => {
      if (hash === "#chat") {
        // check if order has been placed
        if (res.data?.request?.orderId) {
          navigate(`/order/${res.data.request.orderId}#chat`);
        }
      }

      setRequest(res.data.request);
      setQuote(res.data.quote);
      setMode(res.data.quote.status === "pending" ? "edit" : "view");
      setLoading(false);
    });
  }, [reqId, location.key]);

  const onSubmit = (payload) => {
    bkstApi
      .put(`/my/quote/${reqId}`, payload)
      .then((res) => {
        setLoading(true);
        bkstApi(`/my/quote/${reqId}`).then((res) => {
          setRequest(res.data.request);
          setQuote(res.data.quote);
          setMode("sent");
          setLoading(false);
        });
      })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      });
  };

  if (loading) {
    return <LinearProgress />;
  } else if (!request) {
    return (
      <StaticPage
        title="Closed"
        desc="Sorry, this quote request is no longer available. Either the customer has already placed an order or the request date has passed."
        icon={UpdateOutlinedIcon}
        href="/dashboard"
        btnText="View Dashboard"
      ></StaticPage>
    );
  } else if (request) {
    let { status, exdate } = quote;
    let expiration = exdate && exdate.includes("T") ? exdate : `${exdate}T23:59`;

    if (status === "active" && moment(expiration).diff(moment(), "minutes") <= 0) {
      status = "expired";
    } else if (status === "block") {
      status = "unavailable";
    }

    const name = getCustomerName({
      firstName: request.firstName,
      lastName: request.lastName,
      name: request.name
    });

    if (user.role === "clerk") {
      const saasDomainUrl = getSaasWebsiteUrl(user.domain);

      return (
        <Container maxWidth="md">
          <Box my={4} textAlign={"center"}>
            <Typography variant="h2" gutterBottom>
              Quote
            </Typography>
            <Typography variant="h5" color="textSecondary">
              {name} ● {moment(request.date).format("ddd, MMM D")}
            </Typography>
          </Box>
          <Box py={4} sx={{ border: "2px solid #DDD" }}>
            <Grid container>
              <Grid item sm={2} xs={12}></Grid>
              <Grid item sm={8} xs={12}>
                <iframe
                  src={`${saasDomainUrl}/quote/${request.id}?su=${user.id}`}
                  style={{ width: "100%", height: "300vh", border: "0" }}
                  scrolling="no"
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      );
    } else {
      if (quote.items) {
        return (
          <Container maxWidth="sm">
            <Box my={4}>
              <Box mb={6} textAlign={"center"}>
                <QuoteStatusHeader status={status} request={request} quote={quote} />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                  {request.src === "direct" ? (
                    <CustomerDetailsButton
                      customer={{
                        firstName: request.firstName,
                        lastName: request.lastName,
                        name: request.name,
                        phone: request.phone,
                        email: request.email
                      }}
                      src={request.src}
                    />
                  ) : (
                    <CustomerContactInfoButton
                      customer={{
                        firstName: request.firstName,
                        lastName: request.lastName,
                        name: request.name
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center", flexGrow: "1", justifyContent: "flex-end" }}>
                  {request.src === "direct" && isV2SaaSPlan(user.plan) && quote.status !== "paid" && (
                    <ShareButton type="quote" link={`/quote/${reqId}`} />
                  )}
                  {request.src === "direct" && <BakerChat request={request} quote={quote} status={status} />}
                  {quote.status === "paid" && request.orderId && (
                    <IconButton color="secondary" component={RouterLink} to={`/order/${request.orderId}`}>
                      <LocalMallOutlinedIcon />
                    </IconButton>
                  )}

                  {(request.src === "direct" || isV2SaaSPlan(user.plan)) && quote.status !== "paid" && (
                    <IconButton color="secondary" component={RouterLink} to={`/quote/${request.id}/edit`}>
                      <EditOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
              <Paper elevation={0}>
                <Box px={2} py={2}>
                  {quote.items.map((item, idx) => (
                    <Box key={idx} py={1}>
                      <LineItemV2 item={item} view="min" />
                    </Box>
                  ))}
                </Box>
              </Paper>
              <Box my={4}>
                <Typography variant="overline" color="textSecondary">
                  Fulfillment
                </Typography>
                <Paper elevation={0}>
                  <Box p={2}>
                    <Box pb={2}>
                      <Typography variant="overline" color="textSecondary">
                        DATE
                      </Typography>
                      <Typography variant="body2">
                        {moment(quote.fulfillment.date).format("ddd, MMM D, YYYY")} {quote.fulfillment.time ? ` [${quote.fulfillment.time}]` : ""}
                      </Typography>
                    </Box>
                    <Box pb={2}>
                      <Typography variant="overline" color="textSecondary" gutterBottom>
                        CHECKOUT OPTIONS
                      </Typography>
                      <Box py={0.5}>
                        <IconText icon={CheckOutlined} text={"Store Pickup"} variant="body2" />
                      </Box>
                      {quote.fulfillment.option.delivery.type === "in-house" && (
                        <IconText
                          icon={CheckOutlined}
                          text={`In-house Delivery: ${currency(quote.fulfillment.option.delivery.total).format()} to Zip Code (${
                            quote.fulfillment.option.delivery.location.zip
                          })`}
                          variant="body2"
                        />
                      )}
                      {quote.fulfillment.option.delivery.type === "metrobi" && (
                        <IconText icon={CheckOutlined} text={"Metrobi Delivery"} variant="body2" />
                      )}
                      {quote.fulfillment.option.delivery.type === "no" && <IconText icon={CloseIcon} text={"Delivery"} variant="body2" />}
                    </Box>
                  </Box>
                </Paper>
              </Box>
              {request.src === "direct" && request.status !== "closed" && (
                <Box my={4}>
                  <Box textAlign={"center"}>
                    {(quote.status === "active" || quote.status === "expired") && <AssistedCheckout reqId={request.id} status={status} />}
                    <Typography variant="caption" color="textSecondary">
                      Confirm order by taking payment for this quote. Ideal for customers in-store or over the phone
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box my={6}>
                {request.src === "direct" && request.status !== "paid" && (
                  <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Other Actions</AccordionSummary>
                    <AccordionDetails sx={{ marginLeft: "15px", borderLeft: "3px solid #EEE" }}>
                      <Box p={2}>
                        <CancelQuote request={request} />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0
                  }
                }}
              >
                {quote.resTs && (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box mb={1}>
                        <Typography variant="overline" display="block">
                          {moment(quote.resTs._seconds * 1000).format("LLLL")}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" display="block">
                          Quote sent{quote.updTs && `. Last updated ${moment(quote.updTs).format("LLLL")}`}
                        </Typography>
                        {quote.uid && <Avatar src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${quote.uid}.webp`} />}
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                )}
                {quote.reqTs && (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box mb={2}>
                        <Typography variant="overline" display="block">
                          {moment(quote.reqTs._seconds * 1000).format("LLLL")}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" display="block">{`${request.name} requested a quote`}</Typography>
                      </Box>
                      <RequestSummary request={request} />
                    </TimelineContent>
                  </TimelineItem>
                )}
              </Timeline>
            </Box>
          </Container>
        );
      } else {
        return (
          <Container maxWidth="md">
            <Box my={4}>
              <QuoteStatusHeader status={status} request={request} quote={quote} />
            </Box>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Box sx={{ position: "-webkit-sticky", position: "sticky", top: "0" }}>
                  <SendQuoteHeader status={status} request={request} quote={quote} />
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                {mode === "view" || mode === "sent" ? (
                  <Box>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                          {quote.uid && (
                            <img
                              style={{ width: "24px", height: "24px", borderRadius: "12px" }}
                              src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${quote.uid}.webp`}
                            />
                          )}
                          <Typography variant="overline" color="textSecondary">
                            Quote
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} align="right">
                        <Box sx={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "flex-end" }}>
                          {quote.status === "paid" && request.orderId && (
                            <IconButton color="secondary" component={RouterLink} to={`/order/${request.orderId}`}>
                              <LocalMallOutlinedIcon />
                            </IconButton>
                          )}
                          {request.src === "direct" && isV2SaaSPlan(user.plan) && quote.status !== "paid" && (
                            <ShareButton type="quote" link={`/quote/${reqId}`} />
                          )}
                          {(request.src === "direct" || isV2SaaSPlan(user.plan)) && quote.status !== "paid" && (
                            <IconButton color="secondary" onClick={() => setMode("edit")}>
                              <EditOutlinedIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <QuoteItemGeneric request={request} quote={quote} />
                  </Box>
                ) : (
                  <BakerQuoteEntry request={request} quote={quote} onSubmit={onSubmit} onCancel={() => setMode("view")} />
                )}
              </Grid>
            </Grid>
          </Container>
        );
      }
    }
  }
}
