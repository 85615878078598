import React from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { Avatar } from "@mui/material";

export default function NotificationIcon(props) {
  const { type, status } = props;
  const style = { fontSize: "1.5rem" };
  //style.color = "#bababa";

  let icon = <AssignmentIcon style={style} />;

  if (type === "Quote") {
    icon = <VisibilityOutlinedIcon style={style} />;
  } else if (type.includes("New Order") || type.includes("Manual Order") || type.includes('Order for')) {
    icon = <LocalMallOutlinedIcon />;
  } else if (type.includes("message")) {
    icon = <ChatOutlinedIcon style={style} />;
  } else if (type.includes("Text")) {
    icon = <SmsOutlinedIcon style={style} />;
  } else if (type.includes("New Request")) {
    icon = <NewReleasesOutlinedIcon style={style} />;
  } else if (type.includes("Invoice")) {
    icon = <ReceiptOutlinedIcon style={style} />;
  } else if (type.includes("Payment")) {
    icon = <LocalAtmOutlinedIcon style={style} />;
  } else if (type === "Reopen") {
    icon = <LockOpenIcon style={style} />;
  } else if (type === "Review") {
    icon = <StarHalfOutlinedIcon style={style} />;
  } else if (type === "Feedback") {
    icon = <FeedbackIcon style={style} />;
  } else if (type.includes("Order Updated")) {
    icon = <EditOutlinedIcon style={style} />;
  } else if (type.includes("Preponed")) {
    icon = <HistoryOutlinedIcon style={style} />;
  }

  return <Avatar style={{ backgroundColor: status === "closed" ? "#DDD" : "#999" }}>{icon}</Avatar>;
}
