import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Step,
  StepButton,
  Stepper,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import bkstApi from "../../api";
import { ORDER_PROGRESS_STEPS } from "../../common/constants";
import FeatureAccess from "../../baker/components/FeatureAccess";

export default function OrderProgress(props) {
  const { orderId } = props;

  const [progress, setProgress] = useState(props.progress || { step: 0, steps: ORDER_PROGRESS_STEPS });
  const [activeStep, setActiveStep] = useState(progress.step);

  const [open, setOpen] = useState(false);

  const updateProgress = () => {
    const data = { ...progress, step: activeStep };

    bkstApi.put(`/my/order/${orderId}/progress`, { progress: data }).then((res) => {
      setProgress(data);
      setOpen(false);
    });
  };

  const handleStepClick = (step) => {
    let next = +step + 1;
    if (step < progress.step) {
      next = +step;
    }
    setActiveStep(next);
  };

  const handleClose = () => {
    setActiveStep(progress.step);
    setOpen(false);
  };

  const pct = (progress.step * 100) / progress.steps.length;

  return (
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      <Link onClick={() => setOpen(true)} sx={{ cursor: "pointer", color: "#888", display: "inline-flex", alignItems: "center" }}>
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          {/* <CircularProgress thickness={5} size={20} variant="determinate" value={100} sx={{ color: "#DDD" }} /> */}
          <CircularProgress thickness={3} size={32} variant="determinate" value={Number(pct)} sx={{ color: "#16a085", zIndex: "10" }} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ECECEC",
              borderRadius: "50%",
              fontSize: "18px"
            }}
          >
            <CakeOutlinedIcon fontSize="inherit" />
          </Box>
        </Box>
      </Link>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <FeatureAccess plan={["premium"]} />
        <DialogTitle>Order Progress</DialogTitle>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box textAlign={"center"}>
            <Typography variant="body1" color="textSecondary">
              A great way to keep track of your order progress. Simply click a step & confirm to mark it complete!
            </Typography>
          </Box>
          <Box my={4}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              sx={{
                "& .MuiStepIcon-root.Mui-completed": { color: "#16a085" },
                "& .MuiStepLabel-label": { fontSize: "12px", marginTop: "10px", textTransform: "uppercase", fontWeight: "500", fontFamily: "inherit" }
                //"& .MuiStepIcon-root.Mui-active": { color: "#2ecc71" },
              }}
            >
              {progress.steps.map((o, idx) => (
                <Step key={idx}>
                  <StepButton color="#000" onClick={() => handleStepClick(idx)}>
                    {o}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ height: "36.5px" }}>
            {activeStep != progress.step && (
              <Box>
                <Button color="secondary" onClick={handleClose} sx={{ marginRight: "1rem" }}>
                  CANCEL
                </Button>
                <Button color="primary" variant="contained" onClick={updateProgress}>
                  SAVE CHANGES
                </Button>
              </Box>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </span>
  );
}
