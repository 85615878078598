import React, { useState, useEffect, useContext } from "react";
import {
  Backdrop,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slide,
  TextField,
  Typography
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import BDatePicker from "../../common/input/BDatePicker";
import BDateTimePicker from "../../common/input/BDateTimePicker";
import moment from "moment";
import { Autocomplete } from "@mui/material";
import { QUOTE_TAGS } from "../../common/constants";
import ProductAttributes from "../../common/component/ProductAttributes";
import { isV2SaaSPlan, getDefaultQuoteExpiration, getAmountWithTxnFee, getOriginalAmount } from "../../common/util";
import currency from "currency.js";
import { UserContext } from "../../context/UserContext";
import bkstApi from "../../api";
import AttachPhoto from "../../common/chat/AttachPhoto";
import CancelIcon from "@mui/icons-material/Cancel";
import OrderCalendarPopup from "../../common/component/OrderCalendarPopup";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PlacesAutocomplete from "../../common/PlacesAutocomplete";
import DirectionsOutlinedIcon from "@mui/icons-material/DirectionsOutlined";
import QuillRTE from "../../common/component/QuillRTE";

const REASONS = {
  booked: "We're fully booked",
  lastMin: "We don't take last minute orders",
  other: "Other (please specify)"
};

export default function BakerQuoteEntry(props) {
  const { user } = useContext(UserContext);
  const { request } = props;

  const getQuoteDescription = () => {
    let desc = "";
    if (q.note) {
      desc = q.note;
    } else if (q.status === "pending" && request.details) {
      desc = `We can accommodate your request "${request.details}"`;
    }

    return desc;
  };

  let defaultProductType = "Cake";
  if (request.items) {
    defaultProductType = Object.keys(request.items)[0];
  }

  const q = props.quote;
  const qp = {
    productType: q.productType || defaultProductType,
    shape: q.shape || "",
    size: q.size || "",
    servings: q.servings || "",
    frosting: q.frosting || ""
  };

  const [tab, setTab] = useState(q.status === "unavailable" ? "1" : "0");
  const [product, setProduct] = useState(qp || "");

  const [quote, setQuote] = useState(q.quote || "");
  const [exdate, setExdate] = useState(q.exdate || "");
  const [option, setOption] = useState(q.option || user.config?.fulfillment?.delivery?.["third-party"] || "no");
  const [delivery, setDelivery] = useState(q.delivery || "");
  const [deliveryBy, setDeliveryBy] = useState("");
  const [tags, setTags] = useState(q.tags || []);
  const [note, setNote] = useState(getQuoteDescription());
  const [pics, setPics] = useState(q.pics || []);
  const [reason, setReason] = useState(q.reason || "");
  const [includeFees, setIncludeFees] = useState(user.config?.transferFees === "false" ? false : true );

  const [date, setDate] = useState(request.date);
  const [eventLocation, setEventLocation] = useState(request.eventLocation);

  const [templates, setTemplates] = useState("");

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (option === "no") {
      setDelivery("");
      setDeliveryBy("");
    } else if (option === "free") {
      setDelivery(0);
      setDeliveryBy("baker");
    } else {
      setDelivery("");
      setDeliveryBy("baker");
    }
  }, [option]);

  useEffect(() => {
    if (reason === "other") {
      setNote("");
    } else {
      setNote(reason && REASONS[reason]);
    }
  }, [reason]);

  useEffect(() => {
    if (tab == 0) {
      setProduct(qp || "");
      setQuote(getOriginalAmount(q.quote, q.includeFees));
      setExdate(q.exdate || getDefaultQuoteExpiration(request.date));
      setDelivery(getOriginalAmount(q.delivery, q.includeFees));
      setDeliveryBy(q.delivery || "");
      setTags(q.tags || []);
      setNote(getQuoteDescription());
      setPics(q.pics || []);
      setIncludeFees(user.config?.transferFees === "false" ? false : true );
      setReason("");

      if (!templates) {
        getTemplates();
      }
    } else {
      setProduct("");
      setQuote("");
      setExdate("");
      setDelivery("");
      setDeliveryBy("");
      setTags([]);
      setNote((q.reason && q.note) || "");
      setIncludeFees(false);
      setReason(q.reason || "");
      setPics([]);
    }
  }, [tab]);

  const getTemplates = () => {
    bkstApi(`/template/quote`).then((res) => {
      setTemplates(res.data.sort((o1, o2) => (o1.name > o2.name ? 1 : -1)));
    });
  };

  const saveDraft = () => {
    callApi("save");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    callApi("send");
  };

  const callApi = (mode) => {
    setProcessing(true);

    let payload = {
      mode,
      ...product,
      quote,
      option,
      delivery,
      deliveryBy,
      exdate,
      tags,
      note,
      reason,
      includeFees,
      pics,
      appVersion: process.env.REACT_APP_VERSION
    };

    if (isV2SaaSPlan(user.plan)) {
      payload.quote = includeFees ? getAmountWithTxnFee(quote) : quote;
      payload.delivery = includeFees ? getAmountWithTxnFee(delivery) : delivery;
    }

    if (request.src === "direct") {
      payload.request = { date, eventLocation };
    }

    props.onSubmit(payload);
  };

  const removePhoto = (idx) => {
    let arr = [...pics];
    arr.splice(idx, 1);
    setPics(arr);
  };

  let origin = "";
  if (user?.profile?.location) {
    const coordinates = user?.profile?.location?.coord;
    if (coordinates) {
      origin = `${coordinates.lat},${coordinates.lng}`;
    }
  }

  return (
    <Box>
      <Grid container alignItems={"center"}>
        <Grid item xs={8}>
          <Typography variant="overline" color="textSecondary">
            SEND YOUR RESPONSE
          </Typography>
        </Grid>
        <Grid item xs={4} align="right">
          <OrderCalendarPopup date={request.date} />
        </Grid>
      </Grid>
      <Box>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <Paper elevation={0}>
              <Box py={1}>
                <RadioGroup row value={tab} name="tab" onChange={(e) => setTab(e.target.value)} style={{ justifyContent: "center" }}>
                  <FormControlLabel value="0" control={<Radio required={true} />} label="Send Quote" />
                  <FormControlLabel value="1" control={<Radio required={true} />} label="Unavailable" />
                </RadioGroup>
              </Box>
            </Paper>
          </Box>
          {tab == 1 && (
            <Box>
              <Typography variant="overline" color="textSecondary">
                PICK A REASON
              </Typography>
              <Paper elevation={0}>
                <Box p={3}>
                  <Box my={2}>
                    <RadioGroup value={reason} name="reason" onChange={(e) => setReason(e.target.value)}>
                      {Object.entries(REASONS).map(([key, value]) => (
                        <FormControlLabel key={key} value={key} control={<Radio required={true} />} label={value} />
                      ))}
                    </RadioGroup>
                  </Box>
                  {reason === "other" && (
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={note}
                        variant="outlined"
                        fullWidth
                        id="note"
                        label="Reason"
                        name="note"
                        multiline
                        rows={2}
                        onChange={(e) => setNote(e.target.value)}
                        required
                      />
                    </FormControl>
                  )}
                </Box>
              </Paper>
            </Box>
          )}
          {tab == 0 && (
            <Box>
              <Box>
                <Typography variant="overline" color="textSecondary">
                  ITEM DETAILS
                </Typography>
                <Paper elevation={0}>
                  <Box px={3} pt={4} pb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <ProductAttributes value={product} onChange={(o) => setProduct(o)} required={true} />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Autocomplete
                          value={tags}
                          onChange={(e, val) => setTags(val)}
                          options={QUOTE_TAGS}
                          filterSelectedOptions
                          renderInput={(params) => <TextField variant="outlined" {...params} label="Tags" />}
                          multiple
                          freeSolo
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <QuillRTE
                          value={note}
                          onChange={(html) => setNote(html)}
                          placeholder={
                            tab == 0
                              ? "Describe decor (edible image, ruffles, sugar flowers..) & toppers (edible 3D fondant, 2D cardstock..). Propose alternatives."
                              : "Add a reason (optional)"
                          }
                        />
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                          <AttachPhoto id="attach" handleAttachments={(images) => setPics(images)} folder={props.uploadFolder} />
                          {templates && tab == 0 && (
                            <Box mt={1} align="right">
                              {templates.map((t) => (
                                <Chip
                                  key={t.name}
                                  size="small"
                                  label={t.name}
                                  component="a"
                                  onClick={() => setNote(note.concat(` ${t.text}`))}
                                  clickable
                                />
                              ))}{" "}
                            </Box>
                          )}
                        </Box>
                        {pics && pics.length > 0 && (
                          <Box p={2} style={{ display: "flex", gap: "15px", backgroundColor: "#F7F7F7", border: "1px dashed #DDD" }}>
                            {pics.map((pic, idx) => {
                              return (
                                <Badge
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                  }}
                                  badgeContent={
                                    <IconButton onClick={() => removePhoto(idx)} size="small">
                                      <CancelIcon />
                                    </IconButton>
                                  }
                                >
                                  <img key={idx} src={pic} style={{ height: "75px" }} />
                                </Badge>
                              );
                            })}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
              <Box my={2}>
                <Typography variant="overline" color="textSecondary">
                  PRICING
                </Typography>
                <Paper elevation={0}>
                  <Box px={3} pt={4} pb={2}>
                    <TextField
                      name="quote"
                      value={quote}
                      variant="outlined"
                      id="quote"
                      label="Price"
                      onChange={(e) => setQuote(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="text"
                      onInvalid={(e) => e.target.setCustomValidity("Enter a valid price")}
                      onInput={(e) => e.target.setCustomValidity("")}
                      inputProps={{
                        pattern: "^[1-9][0-9]*(.[0-9]{1,2})?$",
                        inputmode: "decimal"
                      }}
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyOutlinedIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                    {isV2SaaSPlan(user.plan) && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          className="includeFees"
                          control={
                            <Checkbox checked={includeFees} onChange={(e) => setIncludeFees(e.target.checked)} name="consent" color="secondary" />
                          }
                          label="Include transaction fees"
                        />
                        {includeFees && +quote > 0 && (
                          <Grid item xs={12}>
                            <Box p={1} sx={{ backgroundColor: "#e5f6fd", color: "#2980b9" }}>
                              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                                <Typography variant="body2">{currency(quote).format()}</Typography>
                                <Typography variant="body1" sx={{ fontWeight: "700" }}>
                                  &#8674; {currency(getAmountWithTxnFee(quote)).format()}
                                </Typography>
                              </Box>
                              <Typography variant="caption">Customer will be shown the final amount above (including fees)</Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Box>
                </Paper>
              </Box>
              <Box my={2}>
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={8}>
                    <Typography variant="overline" color="textSecondary">
                      Fulfillment
                    </Typography>
                  </Grid>
                  <Grid item xs={4} align="right">
                    <IconButton
                      size="small"
                      component="a"
                      target="_blank"
                      href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${origin}&destination=${encodeURIComponent(
                        eventLocation?.description
                      )}`}
                    >
                      <DirectionsOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Paper elevation={0}>
                  <Box px={3} pt={2} pb={2}>
                    <Box mb={4}>
                      <Typography variant="body2" color="textSecondary">
                        Pickup option is always available to customers during checkout. Add a delivery option to offer more choices.
                      </Typography>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item sm={12} xs={12}>
                        <FormControl variant="outlined" required fullWidth>
                          <InputLabel id="delivery-option-label">Delivery options</InputLabel>
                          <Select
                            value={option}
                            labelId="option-label"
                            label="Delivery options"
                            id="option"
                            name="option"
                            onChange={(e) => setOption(e.target.value)}
                          >
                            <MenuItem value="no">No delivery</MenuItem>
                            <MenuItem value="fee">In-house Delivery</MenuItem>
                            {user.config?.fulfillment?.delivery?.["third-party"] === "metrobi" && (
                              <MenuItem value="metrobi">Metrobi Delivery</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      {request.src === "direct" && (option === "fee" || option === "free") && (
                        <Grid item xs={12} sm={12}>
                          <FormControl variant="outlined" fullWidth>
                            <PlacesAutocomplete onChange={setEventLocation} value={eventLocation} label="Delivery Zip Code" required />
                          </FormControl>
                        </Grid>
                      )}
                      {option && request.src === "direct" && (
                        <Grid item xs={12} sm={12}>
                          <FormControl variant="outlined" fullWidth>
                            <BDatePicker
                              value={date}
                              label={option === "no" ? "Pickup Date" : "Delivery Date"}
                              onChange={(d) => setDate(d)}
                              disablePast
                              required
                              autoOk
                            />
                            <FormHelperText></FormHelperText>
                          </FormControl>
                        </Grid>
                      )}
                      {option === "fee" && (
                        <Grid item xs={12} sm={12}>
                          <TextField
                            name="delivery"
                            type="number"
                            value={delivery}
                            variant="outlined"
                            id="delivery"
                            label="Delivery fee"
                            onChange={(e) => setDelivery(e.target.value)}
                            onWheel={(e) => {
                              e.target && e.target.blur();
                            }}
                            fullWidth
                            required={option === "fee"}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AttachMoneyOutlinedIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                          {includeFees && +delivery > 0 && (
                            <Box mt={1} p={1} sx={{ backgroundColor: "#e5f6fd", color: "#2980b9" }}>
                              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                                <Typography variant="body2">{currency(delivery).format()}</Typography>
                                <Typography variant="body1" sx={{ fontWeight: "700" }}>
                                  &#8674; {currency(getAmountWithTxnFee(delivery)).format()}
                                </Typography>
                                <Typography variant="body2">with fees</Typography>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Paper>
              </Box>
              <Box mt={4} mb={2}>
                <Box>
                  <FormControl variant="outlined" fullWidth>
                    <BDateTimePicker
                      value={exdate || null}
                      label="Expiration"
                      onChange={(d) => setExdate(d)}
                      disablePast
                      required
                      autoOk
                      maxDateTime={(request.date && moment(request.date).endOf("day").toDate()) || null}
                    />
                    <FormHelperText>{`Quote expires ${moment(`${exdate}`).fromNow()}`}</FormHelperText>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          )}
          <Box my={2}>
            <Box sx={{ display: "flex", gap: "15px" }}>
              {props.quote?.status === "pending" && tab === "0" && (
                <Button fullWidth variant="outlined" color="secondary" onClick={saveDraft}>
                  Save Draft
                </Button>
              )}
              <Button fullWidth type="submit" variant="contained" color="primary">
                {tab === "0" ? "Send Quote" : "Send"}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
      {processing && (
        <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  );
}
