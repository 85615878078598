import React, { useState, useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";
import DayOfWeek from "./DayOfWeek";
import moment from "moment";
import bkstApi from "../../api";

export default function OrderCalendar(props) {
  const date = moment(props.date);
  const fromDate = moment(props.date).subtract("1", "days");
  const toDate = moment(props.date).add("1", "days");

  const [ordersByDate, setOrdersByDate] = useState();

  useEffect(() => {
    if (date.isValid()) {
      bkstApi(`/my/order?filter=date&fromDt=${fromDate.format("YYYY-MM-DD")}&toDt=${toDate.format("YYYY-MM-DD")}`).then((res) => {
        let tmp = {};
        for (const o of res.data.orders) {
          let arr = tmp[o.fulfillment.date] || [];
          arr.push(o);
          tmp[o.fulfillment.date] = arr;
        }
        setOrdersByDate(tmp);
      });
    }
  }, [props.date]);

  const getOrderCountByDate = (dt) => {
    let count = 0;
    if (ordersByDate) {
      let orderData = ordersByDate[dt.format("YYYY-MM-DD")];
      count = (orderData && orderData.length) || 0;
    }

    return count;
  };

  let days = [];
  // 3 days before and after
  for (let i = -1; i <= 1; i++) {
    const dt = moment(date).add(i, "days");
    days.push(dt);
  }

  if (date.isValid()) {
    return (
      <Box>
        {/* <Typography variant="overline" color="textSecondary">
          ORDER CALENDAR
    </Typography> */}
        <Paper elevation={0}>
          <Box py={2}>
            <Box className="order-calendar">
              {days.map((dt, idx) => (
                <DayOfWeek key={idx} date={dt} count={getOrderCountByDate(dt)} highlight={dt.diff(date, "days") === 0} />
              ))}
            </Box>
          </Box>
        </Paper>
        <Box align="center">
          <Typography color="textSecondary" variant="caption">
            You have {getOrderCountByDate(date)} orders on {moment(date).format("MMM D")}
          </Typography>
        </Box>
      </Box>
    );
  } else {
    return <Box />;
  }
}
