import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import IconText from "../../common/IconText";
import moment from "moment";
import TextWithReadMore from "../../common/TextWithReadMore";
import { generateServingsSnippet } from "../../common/util";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ImageCarousel from "../../common/component/ImageCarousel";

export default function RequestSummary(props) {
  const { request } = props;
  return (
    <Paper elevation={0}>
      <Box>
        <Grid container>
          <Grid item sm={6} xs={5}>
            <ImageCarousel images={request.pics} />
          </Grid>
          <Grid item sm={6} xs={7}>
            <Box p={2}>
              <Box mb={1}>
                <Typography variant="h6" display="inline">
                  {props.request.title}
                </Typography>
              </Box>
              <Box style={{ color: "#777" }}>
                {props.request.date && (
                  <IconText
                    icon={EventOutlinedIcon}
                    text={`${moment(props.request.date).format("ddd, MMM DD, YYYY")} ${props.request.time ? `[${props.request.time}]` : ""} `}
                  />
                )}
                {props.request.servings && <IconText icon={ShoppingBagOutlinedIcon} text={generateServingsSnippet(props.request.servings)} />}
                {props.request.location && <IconText icon={PinDropOutlinedIcon} text={props.request.location} />}
                {props.request.budget && <IconText icon={LocalAtmOutlinedIcon} text={`$${props.request.budget} Budget`} />}
              </Box>
            </Box>
          </Grid>
          {props.request.details && props.request.details.length > 2 && (
            <Grid item sm={12} xs={12}>
              <Box p={2}>
                <TextWithReadMore data={props.request.details} limit={140} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
}
