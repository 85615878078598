import React, { useState } from "react";
import { Box, Button, Divider, Grid, Paper, Slide, TextField, Typography } from "@mui/material";
import PhoneField from "../../common/input/PhoneField";
import bkstApi from "../../api";

export default function BakerOnboardingBakery(props) {
  const [email, setEmail] = useState(props.data.email || "");
  const [phone, setPhone] = useState(props.data.phone || "");

  const [street, setStreet] = useState(props.data.street || "");
  const [city, setCity] = useState(props.data.city || "");
  const [state, setState] = useState(props.data.state || "");
  const [zip, setZip] = useState(props.data.zip || "");

  const submit = (e) => {
    e.preventDefault();

    const baker = { location: { city, state, zip } };
    const privateData = { email, phone, street };

    bkstApi.put("/my/account", { baker, privateData }).then((res) => {
      props.done();
    });
  };

  return (
    <Slide direction="up" in={props.step === 1} mountOnEnter unmountOnExit>
      <Paper elevation={3}>
        <Box px={3} py={3}>
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              Bakery Details
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Enter details for your primary location
            </Typography>
          </Box>
          <form onSubmit={submit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  value={street}
                  name="street"
                  variant="outlined"
                  fullWidth
                  required
                  id="street"
                  label="Street"
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={city}
                  name="city"
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label="City"
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  value={state}
                  name="state"
                  variant="outlined"
                  fullWidth
                  required
                  id="state"
                  label="State"
                  onChange={(e) => setState(e.target.value)}
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  value={zip}
                  name="zip"
                  variant="outlined"
                  fullWidth
                  required
                  id="zip"
                  label="Zip"
                  type="number"
                  onChange={(e) => setZip(e.target.value)}
                  inputProps={{ maxLength: 5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={email}
                  name="email"
                  variant="outlined"
                  fullWidth
                  required
                  id="email"
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneField name="Phone" value={phone} onChange={(phone) => setPhone(phone)} />
              </Grid>
              <Grid item xs={12} align="right">
                <Button variant="contained" color="primary" size="large" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Slide>
  );
}
